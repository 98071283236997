



















































import { Component, Prop, Vue } from 'vue-property-decorator'

import TermsAsset from '@/assets/terms'

@Component
export default class Terms extends Vue {

  copy: string = TermsAsset.copy

  private hideModal () {
    this.$emit('hide')
  }
}
