





























import { mapState } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Pagination from '@/components/core/Pagination.vue'
import { PAGINATION_LIMIT } from '@/constants'
import LoadingProgress from '@/components/core/LoadingProgress.vue'

import UserAPI from '@/services/user.service'
import { IUser } from '@/types/models/user.model'
import { IOption } from '@/types/form'

@Component({
  components: {
    LoadingProgress
  },
})
export default class ConnectionSelect extends Vue {
  @Prop({ required: true }) id: string
  @Prop() label: string
  @Prop() value: number[]
  @Prop() disabledConnections: number[]

  $refs: {
    autoComplete: Vue
  }

  // data
  connectionsList: IUser[] = []
  isFetching: boolean = false
  total: number = 0
  page: number = 0
  $autoCompleteMenuEl: Element|null = null

  // computed: {
  get options (): IOption[] {
    return (this.connectionsList || []).map(conn => ({
      text: conn.name,
      value: conn.id,
      disabled: this.disabledConnections ? this.disabledConnections.includes(conn.id) : false
    }))
  }

  get selected (): number[] {
    return this.value
  }

  set selected (value: number[]) {
    this.$emit('update:value', value)
  }

  // lifecycle hooks
  async created () {
    const data = await UserAPI.getConnections({ pagination: { page: 0 } }).catch(console.error)
    if (!data) return
    this.total = data.meta.total || 0
    this.connectionsList = data.connections || []
  }

  mounted () {
    // Vuetify Autocomplete doesn't have neither scroll event handler nor pagination
    // So below is custom scroll listener to load more connection when user scrolls to bottom of the list
    // @ts-ignore
    this.$autoCompleteMenuEl = this.$refs.autoComplete?.$refs?.menu?.$refs?.content
    if (!this.$autoCompleteMenuEl) return
    this.$autoCompleteMenuEl.addEventListener('scroll', this.handleAutoCompleteScroll)
  }

  beforeDestroy () {
    if (!this.$autoCompleteMenuEl) return
    this.$autoCompleteMenuEl.removeEventListener('scroll', this.handleAutoCompleteScroll)
    this.$autoCompleteMenuEl = null
  }

  // methods
  async handleFetchMore () {
    if (((this.page + 1) * PAGINATION_LIMIT) >= this.total) return

    this.isFetching = true
    const data = await UserAPI.getConnections({
      pagination: { page: ++this.page, limit: PAGINATION_LIMIT }
    }).catch(console.error)
    this.isFetching = false

    if (!data) return
    this.connectionsList = [ ...this.connectionsList, ...data.connections ]
  }

  handleAutoCompleteScroll ($event: Event) {
    if (!$event.target) return
    const $target = $event.target as HTMLElement
    if ($target.scrollTop !== ($target.scrollHeight - $target.offsetHeight)) {
      return
    }
    // if scrolled to the bottom
    if (this.isFetching) return
    this.handleFetchMore()
  }
}
