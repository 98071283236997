
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getFileNameFromUrl } from '@/utils/vuex'

@Component
export default class BusinessDetailsField extends Vue {
  @Prop() label: string
  @Prop({ default: '—' }) value?: string|File
  @Prop({ default: 'text' }) type?: 'text'|'file'

  get fileName () {
    if (this.type !== 'file') return undefined
    if (!this.value) return '—'
    if (this.value instanceof File) return getFileNameFromUrl(this.value.name)
    return getFileNameFromUrl(this.value)
  }
}
