import Vue from 'vue'
import { Auth0Client, RedirectLoginOptions, GetUserOptions, IdToken, LogoutOptions } from '@auth0/auth0-spa-js'
import { Auth0UserPayload, IIdTokenPayload } from '@/types/api'


const auth0Client = new Auth0Client({
  domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
  redirect_uri: `${window.location.origin}/callback`,
  client_id: process.env.VUE_APP_AUTH0_CLIENT_ID as string,
  useRefreshTokens: true,
  cacheLocation: 'localstorage'
})

window.auth0Client = auth0Client

export class AuthService {

  public token?: string

  get session (): Promise<IdToken|null> {
    return new Promise(async (resolve, reject) => {
      const token = await auth0Client.getTokenSilently().catch(err => {
        if (err?.error !== 'login_required') {
          console.error(err)
          Vue.$logger?.logError(err)
        }
        resolve(null)
      })
      if (!token) return resolve(null)
      const idToken = await this.getIdToken()

      return resolve(idToken)
    })
  }

  async login (appState?: string): Promise<any> { 
    let authOptions: RedirectLoginOptions = { connection: 'linkedin' }
    if (!!appState) authOptions = { ...authOptions, appState }
    return auth0Client.loginWithRedirect(authOptions)
  }

  logout (options?: LogoutOptions): void {
    auth0Client.logout(options)
  }

  async handleRedirectCallback (hash = window.location.hash): Promise<IIdTokenPayload|void> {
    const redirectResult = await auth0Client.handleRedirectCallback()
      .catch(err => {
        console.error(err)
        Vue.$logger?.logError(err, { location: window.location.href })
      })

    if (!redirectResult) return

    let idToken = await this.getIdToken()
        
    try {
      idToken.authState = JSON.parse(decodeURIComponent(redirectResult?.appState))
    } catch (error) {
      Vue.$logger?.logError(error as Error)
      console.warn(error)
    }

    return idToken
  }

  async getUser (options?: GetUserOptions): Promise<Auth0UserPayload|undefined> {
    return auth0Client.getUser(options)
  }

  async getIdToken (): Promise<IIdTokenPayload> {
    const idToken = await auth0Client.getIdTokenClaims()
      .catch(err => {
        if (err?.error !== 'login_required') {
          console.error(err)
          Vue.$logger?.logError(err)
        }
      })

    if (idToken) this.token = idToken.__raw
    return idToken as IIdTokenPayload
  }
}

export default new AuthService()