import Vue from 'vue'
import { Module } from 'vuex'
import OpportunityAPI from '@/services/opportunity.service'
import {
  isOppSupportRequested, mergeArraysUniqItems
} from '@/utils/vuex'
import { NETWORK_TYPES } from '@/assets/network-types'
import OPP_STATUSES from '@/assets/opportunity-statuses'
import { IOpportunity } from '@/types/models/opportunity.model'
import RootState from '@/types/rootState'
import { IPagination, IApiMeta, IOppsRequestOptions } from '@/types/api'

interface IState {
  adminAllOpps: {
    list: IOpportunity[],
    total: number
  },
  supportProvided: {
    list: IOpportunity[],
    total: number
  },
  supportRequested: {
    list: IOpportunity[],
    total: number
  },
}

export enum AdminOppsListName {
  adminAllOpps = 'adminAllOpps',
  supportProvided = 'supportProvided',
  supportRequested = 'supportRequested'
}

const state: IState = {
  adminAllOpps: {
    list: [],
    total: 0
  },
  supportProvided: {
    list: [],
    total: 0
  },
  supportRequested: {
    list: [],
    total: 0
  },
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    adminAllOpps (state): IOpportunity[] {
      return state.adminAllOpps.list || []
    },
    supportRequested (state): IOpportunity[] {
      return state.supportRequested.list || []
    },
    supportProvided (state): IOpportunity[] {
      return state.supportProvided.list || []
    },
    adminAllTotal: (state): number => {
      return state.adminAllOpps.total
    },
    supportProvidedTotal: (state): number => {
      return state.supportProvided.total
    },
    supportRequestedTotal: (state): number => {
      return state.supportRequested.total
    }
  },
  mutations: {
    setListByName (state, {
      listName, opportunities, pagination, meta
    }: {
      listName: AdminOppsListName, opportunities: IOpportunity[], pagination: IPagination, meta: IApiMeta
    }): void {
      if (!listName || !state[listName] || !opportunities) return

      state[listName].list = !!pagination?.page ?
        mergeArraysUniqItems<IOpportunity>(state[listName].list, opportunities)
        : opportunities

      if (meta) state[listName].total = meta.total
    },
    addOpportunity (state, opportunity: IOpportunity): void {
      if (!opportunity) return
      if (isOppSupportRequested(opportunity)) {
        state.supportRequested.list = [ opportunity, ...state.supportRequested.list ]
        state.supportRequested.total++
        return
      }
      state.adminAllOpps.list = [ opportunity, ...state.adminAllOpps.list ]
      state.adminAllOpps.total++
    },
  },
  actions: {
    _afterUserAuthHook (context) {
      if (!context.getters.isAdmin) return
      OpportunityAPI.subscribeOpportunityCreate(context.getters.profileID, ({ data }) => {
        const opportunity = data.createOpportunity
        context.commit('addOpportunity', opportunity)
      })

      OpportunityAPI.subscribeOpportunityUpdate(context.getters.profileID, ({ data }) => {
        const opportunity = data.updateOpportunity
        if (!opportunity) return
        context.dispatch('listAdminOpportunities')
        context.dispatch('listSupportProvidedOpportunities')
        context.dispatch('listSupportRequestedOpportunities')
      })
    },
    async listAdminOpportunities (context, payload: IOppsRequestOptions = {}): Promise<void> {
      const data = await OpportunityAPI.getOpportunities(payload).catch(error => { console.error(error) })
      if (!data) return
      context.commit('setListByName', {
        listName: 'adminAllOpps',
        opportunities: data.opportunity,
        pagination: payload.pagination,
        meta: data.meta
      })
    },
    async listSupportRequestedOpportunities (context, payload: IOppsRequestOptions = {}): Promise<void> {
      const data = await OpportunityAPI.getOpportunities({
        ...payload,
        input: {
          ...payload.input,
          networkType: NETWORK_TYPES.SUPPORT_REQUESTED
        }
      }).catch(error => { console.error(error) })
      if (!data) return
      context.commit('setListByName', {
        listName: 'supportRequested',
        opportunities: data.opportunity,
        pagination: payload.pagination,
        meta: data.meta
      })
    },
    async listSupportProvidedOpportunities (context, payload: IOppsRequestOptions = {}): Promise<void> {
      const data = await OpportunityAPI.getOpportunities({
        ...payload,
        input: {
          ...payload.input,
          status: OPP_STATUSES.SUPPORT_PROVIDED
        }
      }).catch(error => { console.error(error) })
      if (!data) return
      context.commit('setListByName', {
        listName: 'supportProvided',
        opportunities: data.opportunity,
        meta: data.meta,
        pagination: payload.pagination
      })
    },
  }
}

export default store