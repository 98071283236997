<template>
  <div>
    <v-row no-gutter>
      <RowHeading>
        My Dashboard <InfoIcon @onClick="handleInfoIconClick" />
      </RowHeading>

      <v-col
        v-for="card in cards"
        :key="card.title"
        v-bind="{ [`xs${card.flex}`]: true }"
        cols="6"
        class="pa-1"
      >
        <v-card
          :disabled="card.disabled"
          @click="handleMenuItemClick(card)"
        >
          <v-img
            :src="card.src"
            height="81"
            cover
          >
            <v-card-title
              class="fill-height align-start section-title pt-2"
              v-text="card.title"
            />
          </v-img>

          <v-card-actions class="subtitle-2">
            {{ card.status }}
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <InfoDialog
      :title="infoDialogTitle"
      :show.sync="showInfoDialog"
      v-trackEvent:hide="{
        event: 'welcome_text_close_tap'
      }"
      :persistent="!gdprAccepted"
    >
      <v-carousel
        class="welcome-dialog-slider"
        light
        v-model="welcomeSliderModel"
        hide-delimiter-background
        :show-arrows="false"
        :hide-delimiters="gdprAccepted"
        height="auto"
      >
        <v-carousel-item>
          <div>
            <h2 class="headline mb-4">
              See your notifications here and use this dashboard to navigate to other pages
            </h2>
            <h3 class="title">
              Profile
            </h3>
            <p>
              Fill-in your profile so Riverflexers can easily find, connect and share opportunities with you.
            </p>

            <h3 class="title">
              Connections
            </h3>
            <p>
              Add professional connections to share opportunities and availability with your private network and our full network of over 1000 digital professionals.
            </p>

            <h3 class="title">
              Opportunities
            </h3>
            <p>
              Browse and apply to opportunities shared across the network. Share opportunities for free across the Riverflex network (not for recruiters or commercial use).
            </p>

            <h3 class="title">
              Rewards
            </h3>
            <p>
              Earn financial rewards while growing your network and sharing opportunities.
            </p>
          </div>
          <div
            v-if="!gdprAccepted"
            class="text-center mb-10 mt-6"
          >
            <v-btn
              rounded
              color="primary"
              dark
              @click="handleNextSlideClick"
            >
              GOT IT, NEXT!
            </v-btn>
          </div>
        </v-carousel-item>
        <v-carousel-item v-if="!gdprAccepted">
          <GDPRAgreement
            class="pb-10"
            @acceptAgreement="handleAcceptAgreementClick"
          />
        </v-carousel-item>
      </v-carousel>
    </InfoDialog>
  </div>
</template>

<script>
import RowHeading from '@/components/core/RowHeading'
import InfoIcon from '@/components/core/InfoIcon'
import InfoDialog from '@/components/core/InfoDialog'
import GDPRAgreement from '@/components/gdpr/GDPRAgreement'
import { mapGetters } from 'vuex'
import ShowInfoDialogMixin from '@/mixins/ShowInfoDialog.mixin'
import { trackHelpBtnTap } from '@/segmentAnalytics/common'
import { trackDashboardMenuTap } from '@/segmentAnalytics/dashboard'

export default {
  props: ['profilePercent', 'opportunities', 'connections', 'rewards'],
  components: {
    RowHeading,
    InfoIcon,
    InfoDialog,
    GDPRAgreement,
  },
  mixins: [
    ShowInfoDialogMixin
  ],
  data: () => ({
    pageName: 'home',
    showInfoDialog: false,
    welcomeSliderModel: 0,
  }),
  computed: {
    ...mapGetters(['isAdmin', 'gdprAccepted']),
    cards () {
      return [
        {
          title: 'Profile',
          to: '/profile',
          src: require('@/assets/images/dashboard/profile_bg.svg'),
          srcset:
            './images/dashboard/card-profile-bg.png 1x, ./images/dashboard/card-profile-bg@2x 2x, ./images/dashboard/card-profile-bg@3x 3x',
          flex: 6,
          status: `${this.profilePercent}% complete`,
          disabled: false,
          eventAction: 'profile'
        },
        {
          title: 'Opportunities',
          to: this.isAdmin ? '/admin/opportunities' : '/opportunities',
          src: require('@/assets/images/dashboard/opportunities_bg.svg'),
          srcset:
            './images/dashboard/opportunities.png 1x, ./images/dashboard/opportunities@2x 2x, ./images/dashboard/opportunities@3x 3x',
          flex: 6,
          status: `${this.opportunities} Active`,
          disabled: false,
          eventAction: 'opportunities'
        },
        {
          title: 'Connections',
          to: '/connections',
          src: require('@/assets/images/dashboard/connections_bg.svg'),
          srcset:
            './images/dashboard/connections.png 1x, ./images/dashboard/connections@2x 2x, ./images/dashboard/connections@3x 3x',
          flex: 6,
          status: `${this.connections} connections`,
          disabled: false,
          eventAction: 'connections'
        },
        {
          title: 'Rewards & offers',
          to: '/rewards',
          src: require('@/assets/images/dashboard/rewards_offers_bg.svg'),
          srcset:
            './images/dashboard/rewards.png 1x, ./images/dashboard/rewards@2x 2x, ./images/dashboard/rewards@3x 3x',
          flex: 6,
          status: 'More information',
          disabled: false,
          eventAction: 'rewards'
        }
      ]
    },
    infoDialogTitle () {
      if (this.gdprAccepted) return 'Welcome to the Riverflex app!'
      return this.welcomeSliderModel === 1
        ? 'Do you want to receive new job opportunities and updates?'
        : 'Welcome to the Riverflex app!'
    }
  },
  methods: {
    handleInfoIconClick () {
      trackHelpBtnTap('dashboard')
      this.showInfoDialog = true
    },
    handleMenuItemClick (item) {
      trackDashboardMenuTap(item.eventAction)
      this.$router.push(item.to)
    },
    handleAcceptAgreementClick () {
      this.showInfoDialog = false
    },
    handleNextSlideClick () {
      this.welcomeSliderModel++
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  font-size: 16px !important;
}

.v-image ::v-deep .v-image__image {
  background-position: right top !important;
  background-size: unset !important;
}
.welcome-dialog-slider ::v-deep {
  .v-carousel__controls {
    height: 20px;
    .v-btn--icon {
      width: 18px;
      height: 18px;
    }
    .v-btn__content .v-icon {
      font-size: 8px !important;
    }
  }
}
</style>
