<template>
  <v-card class="pa-2 pt-0 pb-0">
    <v-container v-if="opportunity !== null">
      <v-row no-gutters>
        <v-col cols="auto ml-auto">
          <v-btn
            small
            fab
            icon
            @click="handleCloseClick"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2 class="headline text-center">
            Share <strong>{{ opportunity.headline }}</strong> opportunity with someone
          </h2>
        </v-col>
      </v-row>

      <v-radio-group v-model="shareOptions.selected">
        <div
          class="mb-5"
          v-for="option in shareOptions.options"
          :key="option.value"
        >
          <v-radio
            :label="option.label"
            :value="option.value"
          />
          <div v-if="option.value === 1 && shareOptions.selected === 1">
            <ConnectionSelect
              :id="fields.selectedConnections.id"
              :label="fields.selectedConnections.label"
              :value.sync="fields.selectedConnections.value"
              :disabled-connections="disabledConnections"
            />
          </div>

          <v-form
            class="mt-3"
            v-model="valid"
            v-if="option.value === 2 && shareOptions.selected === 2"
          >
            <v-text-field
              v-model="fields.name.value"
              :id="fields.name.id"
              :rules="fields.name.rules"
              :placeholder="fields.name.label"
              required
              clearable
              outlined
            />

            <v-text-field
              v-model="fields.email.value"
              :id="fields.email.id"
              :rules="fields.email.rules"
              :placeholder="fields.email.label"
              type="email"
              required
              clearable
              outlined
            />
          </v-form>
        </div>
      </v-radio-group>

      <div class="text-center">
        <v-btn
          color="orange"
          rounded
          large
          class="white--text"
          :disabled="!canShare"
          @click="share"
        >
          Share
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import ConnectionSelect from '@/components/core/ConnectionSelect'
import { shareScreenEvents } from '@/segmentAnalytics/opportunities'

export default {
  components: {
    ConnectionSelect
  },
  props: ['opportunity', 'isDisplayed'],
  data: () => ({
    valid: false,
    shareOptions: {
      selected: 1,
      options: [
        {
          label: 'One of your existing Riverflex Connections',
          value: 1
        },
        {
          label: 'Someone else',
          value: 2
        }
      ]
    },
    fields: {
      selectedConnections: {
        id: 'selectedConnections',
        label: 'Select Connections',
        value: []
      },
      email: {
        id: 'email',
        value: '',
        label: 'Email',
        rules: [ value => !!value || 'Email is required!' ]
      },
      name: {
        id: 'name',
        value: '',
        label: 'Name',
        rules: [ value => !!value || 'Name is required!' ]
      }
    },
    userIdsWhoSharedOpp: []
  }),
  computed: {
    canShare () {
      return this.shareOptions.selected === 1 ?
        this.fields.selectedConnections.value.length :
        this.valid
    },
    disabledConnections () {
      if (!this.opportunity?.owner?.id) return this.userIdsWhoSharedOpp
      return [ ...this.userIdsWhoSharedOpp, this.opportunity.owner.id ]
    }
  },
  watch: {
    isDisplayed: {
      handler () {
        if (this.isDisplayed) {
          this.fetchUsersWhomSharedOpp()
        }
      }
    }
  },
  methods: {
    hideModal () {
      this.$emit('hide')
      this.resetFormFields()
    },
    resetFormFields () {
      Object.values(this.fields).forEach(item => {
        if (item.value instanceof Array) item.value = []
        item.value = ''
      })
    },
    async share () {
      if (!this.canShare) return
      shareScreenEvents.trackShare(this.shareOptions.selected)
      const shareData = {
        id: this.opportunity.id
      }

      if (this.shareOptions.selected === 1) {
        this.$store.dispatch('shareOppWithUsers', {
          ...shareData, to: this.fields.selectedConnections.value
        })
      } else {
        this.$store.dispatch('shareOppByEmail', {
          ...shareData,
          email: this.fields.email.value,
          name: this.fields.name.value,
        })
      }
      this.hideModal()
    },
    async fetchUsersWhomSharedOpp () {
      if (!this.opportunity) return
      const userIds = await this.$store.dispatch('fetchUsersWhomSharedOpp', this.opportunity.id) || []
      this.userIdsWhoSharedOpp = userIds
    },
    handleCloseClick () {
      shareScreenEvents.trackClose(this.shareOptions.selected)
      this.hideModal()
    }
  },
  created () {
    this.fetchUsersWhomSharedOpp()
  }
}
</script>
