






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Callback extends Vue {
  created () {
    // this.$store.dispatch('login')
    this.$router.push('/home')
  }
}
