






















































































import { mapGetters } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IUser } from '@/types/models/user.model'
import WeeklyAvailability from '@/assets/profile/weeklyAvailability'

interface IRadioButtonField {
  id: string
  label: string
  items: Array<any>,
  value?: string|boolean
}

@Component({
  computed: {
    ...mapGetters(['profile'])
  }
})
export default class UpdateAvailability extends Vue {
  @Prop({ default: false }) showAvailabilityDialog: boolean

  // computed
  profile: IUser
  // data
  weeklyAvailability: IRadioButtonField = {
    id: 'weeklyAvailability',
    label: 'What’s your weekly availability?',
    items: WeeklyAvailability,
  }
  available: IRadioButtonField = {
    id: 'available',
    label: 'Are you available to receive work proposals?',
    items: [{ text:'Yes', value: true }, { text:'No', value: false }],
  }

  updateAvailableToWorkProposal (value:boolean) {
    this.available.value = value
  }

  updateWeeklyAvailability (value:string) {
    this.weeklyAvailability.value = value
  }
  // methods
  hideModal () {
    this.$emit('hideModal')
  }

  confirm () {
    this.$store.dispatch('updateProfile', {
      [this.weeklyAvailability.id]: this.weeklyAvailability.value,
      [this.available.id]: this.available.value
    })
    this.hideModal()
  }

  created () {
    this.available.value = this.profile.available
    this.weeklyAvailability.value = this.profile.weeklyAvailability
  }

}
