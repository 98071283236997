<template>
  <v-row
    no-gutters
    align="center"
    class="my-2"
  >
    <v-col
      class="title"
      cols="4"
    >
      Duration
    </v-col>

    <v-col
      cols="3"
      class="mr-3"
    >
      <v-select
        :id="duration.amount.id"
        v-model="duration.amount.value"
        :items="duration.amount.amounts"
        :label="duration.amount.label"
        :rules="duration.amount.rules"
        item-color="#2e00df"
        max-height="39"
        solo
        single-line
        hide-details
        @change="handleAmountChange"
      />
    </v-col>

    <v-col
      v-show="duration.amount.value !== permanentDuration"
    >
      <v-select
        :id="duration.timeframe.id"
        v-model="duration.timeframe.value"
        :items="duration.timeframe.time"
        :rules="duration.timeframe.rules"
        :label="duration.timeframe.label"
        item-color="#2e00df"
        max-height="39"
        solo
        single-line
        hide-details
        @change="handleTimeframeChange"
      />
    </v-col>
  </v-row>
</template>
<script>
import Vue from 'vue'
import { PERMANENT_DURATION } from '@/constants'
import { trackOppSelectFieldChange } from '@/segmentAnalytics/opportunities'

export default {
  props: { duration: { type: Object } },
  data () {
    return {
      permanentDuration: PERMANENT_DURATION
    }
  },
  methods: {
    handleAmountChange (value) {
      trackOppSelectFieldChange(this.duration.amount.id, this.duration.amount.value)
      if (value !== PERMANENT_DURATION) return
      Vue.set(this.duration.timeframe, 'value', '')
    },
    handleInputFocus (field) {
      this.$emit('focus', field)
    },
    handleInputBlur (field) {
      this.$emit('blur', field)
    },
    handleTimeframeChange () {
      trackOppSelectFieldChange(this.duration.timeframe.id, this.duration.timeframe.value)
    }
  }
}
</script>
