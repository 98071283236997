






































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import ProfilePic from '@/components/core/ProfilePic.vue'
import ProfileContact from '@/components/core/ProfileContact.vue'
import MyConnections from '@/components/profile/MyConnections.vue'
import UserReferralsNumber from '@/components/profile/UserReferralsNumber.vue'
import OpportunitiesPublishedNumber from '@/components/profile/OpportunitiesPublishedNumber.vue'
import ConfirmDialog from '@/components/core/ConfirmDialog.vue'
import AchievementsSelect from '@/components/achievements/AchievementsSelect.vue'
import UserAchievements from '@/components/achievements/UserAchievements.vue'
import MEMBER_STATUSES from '@/assets/member-statuses'
import { IUser, MemberStatus } from '@/types/models/user.model'
import UserAPI from '@/services/user.service'
import { waitAsync } from '@/utils/common'
import { MODAL_TRANSITION_TIME } from '@/constants'
import { IAchievement } from '@/types/models/achievement.model'
import { userProfileEvents } from '@/segmentAnalytics/connections'

@Component({
  name: 'ViewProfileDialog',
  components: {
    ProfilePic,
    ProfileContact,
    MyConnections,
    UserReferralsNumber,
    OpportunitiesPublishedNumber,
    ConfirmDialog,
    AchievementsSelect,
    UserAchievements
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
})
export default class ViewProfileDialog extends Vue {
  @Prop({ required: true }) profile: IUser

  showConfirmDeletionDialog: boolean = false
  userDeletionInProgress: boolean = false
  memberStatusesOptions: any = [
    { text: 'Riverflex Member', value: MEMBER_STATUSES.MEMBER },
    { text: 'Riverflex Insider', value: MEMBER_STATUSES.INSIDER },
    { text: 'Riverflex Core', value: MEMBER_STATUSES.CORE },
  ]

  memberStatus: MemberStatus = MemberStatus.MEMBER
  isProfileAdmin: boolean|null = false
  profileAchievements: number[] = []

  // computed
  isAdmin: boolean

  get currentMemberStatus (): MemberStatus {
    return this.profile?.memberStatus ? this.profile.memberStatus : MEMBER_STATUSES.MEMBER
  }

  @Watch('profile')
  onProfileChanged () {
    this.updateProfileEditableData()
  }

  // lifecycle hooks
  created () {
    this.updateProfileEditableData()
  }

  // methods
  hideModal () {
    this.$emit('hide')
  }

  handleMemberStatusChange (value: MemberStatus) {
    if (!this.isAdmin) return
    this.$store.dispatch('updateMemberStatus', { id: this.profile.id, memberStatus: value })
  }

  async handleConfirmDeletion () {
    this.userDeletionInProgress = true
    const deletedUser = await this.$store.dispatch('deleteUser', this.profile)
    this.userDeletionInProgress = false

    if (deletedUser) {
      this.showConfirmDeletionDialog = false
      await waitAsync(MODAL_TRANSITION_TIME)
      this.hideModal()
    }
  }

  handleAdminChange (value: boolean) {
    if (!this.isAdmin) return
    this.$store.dispatch('toggleUserAdminRole', { id: this.profile.id, admin: value })
  }

  handleCloseClick () {
    userProfileEvents.trackClose(this.profile.id)
    this.hideModal()
  }

  handleViewReferrals () {
    userProfileEvents.trackReferralsToPlatformTap(this.profile.id)
  }

  private updateProfileEditableData () {
    this.memberStatus = this.currentMemberStatus
    this.isProfileAdmin = !!this.profile?.admin
    this.profileAchievements = this.profile?.achievements || []
  }

}
