
import { trackEvent } from '@/segmentAnalytics/core'

export const trackAvailabilityTap = (isAvailable: boolean): void => {
  trackEvent('availability_tap', { is_available: isAvailable })
}

export const trackRiverflexLeftHeaderLogoTap = (): void => {
  trackEvent('rf_left_header_logo_tap')
}

export const trackRiverflexCenterHeaderLogoTap = (): void => {
  trackEvent('rf_center_header_logo_tap')
}

type AddAppToHomeActions = 'close'|'add'|'do_not_show'
const trackAddAppToHomeActions = (action: AddAppToHomeActions): void => {
  trackEvent('add_app_to_home_pop', { action })
}

export const addToHomeEvents = {
  trackClose: () => trackAddAppToHomeActions('close'),
  trackAdd: () => {
    trackAddAppToHomeActions('add')
    trackEvent('add_app_to_home_click')
  },
  trackDoNotShow: () => trackAddAppToHomeActions('do_not_show'),
}

type HelpBtnAction = 'profile'|'rewards'|'faq'|'dashboard'|'connections'|'opportunities'
export const trackHelpBtnTap = (action: HelpBtnAction): void => {
  trackEvent('help_button_tap', { action })
}

export type MenuTapAction = 'profile'|'rewards'|'faq'|'home'|'connections'|'opportunities'|'reports'|'settings'|'logout'|'add_to_home'|'businessDetails'
export const trackMenuTap = (button: MenuTapAction): void => {
  trackEvent('hamburger_menu', { button })
}