































import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'

import ScreeningQuestionControl from '@/components/screeningQuestions/ScreeningQuestionControl.vue'
import ScreeningQuestionsMenu from '@/components/screeningQuestions/ScreeningQuestionsMenu.vue'
import { IScreeningQuestion, ScreeningQuestionType, IAnswerOption, IScreeningQuestionTypeItem, IControlItem } from '@/types/models/screeningQuestion.model'
import { QUESTION_TYPES_MAP } from '@/constants'
import { waitAsync } from '@/utils/common'
import { trackScreeningQuestionAdd } from '@/segmentAnalytics/opportunities'

@Component({
  components: {
    ScreeningQuestionControl,
    ScreeningQuestionsMenu,
    draggable
  }
})
export default class ScreeningQuestionsEditor extends Vue {
  @Prop({ required: true, default: [] }) questions: IControlItem[]

  // data
  formModel: boolean = false
  questionsEditCopy: IControlItem[] = []

  questionTypesWithAnswers: ScreeningQuestionType[] = [
    ScreeningQuestionType.multiChoice,
    ScreeningQuestionType.singleChoice,
  ]

  questionsData = QUESTION_TYPES_MAP

  $refs: {
    controls: ScreeningQuestionControl[]
  }

  // computed
  get editalbeQuestions (): IControlItem[] {
    return this.questions
  }

  set editalbeQuestions (value: IControlItem[]) {
    this.$emit('update:questions', value)
  }

  // methods private
  private async createQuestionControl (question: IScreeningQuestionTypeItem) {
    const maxOrder = !this.questions.length ? 0 : Math.max(...this.questions.map(item => item.order))
    const newQuestionControl: IControlItem = {
      type: question.type,
      order: maxOrder + 1,
      body: '',
    }

    if (this.hasAnswerOptions(question.type)) {
      newQuestionControl.answerOptions = [this.createEmptyAnswerOption()]
    }

    this.editalbeQuestions = [
      ...this.questions,
      newQuestionControl
    ]

    await waitAsync(100)
    const lastControl = this.$refs.controls[this.$refs.controls.length - 1]
    lastControl?.focus()
  }

  private createEmptyAnswerOption (order: number = 0): IAnswerOption {
    return { body: '', order }
  }

  private hasAnswerOptions (questionType: ScreeningQuestionType): boolean {
    return this.questionTypesWithAnswers.includes(questionType)
  }

  private getLabel (questionType: ScreeningQuestionType) {
    return this.questionsData[questionType]?.label || ''
  }

  // handlers
  private handleQuestionSelect (item: IScreeningQuestionTypeItem) {
    trackScreeningQuestionAdd(item.type)
    this.createQuestionControl(item)
  }

  private handleControlDelete (index: number) {
    this.editalbeQuestions.splice(index, 1)
  }
}
