import memberStatuses from '@/assets/member-statuses'

enum MemberStatus {
  Member = 'Member',
  Insider = 'Insider',
  Core = 'Core',
}

export function memberStatusToEnum (memberStatus?: number): MemberStatus {
  const memberStatusesNameMap = {
    [memberStatuses.MEMBER]: MemberStatus.Member,
    [memberStatuses.INSIDER]: MemberStatus.Insider,
    [memberStatuses.CORE]: MemberStatus.Core
  }
  
  const defaultMemberStatusName = MemberStatus.Member
  if (!memberStatus) return defaultMemberStatusName

  return memberStatusesNameMap[memberStatus] || defaultMemberStatusName
}
