








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingProgress extends Vue {
  @Prop({ default: 50 }) readonly size?: number
}
