import { IRequestWithSearchOptions } from '@/types/api'

export const createSeachArgs = (options: IRequestWithSearchOptions = {}, argsStr: string = ''): string => {
  const { input, pagination, match } = options
  let args = argsStr
  if (input) args += `input: ${mapObjToKeyValueString(input)} `
  if (pagination) args += `pagination: ${mapObjToKeyValueString(pagination)} `
  if (match !== undefined) args += `match: ${match} `
  if (args) args = `(${args.trim().replace(/,$/, '')})`

  return args
}

export const mapObjToKeyValueString = (input: any): string => {
  if (typeof input !== 'object') return input

  const result = Object.keys(input).map(key => {
    const value = input[key]
    if (Array.isArray(value)) {
      const toKeyValueString = value.map(item => {
        return mapObjToKeyValueString(item)
      })
      if (typeof value[0] !== 'object') {
        return `${key}:${JSON.stringify(toKeyValueString)}`
      }
      return `${key}:[${toKeyValueString}]`
    } else if (value && typeof value === 'object') {
      return `${key}:${mapObjToKeyValueString(value)}`
    }
    return `${key}:${JSON.stringify(value)}`
  })
  return `{${result.join(',')}}`
}