























































































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import SearchField from '@/components/core/SearchField.vue'
import createOpportunity from '@/components/opportunities/createOpportunity.vue'
import viewOpportunity from '@/components/opportunities/viewOpportunity.vue'
import listOpportunities from '@/components/opportunities/listOpportunities.vue'
import applyOpportunity from '@/components/opportunities/applyOpportunity.vue'
import unpublishDialog from '@/components/opportunities/unpublishOpportunity.vue'
import shareOpportunity from '@/components/opportunities/shareOpportunity.vue'
import CreateOpportunityBtn from '@/components/opportunities/CreateOpportunityBtn.vue'
import ViewApplicantsModule from '@/components/opportunities/applicants/ViewApplicantsModule.vue'
import InfoDialog from '@/components/core/InfoDialog.vue'
import InfoIcon from '@/components/core/InfoIcon.vue'
import LoginDialog from '@/components/core/LoginDialog.vue'
import ShareOpportunityCampaignDialog from '@/components/campaigns/ShareOpportunityCampaignDialog.vue'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'

import { MODAL_TRANSITION_TIME, PAGINATION_LIMIT } from '@/constants'
import { waitAsync } from '@/utils/common'
import ShowInfoDialogMixin, { IShowDialogMixin } from '@/mixins/ShowInfoDialog.mixin'
import ViewSearchMixin, { IViewSearchMixin } from '@/mixins/ViewSearch.mixin'
import OpportunitiesPageMixin, { IOpportunitesPageMixin } from '@/mixins/OpportunitiesPage.mixin'

import { IOpportunity } from '@/types/models/opportunity.model'
import { IApplication, IApplicant } from '@/types/models/application.model'
import { IUser } from '@/types/models/user.model'
import { IPagination } from '@/types/api'
import { IOppSearchField } from '@/types/filters'
import { OppsListName } from '@/store/opportunities'
import { trackHelpBtnTap } from '@/segmentAnalytics/common'

const ViewMixins = Mixins(ShowInfoDialogMixin, ViewSearchMixin, OpportunitiesPageMixin)

@Component({
  computed: {
    ...mapGetters([
      'profile',
      'profileID',
      'authenticated',
      'applicationByOppId',
      'isAdmin',
      'all',
      'userApplied',
      'published',
      'allTotal',
      'publishedTotal',
      'userAppliedTotal'
    ]),
  },
  components: {
    SearchField,
    createOpportunity,
    viewOpportunity,
    listOpportunities,
    applyOpportunity,
    unpublishDialog,
    shareOpportunity,
    InfoDialog,
    InfoIcon,
    ViewApplicantsModule,
    CreateOpportunityBtn,
    LoginDialog,
    ShareOpportunityCampaignDialog,
    LegacyPageContent
  }
})
export default class Opportunities extends ViewMixins implements IShowDialogMixin, IViewSearchMixin, IOpportunitesPageMixin {
  @Prop() hasProfile: boolean

  pageName: string = 'opportunities'
  routeName: string = !!this.hasProfile ? 'opportunities' : 'publicOpportunities'

  applyDialog: boolean = false
  showInfoDialog: boolean = false
  showLoginDialog: boolean = false

  pagination: {[key: string]: {
    isFetching: boolean
    page: number
  }} = {
    all: {
      isFetching: false,
      page: 0,
    },
    userApplied: {
      isFetching: false,
      page: 0,
    },
    published: {
      isFetching: false,
      page: 0,
    },
  }

  searchField: {
    [key: string]: IOppSearchField
  } = {
    all: {
      headline: '',
      industry: '',
      location: ''
    },
    userApplied: {
      headline: '',
      industry: '',
      location: ''
    },
    published: {
      headline: '',
      industry: '',
      location: ''
    },
  }

  // computed
  // from mapGetters:
  profile: IUser
  profileID?: number
  authenticated: boolean
  applicationByOppId: Function
  isAdmin: boolean
  all: IOpportunity[]
  userApplied: IOpportunity[]
  published: IOpportunity[]
  allTotal: number
  publishedTotal: number
  userAppliedTotal: number

  get loginDialogAuthState () {
    return {
      redirectTo: `/opportunities/${this.$route.params.id}`
    }
  }

  // lifecycle Hooks
  async created () {
    if (this.isAdmin) {
      this.$router.push(`/admin${this.$route.fullPath}`)
    }
    if (this.$route.query.offerId) {
      const opportunityByOfferId = await this.$store.dispatch('fetchOpportunityByOfferId', this.$route.query.offerId)
      if (opportunityByOfferId) {
        this.$router.push(`/current-opportunities/${opportunityByOfferId.id}`)
      }
    }
    await this.$store.dispatch('listOpportunities')
    if (this.authenticated) {
      await this.$store.dispatch('listPublishedOpportunities')
      await this.$store.dispatch('listUserAppliedOpportunities')
      this.$store.commit('showShareOpportunityCampaignDialog')
    }
  }

  // methods
  private async applyOpp (modalFields: IApplication & any) {
    modalFields.oppID = this.opportunitySelected?.id
    this.applyDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.$store.dispatch('applyToOpportunity', modalFields)
  }

  private async unpublish () {
    const id = this.opportunitySelected!.id
    const input = {
      networkType: 0,
      status: this.opportunitySelected!.status
    }

    this.unpublishDialog = false
    this.viewDialog = false

    await waitAsync(MODAL_TRANSITION_TIME)

    this.$store.dispatch('upsertOpportunity', { id, input })
  }

  private clearSearch (listName: OppsListName) {
    if (!this.searchField[listName]) return
    this.searchField[listName] = {
      headline: '',
      industry: '',
      location: ''
    }
    this.handleSearch(listName, this.searchField[listName])
  }

  // handlers
  private async handleFetchMore (listName: OppsListName, pagination: IPagination) {
    this.fetchData(listName, pagination)
  }

  private async handleSearch (listName: OppsListName, search: IOppSearchField) {
    this.fetchData(listName, { page: 0, limit: PAGINATION_LIMIT })
  }

  private handleShowApplyDialog () {
    if (!this.authenticated) {
      this.showLoginDialog = true
      return
    }
    this.applyDialog = true
  }

  private handleInfoIconClick () {
    trackHelpBtnTap('opportunities')
    this.showInfoDialog = true
  }

  getActionByListName (listName: OppsListName): string|undefined {
    let requestAction
    switch (listName) {
    case 'all':
      requestAction = 'listOpportunities'
      break
    case 'userApplied':
      requestAction = 'listUserAppliedOpportunities'
      break
    case 'published':
      requestAction = 'listPublishedOpportunities'
      break
    }
    if (!requestAction) {
      console.warn('RequestAction is not found!', listName)
    }
    return requestAction
  }

  isSearching (listName: OppsListName): boolean {
    const searchField = this.searchField[listName]
    if (!searchField) return false
    return !!searchField.headline || !!searchField.industry || !!searchField.location
  }
}
