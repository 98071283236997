




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PrimaryPageContent from '@/components/layouts/PrimaryPageContent.vue'
import BusinessDetailsForm, { FieldId, FormValues, defaultFormValues, fileFields } from '@/components/businessDetails/BusinessDetailsForm.vue'
import { IUser } from '@/types/models/user.model'
import { IBusinessInfo } from '@/types/models/businessInfo.model'
import { getFileNameFromUrl } from '@/utils/vuex'
import StorageAPI from '@/services/storage.service'

const fileUrlFields = ['passportFileUrl', 'professionalLiabilityInsuranceUrl', 'publicLiabilityInsuranceUrl'] as const
type FileUrlField = typeof fileUrlFields[number]

@Component({
  components: {
    PrimaryPageContent,
    BusinessDetailsForm,
  },
  computed: {
    ...mapGetters([
      'profile',
      'businessInfo',
      'isBusinessInfoVerified',
      'isBusinessInfoSubmitted',
    ]),
  }
})
export default class BusinessDetails extends Vue {
  @Prop() hasProfile: boolean

  // data
  initialValues: FormValues|null = null
  editMode: boolean = true
  isUpdatingBusinessInfo: boolean = false

  // computed
  profile: IUser
  businessInfo: IBusinessInfo
  isBusinessInfoVerified: boolean
  get isBusinessInfoSubmitted (): boolean {
    return !!this.profile.isBusinessInfoSet
  }

  get changeRequestMessage (): string|undefined {
    return this.businessInfo?.changeRequestMessage
  }

  @Watch('businessInfo')
  onBusinessInfoChange (info: IBusinessInfo) {
    this.prePopulateForm()
  }

  // lifecycle hooks
  async created () {
    await this.$store.dispatch('fetchBusinessInfo')
    if (this.isBusinessInfoSubmitted) {
      this.editMode = false
    }
    this.prePopulateForm()
  }

  // methods
  async handleSubmit (formData: FormValues) {
    const preparedData: {
      files?: Array<[string, File]>
      main: FormValues
    } = Object.entries(formData).reduce((acc: any, [key, value]) => {
      if (fileFields.includes(key) && value && value instanceof File) {
        return value.size > 0 ? ({ ...acc, files: [ ...(acc.files || []), [key, value]] }) : acc
      }
      return { ...acc, main: { ...acc.main, [key]: value } }
    }, {})

    this.isUpdatingBusinessInfo = true
    let fileS3Keys = {}
    if (preparedData.files) {
      fileS3Keys = await this.$store.dispatch('uploadBusinessInfoFilesToS3', preparedData.files)
    }
    const response = await this.$store.dispatch('updateBusinessInfo', { ...preparedData.main, ...fileS3Keys })
    this.isUpdatingBusinessInfo = false
    if (response) this.toggleEditMode(false)
  }

  handleCancel () {
    this.toggleEditMode(false)
  }

  handleEditClick () {
    this.toggleEditMode(!this.editMode)
  }

  prePopulateForm () {
    const initialValues: any = {}
    Object.entries(this.businessInfo).forEach(([key, value]) => {
      if (!defaultFormValues.hasOwnProperty(key)) return
      initialValues[key] = value
    })

    const fileFields: [FieldId, FileUrlField][] = [
      ['passport', 'passportFileUrl'],
      ['professionalLiabilityInsurance', 'professionalLiabilityInsuranceUrl'],
      ['publicLiabilityInsurance', 'publicLiabilityInsuranceUrl']
    ]
    fileFields.forEach(([formField, dataField]) => {
      if (!this.businessInfo[dataField]) return
      initialValues[formField] = this.businessInfo[dataField]
    })
    this.initialValues = initialValues
  }

  toggleEditMode (isEditMode: boolean) {
    this.editMode = isEditMode
  }
}
