import Vue from 'vue'

import UserAPI from '@/services/user.service'
import AchievementAPI from '@/services/achievement.service'
import { updateArrayItemById, removeArrayItemById, findArrayItemById } from '@/utils/vuex'
import { IUser, IInvite, ISimpleInvite, MemberStatus } from '@/types/models/user.model'
import RootState from '@/types/rootState'
import { Module } from 'vuex'
import { IPagination, IApiMeta, IUsersRequestOptions } from '@/types/api'

interface IState {
  connections: {
    list: IUser[],
    total: number
  },
  users: {
    list: IUser[],
    total: number
  },
  incomingInvites: {
    list: IUser[],
    total: number
  },
  invites: ISimpleInvite[]
}

export enum ConnsListName {
  connections = 'connections',
  users = 'users',
  incomingInvites = 'incomingInvites'
}

const state: IState = {
  connections: {
    list: [],
    total: 0
  },
  users: {
    list: [],
    total: 0
  },
  incomingInvites: {
    list: [],
    total: 0
  },
  invites: [],
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    connections (state): IUser[] {
      return state.connections.list
    },
    users (state): IUser[] {
      return state.users.list
    },
    incomingInvites (state): IUser[] {
      return state.incomingInvites.list
    },
    connectionsTotal (state): number {
      return state.connections.total
    },
    usersTotal (state): number {
      return state.users.total
    },
    incomingInvitesTotal (state): number {
      return state.incomingInvites.total
    },
    invites (state): ISimpleInvite[] {
      return state.invites
    },
  },
  mutations: {
    setConnections (state, { connections = [], meta, pagination }: {
      connections: IUser[]
      meta: IApiMeta
      pagination: IPagination
    }) {
      if (!connections) return
      state.connections.list = !!pagination?.page ? [ ...state.connections.list, ...connections ] : connections
      if (meta) state.connections.total = meta.total
    },
    setIncomingInvites (state, { invites = [], meta, pagination, profileID }: {
      invites: { id: number, from: IUser }[]
      meta: IApiMeta
      pagination: IPagination,
      profileID: number
    }) {
      if (!invites) return
      const incomingUserList = invites.map(item => item.from)
      state.incomingInvites.list = !!pagination?.page ?
        [ ...state.incomingInvites.list , ...incomingUserList ]
        : incomingUserList

      state.invites = [
        ...state.invites,
        ...invites.map(item => ({
          id: item.id,
          from: item.from.id,
          to: profileID
        }))
      ]
      if (meta) state.incomingInvites.total = meta.total
    },
    setUsers (state, { users = [], meta, pagination }: {
      users: IUser[], meta?: IApiMeta, pagination: IPagination
    }) {
      state.users.list = !!pagination?.page ? [ ...state.users.list, ...users ] : users
      if (meta) state.users.total = meta.total
    },
    addInvite (state, invite: IInvite) {
      if (!invite) return
      const { id, from, to } = invite
      state.invites = [...state.invites, { id, from: from.id, to: to.id }]
    },
    addInvites (state, invites: ISimpleInvite[]) {
      if (!invites) return
      state.invites = [...state.invites, ...invites]
    },
    addIncomingInvite (state, invite: IInvite) {
      if (!invite) return
      const { id, from, to } = invite
      state.incomingInvites.list = [...state.incomingInvites.list, from]
      state.incomingInvites.total++
      state.invites = [...state.invites, { id, from: from.id, to: to.id }]
    },
    declineInvite (state, invite: ISimpleInvite) {
      if (!invite) return
      state.invites = removeArrayItemById(state.invites, invite)
      state.incomingInvites.list = state.incomingInvites.list.filter(user => user.id !== invite.from)
      state.incomingInvites.total--
    },
    removeInvite (state, inviteId: number) {
      state.invites = state.invites.filter(invite => invite.id !== inviteId)
    },
    updateUser (state, user: IUser) {
      state.users.list = updateArrayItemById<IUser>(state.users.list, user)
      state.connections.list = updateArrayItemById<IUser>(state.connections.list, user)
      // state.incomingInvites.list = updateArrayItemById<IUser>(state.incomingInvites.list, user)
    },
    setAllUsersNumber (state, allUsersNumber: number) {
      state.users.total = allUsersNumber
    },
    deleteUser (state, deletedUser: IUser) {
      if (!deletedUser) return
      state.users.list = removeArrayItemById(state.users.list, deletedUser)
      state.incomingInvites.list = removeArrayItemById(state.incomingInvites.list, deletedUser)
      state.connections.list = removeArrayItemById(state.connections.list, deletedUser)
    },
    setUserIsConnectedToMe (state, user: IUser) {
      if (!user) return
      user.isConnectedToMe = true
      state.users.list = updateArrayItemById<IUser>(state.users.list, user)
    },
  },
  actions: {
    _afterUserAuthHook (context) {
      if (!context.getters.profileID) return console.warn('No profile id!')

      UserAPI.subscribeIncomingInvite(context.getters.profileID, ({ data }) => {
        context.commit('addIncomingInvite', data.invite)
      })

      UserAPI.subscribeConnect(context.getters.profileID, ({ data }) => {
        context.dispatch('refetchConnections')
      })

      UserAPI.subscribeOutcomingDeclinedInvite(context.getters.profileID, ({ data }) => {
        context.commit('removeInvite', data.declineInvite)
      })
    },
    async listConnections (context, payload: IUsersRequestOptions = {}) {
      const data = await UserAPI.getConnections(payload).catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!data) return
      const { connections, meta } = data

      context.commit('setConnections', {
        connections, meta, pagination: payload.pagination,
      })
      return connections
    },
    async listIncomingInvites (context, payload: IUsersRequestOptions = {}) {
      const data = await UserAPI.getIncomingInvites(payload).catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!data) return
      const { invites, meta } = data

      context.commit('setIncomingInvites', {
        invites,
        meta,
        profileID: context.getters.profileID,
        pagination: payload.pagination
      })
      return invites
    },
    async listUsers (context, payload: IUsersRequestOptions = {}) {
      const data = await UserAPI.getUsers(payload).catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!data) return
      const { user, meta } = data

      context.commit('setUsers', {
        users: user, meta, pagination: payload.pagination
      })

      const invites = await UserAPI.getInvitesToUsers(user.map(userItem => userItem.id))
      context.commit('addInvites', invites)

      return user
    },
    async getAllUsersNumber (context) {
      const allUsersNumber = await UserAPI.getAllUsersNumber().catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!allUsersNumber) return
      context.commit('setAllUsersNumber', allUsersNumber)
    },
    async getUser (context, id: number) {
      const result = await UserAPI.getUserById(id).catch(error => {
        context.dispatch('setStatusError', error)
      })
      return result
    },
    async sendInvite (context, id: number) {
      const invite = await UserAPI.sendInvite(id).catch(error => {
        context.dispatch('setStatusError', error)
      })
      context.commit('addInvite', invite)
      const user = context.getters.users.find((user: IUser) => user.id === id)
      // @ts-ignore
      this._vm.$analytics.sendInvite({ user })
    },
    async acceptInvite (context, invite: ISimpleInvite) {
      const data = await UserAPI.connect(invite.id).catch(error => {
        context.dispatch('setStatusError', error)
      })

      if (!data) {
        context.commit('declineInvite', invite)
        return
      }

      const user = context.getters.users.find((user: IUser) => user.id === invite.from)
      context.commit('setUserIsConnectedToMe', user)

      // @ts-ignore
      this._vm.$analytics.sendInviteAccept({ user })

      context.dispatch('refetchConnections')
    },
    async declineInvite (context, invite: ISimpleInvite) {
      await UserAPI.declineInvite(invite.id).catch(error => {
        context.dispatch('setStatusError', error)
      })

      const user = context.getters.incomingInvites.find((user: IUser) => user.id === invite.from)
      context.commit('declineInvite', invite)
      // @ts-ignore
      this._vm.$analytics.sendInviteDecline({ user })
    },
    async connectReferral (context, { userId, oppId }: { userId: number, oppId: number }) {
      const input: any = { from: userId, to: context.getters.profileID }
      if (oppId) input.share = oppId

      const user = await UserAPI.connectReferral(input)
      if (user) {
        // @ts-ignore
        this._vm.$analytics.sendReferralSignup({
          user: context.getters.profile,
          referral: user,
          oppId
        })
      }
      return user
    },
    refetchConnections (context) {
      context.dispatch('listConnections')
      context.dispatch('listIncomingInvites')
    },
    async updateMemberStatus (context, { id, memberStatus }: { id: number, memberStatus: MemberStatus }) {
      if (!context.getters.isAdmin) return
      const user = await UserAPI.upsert({ id, input: { memberStatus } }).catch(err => {
        context.dispatch('setStatusError', err)
      })
      if (user) context.commit('updateUser', user)
    },
    async toggleUserAdminRole (context, { id, admin }: { id: number, admin: boolean }): Promise<IUser|void> {
      if (!context.getters.isAdmin) return
      const user = await UserAPI.upsert({ id, input: { admin } }).catch(err => {
        context.dispatch('setStatusError', err)
      })
      if (user) context.commit('updateUser', user)
      return user
    },
    async deleteUser (context, user: IUser): Promise<IUser|null> {
      const data = await UserAPI.deleteUser(user.id)
        .catch((error) => context.dispatch('setStatusError', error))
      if (!data) return null

      const { deleteUser } = data
      context.commit('deleteUser', user)
      return deleteUser
    },
    async updateUserAchievements (context, { user, achievementIds }: { user: IUser, achievementIds: number[] }) {
      if (!context.getters.isAdmin) return

      const data = await AchievementAPI.updateUserAchievements(user.id, achievementIds)
        .catch((error) => context.dispatch('setStatusError', error))
      if (!data) return null

      context.commit('updateUser', { ...user, achievements: achievementIds })
    }
  }
}

export default store