<template>
  <v-card class="px-2 mt-0 main-panel">
    <v-container>
      <v-row
        no-gutters
        align="center"
      >
        <v-col class="headline">
          Ask Riverflex for Support
        </v-col>

        <v-col cols="auto">
          <v-btn
            small
            fab
            icon
            @click="hideModal"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="headline mb-1">
          <strong>{{ support.heading }}</strong>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="mb-2"
      >
        <v-col
          class="body"
          v-html="support.body"
        />
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-checkbox
            :label="support.termsField"
            color="primary"
            hide-details
            off-icon="radio_button_unchecked"
            on-icon="check_circle"
            v-model="agreeTerms"
          />
        </v-col>
      </v-row>

      <v-row
        class="text-center mt-4 mb-2"
        no-gutters
      >
        <v-col>
          <v-btn
            rounded
            dark
            color="grey"
            large
            @click="hideModal"
          >
            Close
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            rounded
            light
            color="primary"
            large
            :disabled="!agreeTerms"
            @click="confirmSupport"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import SupportContent from '@/assets/support'
import { askForSupportEvents } from '@/segmentAnalytics/opportunities'
export default {
  data () {
    return {
      support: SupportContent,
      agreeTerms: false
    }
  },
  methods: {
    hideModal () {
      askForSupportEvents.trackClose()
      this.$emit('hide')
    },
    confirmSupport () {
      askForSupportEvents.trackSubmit()
      this.$emit('support')
    }
  }
}
</script>
<style lang="scss" scoped>
.main-panel {
  background: var(--v-orange-base);
  .body {
    color: white;
  }
}
.v-input--selection-controls {
  margin-top: 0;
}
</style>
