import API from './api.service'
import gql from 'graphql-tag'
import { INextCallback } from '@/types/api'
import { IApplication, ApplicationStatus, IApplicants } from '@/types/models/application.model'

export const fragment = gql`
  fragment ApplicationData on Application {
    id
    message
    status
    user
    opportunity
    availableFrom
    timestamp
    cv
    answers {
      answer
      question
      type
      order
    }
  }
`

export const applicantsFragment = gql`
  fragment ApplicantsData on Applicants {
    opportunity
    users {
      id, name, picture, location, role
    }
  }
`

interface IApplicationUpsert {
  id: number
  input: {
    status: ApplicationStatus
  }
}

export class ApplicaitonAPI extends API {

  // queries
  async getApplications (input?: Partial<IApplication>): Promise<IApplication[]> {
    const query = await this.query<{ application: IApplication[] }>(gql`
      ${fragment}
      query application {
        application(input: ${this.input(input)}) {
          ...ApplicationData
        }
      }`, { fetchPolicy: 'no-cache' })
    return query.application
  }

  async getApplicationById (id: number): Promise<IApplication> {
    const query = await this.query<{ application: IApplication }>(gql`
      ${fragment}
      query applicationById {
        application(id: ${id}) {
          ...ApplicationData
        }
      }`, { fetchPolicy: 'no-cache' })
    return query.application
  }

  async getApplicants (oppIds: string[] = []): Promise<IApplicants[]> {
    const query = await this.query<{ applicants: IApplicants[] }>(gql`
    ${applicantsFragment}
			query applicants {
				applicants(opportunities: ${JSON.stringify(oppIds)}) {
					...ApplicantsData
				}
			}`, { fetchPolicy: 'no-cache' })
    return query.applicants
  }

  async getOpportunityApplications (oppId: number): Promise<IApplication[]> {
    const query = await this.query<{ opportunityApplications: IApplication[] }>(gql`
      ${fragment}
      query opportunityApplications {
        opportunityApplications(id: ${oppId}) {
          ...ApplicationData
        }
      }`,
    { fetchPolicy: 'no-cache' })
    return query.opportunityApplications
  }

  async getOpportunitiesApplications (oppsIds: number[]): Promise<IApplication[]|undefined> {
    if (!oppsIds) {
      console.error('Opportunities id are not provided!')
      return
    }

    const query = await this.query<{ opportunitiesApplications: IApplication[] }>(gql`
      ${fragment}
      query opportunitiesApplications {
        opportunitiesApplications(opportunities: ${JSON.stringify(oppsIds)}) {
          ...ApplicationData
        }
      }`,
    { fetchPolicy: 'no-cache' })
    return query.opportunitiesApplications
  }

  // mutations
  async upsert ({ id, input }: IApplicationUpsert): Promise<IApplication> {
    const response = await this.mutation<{ application: IApplication }>(gql`
      ${fragment}
      mutation upsertApplication {
        application(id: ${id}, input: ${this.input(input)}) {
          ...ApplicationData
        }
      }
    `)
    return response.application
  }

  // subscriptions
  subscribeApplicationUpdate (next: INextCallback<{ application: IApplication }>): void {
    this.subscribe(gql`
      ${fragment}
      subscription application {
        application { ...ApplicationData }
      }
    `, next)
  }
}

export default new ApplicaitonAPI()
