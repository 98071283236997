

















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import isEmail from 'validator/es/lib/isEmail'
import isVAT from 'validator/es/lib/isVAT'
import isIBAN from 'validator/es/lib/isIBAN'
import isBIC from 'validator/es/lib/isBIC'

import { IRuleFunction } from '@/types/form'
import { requiredRule } from '@/validation/rules'
import { IUser } from '@/types/models/user.model'
import BusinessDetailsField from '@/components/businessDetails/BusinessDetailsField.vue'
import { getFileNameFromUrl } from '@/utils/vuex'

export interface IField {
  value: any
  rules?: IRuleFunction[]
  label?: string;
}
export type FieldId = 'fullName'|'phone'|'email'|'deliveryAddress'|'passport'|'supplierName'|'supplierAddress'|'registrationNumber'|'vat'|'professionalLiabilityInsurance'|'publicLiabilityInsurance'|'iban'|'swift';
export const fileFields = ['passport', 'professionalLiabilityInsurance', 'publicLiabilityInsurance']
export type FormValues = {
  fullName: string
  phone: string
  email: string
  deliveryAddress: string
  passport?: File
  supplierName: string
  supplierAddress: string
  registrationNumber: string
  vat: string
  professionalLiabilityInsurance?: File
  publicLiabilityInsurance?: File
  iban: string
  swift: string
}

export type InitialFormValues = Omit<FormValues, 'publicLiabilityInsurance' | 'passport' | 'professionalLiabilityInsurance'> & {
  passport?: string
  professionalLiabilityInsurance?: string
  publicLiabilityInsurance?: string
}

export const defaultFormValues = {
  fullName: undefined,
  phone: undefined,
  email: '',
  deliveryAddress: '',
  passport: undefined,
  supplierName: '',
  supplierAddress: '',
  registrationNumber: '',
  vat: '',
  professionalLiabilityInsurance: undefined,
  publicLiabilityInsurance: undefined,
  iban: '',
  swift: '',
}

@Component({
  components: {
    BusinessDetailsField,
  },
  computed: {
    ...mapGetters(['profile']),
  }
})
export default class BusinessDetailsForm extends Vue {
  @Prop() initialValues?: InitialFormValues;
  @Prop({ default: true }) editMode: boolean = true;
  @Prop({ default: false }) loading: boolean = false;

  $refs: {
    form: any
  }
  isFormValid: boolean = true;
  formFields: Record<FieldId, IField> = {
    fullName: {
      label: 'Full name',
      value: '',
      rules: [requiredRule],
    },
    phone: {
      label: 'Phone number',
      value: '',
      rules: [requiredRule]
    },
    email: {
      label: 'Business email',
      value: '',
      rules: [requiredRule, (value) => isEmail(value) || 'Email is not valid!']
    },
    deliveryAddress: {
      label: 'Personal delivery address',
      value: '',
      rules: [requiredRule],
    },
    passport: {
      label: 'Upload scan/photo of passport',
      value: undefined,
      rules: [requiredRule]
    },
    supplierName: {
      label: 'Supplier name',
      value: '',
      rules: [requiredRule]
    },
    supplierAddress: {
      label: 'Supplier address',
      value: '',
      rules: [requiredRule]
    },
    registrationNumber: {
      label: 'Company registration number',
      value: '',
      rules: [requiredRule]
    },
    vat: {
      label: 'VAT number',
      value: '',
      rules: [requiredRule/*, (value) => isVAT(value, 'IT') || 'VAT is not valid!'*/]
    },
    professionalLiabilityInsurance: {
      label: 'Professional liability insurance',
      value: undefined,
      rules: [requiredRule]
    },
    publicLiabilityInsurance: {
      label: 'Public liability insurance',
      value: undefined,
      rules: [requiredRule]
    },
    iban: {
      label: 'IBAN',
      value: '',
      rules: [requiredRule/*, (iban) => isIBAN(iban) || 'IBAN is not valid!'*/]
    },
    swift: {
      label: 'BIC/SWIFT',
      value: '',
      rules: [requiredRule/*, (bic) => isBIC(bic) || 'BIC is not valid!'*/]
    },
  }

  sections = {
    business: 'Business representative information',
    documents: 'Documents',
    company: 'Company information',
    payment: 'Payment information',
  }
  supportedFilesList = ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg']
  supportedFilesHint: string = `Supported files: ${this.supportedFilesList.join(', ')}`

  // computed
  profile: IUser

  // watchers
  @Watch('initialValues')
  onInitialValuesChange (initialValues: InitialFormValues, prevInitialValues: InitialFormValues) {
    if (!initialValues) return
    this.setupInitialValues(initialValues)
  }

  created () {
    this.setupInitialValues(this.initialValues)
  }

  handleFileSelect (file: File) {
    this.formFields.passport.value = file
  }

  handleCancelClick () {
    this.setupInitialValues(this.initialValues)
    this.$emit('cancel')
  }

  handleSaveClick () {
    if (this.$refs.form.validate()) {
      const formData = Object.entries(this.formFields)
        .reduce((acc, [key, formItem]) => ({
          ...acc,
          [key]: formItem.value,
        }), {})

      this.$emit('submit', formData as FormValues)
    }
  }

  setupInitialValues (initialValues?: InitialFormValues) {
    if (!initialValues) return
    Object.entries(initialValues).forEach(([key, value]) => {
      if (fileFields.includes(key) && value) {
        this.formFields[key as FieldId].value = new File([''], getFileNameFromUrl(value))
        return
      }
      this.formFields[key as FieldId].value = value
    })
    this.$refs.form?.resetValidation()
  }
}
