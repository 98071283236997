import API from './api.service'
import gql from 'graphql-tag'

import INotification from '@/types/models/notification.model'
import { INextCallback, IPagination, IApiMeta } from '@/types/api'

interface INotificationArgs {
  pagination?: IPagination
}

export class NotificationService extends API {

  async getNotifications (args: INotificationArgs = { pagination: { page: 0 } }): Promise<{ notification: INotification[], meta: IApiMeta }> {
    return await this.query<{ notification: INotification[], meta: IApiMeta }>(gql`
      query notification {
        notification (pagination: ${this.input(args.pagination)}) {
          message,
          type,
          content
        }
      }
    `, { fetchPolicy: 'no-cache' })
  }

  async triggerEmailNotification (id: string, oppId: number): Promise<{ triggerEmailNotification: string }> {
    return this.mutation<{ triggerEmailNotification: string }>(gql`
      mutation triggerEmailNotification {
        triggerEmailNotification (id: "${id}", oppId: ${oppId})
      }
    `)
  }

  async subscribeNotification (id: number, next: INextCallback<{ notification: INotification }>) {
    this.subscribe(gql`
      subscription notification($id: ID!) {
        notification(id: $id) { message, type, content, user }
      }
    `, next, { id })
  }

}

export default new NotificationService ()
