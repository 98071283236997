













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ViewBtn extends Vue {
  @Prop({ default: 110 }) maxWidth: number
  @Prop({ required: true }) title: string
  @Prop({ default: false }) loading: boolean

  private handleClick () {
    this.$emit('onClick')
  }
}
