import { Module } from 'vuex'

import NotificationService from '@/services/notification.service'
import RootState from '@/types/rootState'
import INotification from '@/types/models/notification.model'
import { IPagination, IApiMeta } from '@/types/api'

interface IState {
  list: INotification[]
  total: number
}

const state: IState = {
  list: [],
  total: 0
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    notifications (state): INotification[] {
      return state.list || []
    },
    notificationsTotal (state) { return state.total },
  },
  mutations: {
    setNotificationsList (state, payload: INotification[] = []): void {
      state.list = payload
    },
    addNotification (state, notification?: INotification): void {
      if (!notification) return
      state.list = [ notification, ...state.list ]
      state.total++
    },
    addNotifications (state, payload: { notifications: INotification[] }) {
      if (!payload?.notifications) return
      state.list = [ ...state.list, ...payload.notifications ]
    },
    setTotal (state, meta: IApiMeta) {
      if (!meta) return
      state.total = meta.total || 0
    }
  },
  actions: {
    _afterUserAuthHook (context) {
      if (!context.getters.profileID) return
      NotificationService.subscribeNotification(context.getters.profileID, ({ data }) => {
        const { notification } = data
        context.commit('addNotification', notification)
      })
    },
    async listNotifications (context) {
      const data = await NotificationService.getNotifications().catch(error => {
        if (!context.getters.profileID) return
        context.dispatch('setStatusError', error)
      })
      if (data && data.notification) {
        context.commit('setNotificationsList', data.notification)
        context.commit('setTotal', data.meta)
      }
    },
    async fetchMoreNotifications (context, pagination?: IPagination) {
      const { notification, meta } = await NotificationService.getNotifications({
        pagination
      }).catch(error => context.dispatch('setStatusError', error))
      context.commit('addNotifications', { notifications: notification })
      context.commit('setTotal', meta)
    },
    async triggerEmailNotification (context, { id, oppId }) {
      await NotificationService.triggerEmailNotification(id, oppId)
        .catch(error => context.dispatch('setStatusError', error))
    }
  }
}

export default store