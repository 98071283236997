<template>
  <v-textarea
    id="message"
    :counter="640"
    :rules="rules"
    v-model="message"
    clearable
    auto-grow
    required
    clear-icon="close"
    label="Personal Message"
    placeholder="Your message"
    class="py-3"
    color="secondary"
    append-icon="edit"
    v-trackEvent:focus="{
      event: 'opp_apply_screen', props: { action: 'edit_personal_message' }
    }"
  />
</template>
<script>
export default {
  props: ['rules'],
  data () {
    return {
      message: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.v-input {
  font-size: 1em;
}
.v-input ::v-deep .v-label {
  font-size: 1.75em;
  height: 30px;
}
.v-input ::v-deep input {
  padding: 20px 0 5px;
  font-size: 0.9em;
}
.v-input ::v-deep textarea {
  padding: 15px 0 5px;
  font-size: 0.9em;
  line-height: normal;
  //color: purple !important;
}
</style>
