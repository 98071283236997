






































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import { IUser } from '@/types/models/user.model'
import { inviteToNewtorkEvents } from '@/segmentAnalytics/connections'

@Component({
  computed: {
    ...mapGetters(['profile', 'profileID'])
  }
})
export default class AddConnection extends Vue {
  @Prop({ default: false }) showMoreInfo: boolean

  // data
  isCopied: boolean = false

  // computed
  profile: IUser
  profileID: number

  get copyBtnText (): string {
    return this.isCopied ? 'Link Copied' : 'Copy Link'
  }

  get connectURL (): string {
    return `${window.location.origin}/connect/${this.profileID}`
  }

  // methods
  hideModal () {
    inviteToNewtorkEvents.trackClose()
    this.$emit('hide')
    this.isCopied = false
  }

  async onCopyBtnClick () {
    await this.$copyText(this.connectURL, this.$refs.container).catch(error => console.warn('Can not copy', error))
    this.isCopied = true
    inviteToNewtorkEvents.trackCopyLink()
    this.$analytics.sendCopyLinkClick()
  }

  onEmail () {
    inviteToNewtorkEvents.trackInviteViaEmail()
    const subject = `Join ${this.profile.name} on the Riverflex Platform`
    const body = `
      Hi,
      I've recently been using the Riverflex platform which is designed for independent consultants to network and find opportunities. I think you would find it useful too.

      It would be great to connect with you there.

      Here is the link to sign up: ${this.connectURL}

      Or if you would like more information, you can visit the Riverflex site www.riverflex.com and find out about the platform.

      Looking forward to connecting!

      ${this.profile.name}
    `
    const mail = `mailto:?subject=${ encodeURIComponent(subject) }&body=${ encodeURIComponent(body) }`
    window.open(mail, '_blank')
    this.$analytics.sendInviteViaEmailClick()
  }

  handleMoreInfoClick () {
    inviteToNewtorkEvents.trackMoreInfoTap()
    this.$emit('moreInfoClick')
  }
}
