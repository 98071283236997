var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-text-field',{directives:[{name:"trackEvent",rawName:"v-trackEvent:focus",value:({
        event: ("opp_" + (_vm.descFields.headline.id) + "_fill"), props: { action: 'edit' }
      }),expression:"{\n        event: `opp_${descFields.headline.id}_fill`, props: { action: 'edit' }\n      }",arg:"focus"},{name:"trackEvent",rawName:"v-trackEvent:blur",value:({
        event: ("opp_" + (_vm.descFields.headline.id) + "_fill"), props: { action: 'close_editing' }
      }),expression:"{\n        event: `opp_${descFields.headline.id}_fill`, props: { action: 'close_editing' }\n      }",arg:"blur"}],staticClass:"custom-input pb-3",attrs:{"id":_vm.descFields.headline.id,"counter":_vm.descFields.headline.maxLength,"rules":_vm.descFields.headline.rules,"label":"Headline","placeholder":"Example - Wanted: Numbers wizard!","required":"","clearable":"","height":"50","color":"secondary","append-icon":"edit"},model:{value:(_vm.descFields.headline.value),callback:function ($$v) {_vm.$set(_vm.descFields.headline, "value", $$v)},expression:"descFields.headline.value"}}),_c('HTMLEditor',{directives:[{name:"trackEvent",rawName:"v-trackEvent:focus",value:({
        event: ("opp_" + (_vm.descFields.description.id) + "_fill"), props: { action: 'edit' }
      }),expression:"{\n        event: `opp_${descFields.description.id}_fill`, props: { action: 'edit' }\n      }",arg:"focus"},{name:"trackEvent",rawName:"v-trackEvent:blur",value:({
        event: ("opp_" + (_vm.descFields.description.id) + "_fill"), props: { action: 'close_editing' }
      }),expression:"{\n        event: `opp_${descFields.description.id}_fill`, props: { action: 'close_editing' }\n      }",arg:"blur"}],staticClass:"custom-input",attrs:{"label":"Description","id":_vm.descFields.description.id,"editor-options":_vm.descriptionEditorOptions,"value":_vm.descFields.description.value,"characters-limit":_vm.descFields.description.maxLength,"validation-rules":_vm.descFields.description.rules},on:{"update:value":function($event){return _vm.$set(_vm.descFields.description, "value", $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }