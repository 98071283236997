






























import { mapGetters } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { isPushNotificationsSupported } from '@/utils/pushNotifications'
import { trackEmailNotificationTap } from '@/segmentAnalytics/profile'


@Component
export default class UserSubscriptions extends Vue {

  @State(state => state.pushNotifications.subscriptionUpdateInProgress) subscriptionUpdateInProgress: boolean
  displayPushNotificationsSub: boolean = false

  get emailSubscription (): boolean {
    return this.$store.getters.emailSubscription
  }

  set emailSubscription (value) {
    trackEmailNotificationTap(!!value)
    this.$store.dispatch('updateProfile', { email_subscribe: value })
  }

  get pushNotificationsSub (): boolean {
    return this.$store.getters.subscribedToPushNotifications
  }

  set pushNotificationsSub (value) {
    if (value) {
      this.$store.dispatch('subscribePushNotifications')
      return
    }
    this.$store.dispatch('unsubscribePushNotifications')
  }

  created () {
    // this.displayPushNotificationsSub = isPushNotificationsSupported()
  }
}
