enum NotificationType {
  opportunity = 'opportunity',
  connection = 'connection',
  reward = 'reward',
}

export default interface INotification {
  message: string
  type: NotificationType
  content: string[]
  user: number
}