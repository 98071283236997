











































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ErrorConnection extends Vue {
  @Prop() msg?: string
  @Prop() title?: string
}
