import { Module } from 'vuex'

import RootState from '@/types/rootState'

interface IState {
  postOpportunityCampaign: {
    showDialog: boolean
    isOnceShowed: boolean
    validUntil: number
  },
  shareOpportunityCampaign: {
    showDialog: boolean
    validUntil: number
  }
}

const state: IState = {
  postOpportunityCampaign: {
    showDialog: false,
    isOnceShowed: false,
    validUntil: (new Date('October, 1, 2020 00:00:00')).getTime(),
  },
  shareOpportunityCampaign: {
    showDialog: false,
    validUntil: (new Date('January, 1, 2021 00:00:00')).getTime()
  }
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    showPostOpportunityCampaignDialog (state): boolean {
      return state.postOpportunityCampaign.showDialog
    },
    showShareOpportunityCampaignDialog (state): boolean {
      return state.shareOpportunityCampaign.showDialog
    },
  },
  mutations: {
    showPostOpportunityCampaignDialog (state): void {
      if (state.postOpportunityCampaign.isOnceShowed) return
      if ((new Date()).getTime() > state.postOpportunityCampaign.validUntil) return
      state.postOpportunityCampaign.showDialog = true
      state.postOpportunityCampaign.isOnceShowed = true
    },
    hidePostOpportunityCampaignDialog (state): void {
      state.postOpportunityCampaign.showDialog = false
    },
    showShareOpportunityCampaignDialog (state): void {
      if ((new Date()).getTime() > state.shareOpportunityCampaign.validUntil) return
      state.shareOpportunityCampaign.showDialog = true
    },
    hideShareOpportunityCampaignDialog (state): void {
      state.shareOpportunityCampaign.showDialog = false
    },
  },
  actions: {}
}

export default store