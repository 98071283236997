








import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'

import { ScreeningQuestionType } from '@/types/models/screeningQuestion.model'
import { QUESTION_TYPES_MAP } from '@/constants'

@Component
export default class ScreeningQuestionIcon extends Vue {
  @Prop({ required: true }) type: ScreeningQuestionType
  @Prop({ required: false, default: false }) small: boolean

  questionsData = QUESTION_TYPES_MAP

  get iconName (): string {
    return this.questionsData[this.type]?.icon || ''
  }
}
