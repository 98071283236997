







































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import { waitAsync } from '@/utils/common'
import { HEADER_HEIGHT } from '@/constants'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'

interface IFaqListItem {
  name: string
  title: string
  body: string
}

@Component({
  components: {
    LegacyPageContent,
  }
})
export default class Faq extends Vue {
  @Prop() hasProfile: boolean

  faqsList: IFaqListItem[] = [
    {
      name: 'profile-info',
      title: 'Who will see my profile and what information will be shared?',
      body: 'Only your network will be able to view your full profile and contact you. We try make sure that there are no recruiters on the platform so that opportunities shared across the platform are direct. Please inform us if you spot recruiters or otherwise bad behaviour on the platform.'
    },
    {
      name: 'profile-update',
      title: 'Why do I need to have my profile updated?',
      body: 'Upload your CV and complete your profile to maximise your opportunities across the Riverflex network. Adding your CV and LinkedIn URL significantly increases your chances of being invited to join the Riverflex Insider group.'
    },
    {
      name: 'connection',
      title: 'What happens if I connect with other professionals on the platform?',
      body: `
        Connecting with other consultants in the platform will allow you to extend your private network and help you find and share unique consulting opportunities. Grow your network by inviting peers who are not yet part of the platform so they can also share and receive opportunities.<br><br>
        <i>*You will also be rewarded if you invite a new member to the network and they are engaged on a Riverflex project see the Rewards page for more info.</i>
      `
    },
    {
      name: 'member',
      title: 'What is a Riverflex member? How can I become a member and what can I use the Riverflex platform for?',
      body: `
        A Riverflex member is an independent consultant who is signing up to the Riverflex platform.<br>
        <p class="my-2"><strong>How can I become a member?</strong></p>
        You sign up to join the Riverflex platform or you get referred by a friend/colleague to join the RIverflex platform.<br>
        <p class="my-2"><strong>What can I use the Riverflex platform for?</strong></p>
        - Develop your own private network.<br>
        - Share opportunities in your private network or across the whole of Riverflex for free.<br>
        - Earn rewards automatically for members you invite to Riverflex that are engaged on Riverflex projects.
      `
    },
    {
      name: 'insider',
      title: 'What is a Riverflex Insider? How can I become one and what can I use the Riverflex platform for?',
      body: `
      Joining the Riverflex Insider community is an invite only process. As an Insider, you will have access to a broader range of Riverflex resources and be first considered by Riverflex for opportunities.

      <p class="my-2"><strong>How can I become an insider?</strong></p>
      To become and insider, you will need to be identified and referred by either a member for the Riverflex Leadership Team or by one of the existing Insiders team via email to (<a class="link" href="mailto:apply@riverflex.recruitee.com">apply@riverflex.recruitee.com</a>). Once identified, we will review your candidacy and contact you for next steps!

      <p class="my-2"><strong>What can I use the Riverflex platform for?</strong></p>
      - Develop your own private network.<br>
      - Share opportunities in your private network or across the whole of Riverflex for free<br>
      - Earn rewards automatically for members you invite to Riverflex that are engaged on Riverflex projects<br>
      - Exclusive Riverflex events to build your network and increase your connections<br>
      - Gain access to the Insider community to share knowledge and collaborate with some of the best digital consultants in Riverflex<br>
      - You can join our private slack community to get updates and sneak peaks on upcoming opportunities or initiatives that might interest you.<br>
      - Many more benefits to be explored!
      `
    },
    {
      name: 'tricks',
      title: 'How can I maximise my chances for being sourced for opportunities?',
      body: 'Keeping your profile updated will maximise your chances of being considered for opportunities across the Riverflex network. Adding your CV and LinkedIn URL will help other consultants and the Riverflex team identify your unique skills and professional experiences.'
    },
    {
      name: 'publish-opportunity',
      title: 'I want to publish an opportunity, how do I do this?',
      body: 'You can publish your own opportunities for free which can be made visible to the full network or your private network You can publish an opportunity accessing the opportunities page and clicking the button at the top right corner “Create new”.'
    },
    {
      name: 'opportunity-application',
      title: 'How do I know if someone has applied to one of my opportunities?',
      body: 'You will get a notification when someone has applied to an opportunity you have published and you will also receive an email in your inbox to your profile.'
    },
    {
      name: 'quality-mark',
      title: 'What does the Quality Mark badge mean?',
      body: `The Quality Mark badge <img class="vertical-align-middle" width="15px" src="${require('@/assets/images/ui/quality-mark-icon.png')}" /> is assigned to platform members who have worked with Riverflex or the Insiders Team before and whose project delivery quality has been verified as exceptional and reliable.`
    },
    {
      name: 'rewards',
      title: 'How do I earn rewards?',
      body: `
        You can earn financial rewards in the following 2 ways:

        <p class="my-2"><strong>1 - Share an opportunity and partner with Riverflex to earn 40% margin share.</strong></p>
        Step 1: Post a job opportunity on the <a class="link" href="/opportunities">Opportunities</a> page and Request support from Riverflex by sending an email to Derek at <a class="link" href="mailto:derek.vanderschyff@riverflex.com">derek.vanderschyff@riverflex.com</a><br>
        Step 2: Your job opportunity will be visible to our network of high-quality consultants on the platform.<br>
        Step 3: We will be in touch to partner with you and give you our full support to find the best independent consultants for the project and help you staff it.<br>
        Step 4: When the opportunity has been successfully staffed, you will receive 40% margin share.<br>
        <i>*40% margin share valid until 31st December 2020, T’s & C’s apply.</i><br><br>

        <p class="my-2"><strong>2 - Refer one of your contacts and earn 1000 €/£ when they are engaged on an opportunity.</strong></p>
        Step 1: You invite your contacts to the Riverflex platform through the invite feature on the connections page.<br>
        Step 2: Your contacts accept the invite and join the Riverflex platform<br>
        Step 3: They get sourced for an opportunity facilitated by Riverflex within 1 year!<br>
        Step 4: Congratulations! Time for your reward!<br>
        <i>*Contact us for terms and conditions on these rewards.</i>
      `
    },
    {
      name: 'reward-credits',
      title: 'When are reward credits applied to my account?',
      body: 'A reward becomes payable when the opportunity it relates to has been staffed and the project has been running for at least 30 chargeable days from the start date. Rewards are automatically recorded and held by Riverlfex. They are claimed by sending an email to <a class="link" href="mailto:info@riverflex.com">info@riverflex.com</a> with payment details.'
    },
    {
      name: 'rewards-paid',
      title: 'When are rewards paid out?',
      body: 'Rewards are automatically recorded and held by Riverlfex. They are claimed by sending an email to <a class="link" href="mailto:info@riverflex.com">info@riverflex.com</a> with payment details.'
    },
    {
      name: 'referral-reward',
      title: 'When do I qualify for a referral reward?',
      body: `
        <ol>
          <li>In order to qualify for the referral bonus, the consultant making the referral (the referrer) must have added the candidate as a referral to their Connections on the platform</li>
          <li>In order to qualify for the bonus payment, the client must have paid for 6 weeks of work or 30 days. The bonus payment will be paid after the client has made payment.</li>
          <li>The bonus amount is 1000 Euros if the client is outside of the UK or 1000 GBP if the client is UK. It is not based upon where the candidate or referrer are based.</li>
          <li>The bonus will be paid to the consultants business with VAT as a business transaction. The consultant mast pay the VAT to the local tax authorities.</li>
        </ol>
      `
    },
    {
      name: 'profile-deletion',
      title: 'How do I delete my profile?',
      body: 'If you no longer want Riverflex to hold your profile information and match you with potential work opportunities then let us know by sending an email to <a class="link" href="mailto:info@riverflex.com">info@riverflex.com</a>. In order to ensure that the request is authentic, please send us the email from the same email address you used to create your profile.'
    },
    {
      name: 'feedback',
      title: 'How can I give feedback to Riverflex?',
      body: 'We are always looking to improve and welcome feedback from our members and insiders. You can reach out to us via platform or by e-mail at <a class="link" href="mailto:info@riverflex.com">info@riverflex.com</a>.'
    },
    {
      name: 'other-questions',
      title: 'Other questions?',
      body: 'We are working to provide you with as much information as possible and we are updating this page regularly. In the meantime, if you have questions which are not part of our FAQ, please feel free to contact us via email at <a class="link" href="mailto:info@riverflex.com">info@riverflex.com</a>.'
    }
  ]


  mounted () {
    this.openPanelFaqItem(this.$route.params.faqName)
  }

  private async openPanelFaqItem (faqItemName: string) {
    if (!faqItemName) return
    // await waitAsync(500)
    const panelElement = document.getElementById(`faq-${faqItemName}`)
    if (!panelElement) return
    window.scrollTo(0, panelElement.offsetTop)
  }

}
