
























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { debounce, IDebouncedFunction } from 'debounce'

import Industries from '@/assets/profile/industries'
import Location from '@/assets/fields/location'
import { IOppSearchField } from '@/types/filters'
import { trackSearchFieldFill, ISearchField } from '@/segmentAnalytics/opportunities'

@Component
export default class SearchField extends Vue {
  @Prop({ required: true }) search: IOppSearchField
  @Prop({ required: true }) label: string

  searchOpen: string|null = null
  searchLists: any = {
    industries: Industries,
    locations: Location
  }

  // lifecycle hooks
  created () {
    this.emitSearchingDebounced = debounce((inputName: ISearchField) => {
      trackSearchFieldFill(inputName)
      this.$emit('searching', this.search)
    }, 600)
  }

  // methods
  private emitSearchingDebounced: IDebouncedFunction

  private clearSearch () {
    this.$emit('clearSearch')
  }

  private handleSearch (inputName: ISearchField) {
    this.emitSearchingDebounced(inputName)
  }
}
