import { IRuleFunction } from '@/types/form'

export const requiredRule: IRuleFunction = (value: any) =>  !!value || 'This field is required!'

export const createMinLengthRule = (minLength: number, fieldName: string): IRuleFunction => {
  return (value: any) => {
    if (!value) return true
    return (value.length > minLength) || `${fieldName} must be more than ${minLength} characters`
  }
}

export const createMaxLengthRule = (maxLength: number, fieldName: string): IRuleFunction => {
  return (value: any) => {
    if (!value) return true
    return (value.length < maxLength) || `${fieldName} must not be more than ${maxLength} characters`
  }
}