import { IRole, IIndustry, IRate } from '@/types/dictionaries'
import { trackEvent } from '@/segmentAnalytics/core'

const trackControlActions = (fieldName: string, action: 'edit'|'close_editing'): void => {
  trackEvent(`add_${fieldName.toLowerCase()}_fill`, { action })
}

export const trackControlEdit = (fieldName: string): void => {
  trackControlActions(fieldName, 'edit')
}

export const trackControlCloseEditing = (fieldName: string): void => {
  trackControlActions(fieldName, 'close_editing')
}

interface IUploadCvProps {
  action: 'close'|'submit'|'select_file'
  file_ext?: string
}
const trackUploadCvActions = (props: IUploadCvProps): void => {
  trackEvent('upload_cv_pop', props)
}
export const uploadCvDialogEvents = {
  trackClose: () => trackUploadCvActions({ action: 'close' }),
  trackSubmit: (file_ext: string) => {
    trackUploadCvActions({ action: 'submit', file_ext })
    trackEvent('upload_cv_submit_click')
  },
  trackSelectFile: (file_ext: string) => trackUploadCvActions({ action: 'select_file', file_ext }),
}

export const trackRoleChoiceTap = (role: IRole): void => {
  trackEvent('role_choice_tap', { role })
}

export const trackIndustryTap = (industry: IIndustry): void => {
  if (!industry) return
  trackEvent('pref_indus_tap', { pref: industry })
}

export const trackDayRateTap = (rate: IRate): void => {
  trackEvent('dayrate_tap', { rate })
}

export const trackEmailNotificationTap = (isEnabled: boolean): void => {
  trackEvent('email_notification_tap', { is_enabled: isEnabled })
}

export const trackMyconnectionsTap = (): void => {
  trackEvent('my_connetions_tap')
}