<template>
  <div>
    <v-card
      class="px-2 mt-0 main-panel"
      min-height="100vh"
    >
      <v-container>
        <v-row
          no-gutters
          align="center"
        >
          <v-col class="headline">
            <div v-if="!editOpportunity">
              Create New
            </div>
            <div v-else>
              Edit
            </div>
          </v-col>

          <v-col
            class="text-right"
            style="display:none"
          >
            <v-btn
              disabled
              rounded
              color="secondary"
              light
              small
            >
              Save to drafts
            </v-btn>
          </v-col>

          <v-col cols="auto ml-2">
            <v-btn
              small
              fab
              icon
              @click="hideModal"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mt-2"
        >
          <Description
            :desc-fields="this.descFields"
          />

          <RecruiteeOfferSelect
            v-if="isAdmin"
            :select-id="offer.id"
            :value.sync="offer.value"
          />

          <v-expansion-panels
            v-model="showRecommendedInfo"
            single
            flat
            focusable
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="px-2">
                <h3
                  ref="recommendedInfo"
                  class="title"
                >
                  Recommended Information
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <p class="body-1">
                  This information is not required but we recommend to fill it in to improve your opportunity!<br>You can skip it by click on "Next" button.
                </p>
                <v-text-field
                  :id="rolePreview.id"
                  v-model="rolePreview.value"
                  :rules="rolePreview.rules"
                  label="Role requirements preview text"
                  clearable
                  height="50"
                  class="custom-input"
                  color="secondary"
                  v-trackEvent:focus="{
                    event: `opp_${rolePreview.id}_fill`, props: { action: 'edit' }
                  }"
                  v-trackEvent:blur="{
                    event: `opp_${rolePreview.id}_fill`, props: { action: 'close_editing' }
                  }"
                />
                <HTMLEditor
                  class="custom-input"
                  :id="descFields.role.id"
                  label="Role Requirements"
                  :editor-options="{
                    placeholder: 'What kind of role is it? For example, accountant, digital strategist, business analyst, etc.',
                  }"
                  :value.sync="descFields.role.value"
                  :characters-limit="descFields.role.maxLength"
                  :validation-rules="descFields.role.rules"
                  @focus="trackFieldEdit(descFields.role.id)"
                  @blur="trackFieldFinishEdit(descFields.role.id)"
                />
                <Prefs
                  :prefs-selects="this.prefsSelects"
                  @clear="handlePrefsClear"
                />
                <DurationFields
                  :duration="this.duration"
                />
                <DayRate
                  :day-rate="this.dayRate"
                />
                <Dates
                  start-date-label="Approx starting date"
                  :show-end-date="false"
                  :dates="this.dates"
                  @startDatePickerActive="trackFieldEdit('start_date')"
                  @startDateChange="trackFieldFinishEdit('start_date')"
                  @startDateCancel="trackFieldFinishEdit('start_date')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="px-2">
                <h3 class="title">
                  Screening Questions
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <ScreeningQuestionsEditor
                  ref="sqEditor"
                  :questions.sync="screeningQuestions.value"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <ShareField
            :shareable="shareable"
          />

          <v-text-field
            id="networkType"
            :value="networkPrefs.radioGroup.value"
            v-show="false"
          />

          <v-text-field
            id="selectedConns"
            :value="networkPrefs.selectConn.value"
            v-show="false"
          />
        </v-form>
      </v-container>
    </v-card>

    <div class="py-3 d-flex justify-space-around bottom-nav">
      <v-btn
        rounded
        color="grey"
        large
        dark
        height="100%"
        active-class="none"
        @click="hideModal"
      >
        Close
      </v-btn>

      <v-btn
        rounded
        color="primary"
        large
        light
        height="100%"
        active-class="none"
        @click="handleNextClick"
      >
        Next
      </v-btn>
      <v-btn
        v-if="!!editOpportunity"
        rounded
        color="secondary"
        large
        light
        height="100%"
        @click="handleSaveClick"
      >
        Save
      </v-btn>
    </div>

    <v-dialog
      v-model="publishDialog"
      transition="slide-y-transition"
    >
      <PublishPrefs
        @publish="handleOpportunityPublish"
        @hide="publishDialog = false"
        @terms="termsDialog = true"
        @support="handleOpportunityPublish((support = true))"
        :radio-fields="networkPrefs.radioGroup"
        :select-field="networkPrefs.selectConn"
        :is-admin="isAdmin"
      />
    </v-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import Description from '@/components/opportunities/create/Description'
import Prefs from '@/components/opportunities/create/Preferences'
import DurationFields from '@/components/opportunities/create/DurationFields'
import DayRate from '@/components/opportunities/create/DayRate'
import Dates from '@/components/opportunities/create/Dates'
import PublishPrefs from '@/components/opportunities/create/PublishPrefs'
import ShareField from '@/components/opportunities/create/ShareField'
import RecruiteeOfferSelect from '@/components/opportunities/create/RecruiteeOfferSelect'
import ScreeningQuestionsEditor from '@/components/screeningQuestions/ScreeningQuestionsEditor.vue'
import HTMLEditor from '@/components/core/HTMLEditor.vue'

import Industries from '@/assets/profile/industries'
import Duration from '@/assets/fields/duration'
import Location from '@/assets/fields/location'
import OPP_STATUSES from '@/assets/opportunity-statuses'
import { NETWORK_TYPES } from '@/assets/network-types'
import { MODAL_TRANSITION_TIME, PERMANENT_DURATION } from '@/constants'
import { waitAsync, isNil } from '@/utils/common'
import { createMaxLengthRule, createMinLengthRule, requiredRule } from '@/validation/rules'
import { trackOppFieldEdit, trackOppFieldFinishEdit, trackOppSelectFieldChange, trackOppAllowShareTap, oppEditEvents } from '@/segmentAnalytics/opportunities'


export default {
  components: {
    Description,
    Prefs,
    DurationFields,
    DayRate,
    Dates,
    PublishPrefs,
    ShareField,
    RecruiteeOfferSelect,
    ScreeningQuestionsEditor,
    HTMLEditor,
  },
  props: ['editOpportunity'],
  async created () {
    await waitAsync(MODAL_TRANSITION_TIME)
    this.updateFields(this.editOpportunity)
  },
  watch: {
    editOpportunity: {
      handler () {
        this.updateFields()
      }
    }
  },
  data () {
    return {
      showRecommendedInfo: null,
      valid: true,
      publishDialog: false,
      descFields: {
        headline: {
          id: 'headline',
          value: '',
          minLength: 6,
          maxLength: 60,
          rules: [
            requiredRule,
            createMinLengthRule(6, 'Headline'),
            createMaxLengthRule(60, 'Headline')
          ]
        },

        description: {
          id: 'description',
          value: '',
          minLength: 10,
          maxLength: 3000,
          rules: [
            requiredRule,
            createMinLengthRule(10, 'Description'),
            createMaxLengthRule(3000, 'Description')
          ]
        },

        role: {
          id: 'role',
          value: null,
          minLength: 6,
          maxLength: 2000,
          rules: [
            // v => !!v || 'required',
            createMinLengthRule(6, 'Role'),
            createMaxLengthRule(2000, 'Role'),
          ]
        }
      },

      rolePreview: {
        id: 'rolePreview',
        value: null,
        maxLength: 200,
        rules: [
          createMaxLengthRule(200, 'Role'),
        ]
      },

      prefsSelects: [
        //v-for
        {
          id: 'industry',
          value: '',
          label: 'Industry',
          items: Industries,
          info: '',
          multi: false,
          chips: false,
          rules: [
            // v => !!v || 'required',
            (value) => {
              if (!value) return true
              return (value.length > 0) || 'Industry requires a selection'
            }
          ]
        },
        {
          id: 'location',
          value: '',
          label: 'Location',
          items: Location.map(item => item === '-' ? { divider: true } : item),
          info: '',
          multi: false,
          chips: false,
          rules: [
            // v => !!v || 'required',
            (value) => {
              if (!value) return true
              return (value.length > 0) || 'Location requires a selection'
            }
          ]
        }
      ],

      duration: {
        amount: {
          id: 'duration',
          value: '',
          label: '-',
          amounts: [
            PERMANENT_DURATION,
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12'
          ],
          rules: [
            // v => !!v || 'required',
            (value) => {
              const errorMessage = 'Select amount of days/weeks/months'
              if (value === PERMANENT_DURATION) return true

              if (!!this.duration.timeframe.value && !value) return errorMessage
              if (!value) return true
              return (value > 0) || errorMessage
            }
          ]
        },
        timeframe: {
          id: 'timeframe',
          value: '',
          label: '-',
          time: ['Days', 'Weeks', 'Months'],
          rules: [
            // v => !!v || 'required',
            (value) => {
              const errorMessage = 'Select either days/weeks/months'
              if (this.duration.amount.value === PERMANENT_DURATION) return true

              if (!!this.duration.amount.value && !value) return errorMessage
              if (!value) return true
              return (value.length > 0) || errorMessage
            }
          ]
        }
      },

      dayRate: {
        currency: {
          id: 'currency',
          value: '',
          label: '-',
          symbols: ['€', '£', '$'],
          rules: [
            // v => !!v || 'required',
            (value) => {
              if (!value) return true
              return (value.length > 0) || 'Select a currency'
            }
          ]
        },
        rate: {
          id: 'rate',
          value: '',
          label: '-',
          amounts: [
            ...Array.from({ length: (2000 - 100) / 100 + 1 }, (_, i) => {
              const value = 100 + (i * 100)
              return { text: value, value }
            }),
            { text: 'TBC', value: 0 }],
          rules: [
            // v => (v !== undefined && v !== null) || 'required',
            (value) => {
              return (value >= 0) || 'Select a day rate amount'
            }
          ]
        }
      },

      dates: {
        start: {
          id: 'startDate',
          date: new Date().toISOString().substr(0, 10),
          value: null,
          rules: [
            // v => !!v || 'required',
            (value) => {
              if (!value) return true
              return (value.length > 1) || 'Select a starting date'
            }
          ]
        },
        // end: {
        //   id: 'endDate',
        //   value: null,
        //   rules: [
        //     // v => !!v || 'required',
        //     (value) => {
        //       if (!value) return true
        //       return (value.length > 1) || 'Select a closing date'
        //     }
        //   ]
        // }
      },

      networkPrefs: {
        radioGroup: {
          id: 'networkPref',
          value: 0,
          items: [
            {
              id: 'All',
              label: 'Publish to all Riverflex members',
              info:
                'Share your opportunity with the full Riverflex network. This is a free service for independent consultants.',
              value: 1
            },
            {
              id: 'MemberNetwork',
              label: 'Your entire network',
              info:
                'Selecting this will notify all of your connections when you publish this opportunity',
              value: 2
            },
            {
              id: 'Specific',
              label: 'Specific connections',
              info: 'Select connections from the drop down menu',
              value: 3
            }
          ]
        },
        selectConn: {
          id: 'selectedConnections',
          label: 'Select Connections',
          info: '',
          value: []
        }
      },

      shareable: {
        id: 'shareable',
        value: false
      },

      offer: {
        id: 'offerId',
        value: ''
      },

      screeningQuestions: {
        id: 'screeningQuestions',
        value: []
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isRecommendedInfoOpened () {
      return this.showRecommendedInfo === 0
    }
  },
  methods: {
    async hideModal () {
      oppEditEvents.trackClose()
      this.$emit('hide')
      await waitAsync(MODAL_TRANSITION_TIME)
      this.showRecommendedInfo = null
    },
    async handleNextClick () {
      oppEditEvents.trackNextTap()
      if (!this.validateForm()) return

      if (!this.isRecommendedInfoOpened) {
        this.showRecommendedInfo = 0
        await waitAsync(300)
        this.$refs.recommendedInfo.scrollIntoView()
        return
      }

      this.publishDialog = true
      this.snackbar = true
    },
    handlePrefsClear (index) {
      Vue.set(this.prefsSelects, index, { ...this.prefsSelects[index], value: '' })
    },
    handleSaveClick () {
      if (!this.validateForm()) return
      oppEditEvents.trackSave()

      const input = this.getFormData()
      this.submitOpp(this.editOpportunity.id, input)
    },
    showPublishDialog () {
      this.publishDialog = true
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    validateForm () {
      return this.$refs.form.validate()
    },
    handleOpportunityPublish (support) {
      const input = this.getFormData()

      if (support) input.networkType = NETWORK_TYPES.SUPPORT_REQUESTED
      if (this.editOpportunity && this.editOpportunity.status === OPP_STATUSES.SUPPORT_PROVIDED && support) {
        input.status = null
      }
      if (this.isAdmin && this.editOpportunity && this.editOpportunity.networkType === NETWORK_TYPES.SUPPORT_REQUESTED) {
        input.status = OPP_STATUSES.SUPPORT_PROVIDED
      }

      const id = this.editOpportunity ? this.editOpportunity.id : null
      this.submitOpp(id, input)
    },
    async submitOpp (id, input) {
      this.$emit('submitOpp', { id, input })
      await waitAsync(MODAL_TRANSITION_TIME)
      this.updateFields()
      this.showRecommendedInfo = null
    },
    trackFieldEdit (fieldName) {
      trackOppFieldEdit(fieldName)
    },
    trackFieldFinishEdit (fieldName) {
      trackOppFieldFinishEdit(fieldName)
    },
    getFormData () {
      const input = this.$refs.form.inputs.reduce((result, input) => {
        const { id, value, inputValue } = input
        if ((isNil(value) && isNil(inputValue)) || !id) {
          return result
        }

        result[id] = isNil(value) ? inputValue : value // inputValue for checkboxes
        return result
      }, {})
      input.screeningQuestions = this.getScreeningQuestionsData()
      return input
    },
    updateFields: function () {
      if (!!this.editOpportunity) {
        this.descFields.headline.value = this.editOpportunity.headline
        this.descFields.description.value = this.editOpportunity.description
        this.descFields.role.value = this.editOpportunity.role
        this.rolePreview.value = this.editOpportunity.rolePreview

        this.prefsSelects[0].value = this.editOpportunity.industry
        this.prefsSelects[1].value = this.editOpportunity.location

        this.duration.amount.value = this.editOpportunity.duration
        this.duration.timeframe.value = this.editOpportunity.timeframe

        this.dayRate.currency.value = this.editOpportunity.currency
        this.dayRate.rate.value = this.editOpportunity.rate || 0

        if (this.editOpportunity.startDate) {
          this.dates.start.value = new Date(this.editOpportunity.startDate).toISOString().substr(0, 10)
        }
        // if (this.editOpportunity.endDate) {
        //   this.dates.end.value = new Date(this.editOpportunity.endDate).toISOString().substr(0, 10)
        // }

        this.networkPrefs.radioGroup.value = this.editOpportunity.networkType
        this.networkPrefs.selectConn.value = this.editOpportunity.selectedConns

        this.shareable.value = this.editOpportunity.shareable
        this.offer.value = this.editOpportunity.offerId
        this.screeningQuestions.value = this.editOpportunity.screeningQuestions || []
      } else {
        this.descFields.headline.value = ''
        this.descFields.description.value = ''
        this.descFields.role.value = null
        this.rolePreview.value = null

        this.prefsSelects[0].value = null
        this.prefsSelects[1].value = null

        this.duration.amount.value = null
        this.duration.timeframe.value = null

        this.dayRate.currency.value = null
        this.dayRate.rate.value = 0

        this.dates.start.value = new Date().toISOString().substr(0, 10)
        // this.dates.end.value = null

        this.networkPrefs.radioGroup.value = ''
        this.networkPrefs.selectConn.value = []

        this.shareable.value = false
        this.offer.value = ''
        this.screeningQuestions.value = []
      }
    },

    // This method prepares data for request
    // Fix issues with order (since user can add/remove/sort items)
    // Remove system fields ([__typename)], answerOptions where it's needed
    getScreeningQuestionsData () {
      const optionalFields = ['id', 'extensions']
      return this.screeningQuestions.value
        .map((item, i) => {
          const cleanItem = { body: item.body, order: i, type: item.type }

          optionalFields.forEach(field => {
            if (item[field]) cleanItem[field] = item[field]
          })

          if (!item.answerOptions) return cleanItem

          const answerOptions = item.answerOptions.map(({ body, order }, j) => ({
            body, order: j
          }))

          return { ...cleanItem, answerOptions }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.main-panel {
  padding-bottom: 60px !important;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
}
</style>
