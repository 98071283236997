import { PartialBy } from '@/types/utils'

export interface IScreeningQuestion {
  id: number
  type: ScreeningQuestionType
  body: string
  order: number
  extensions?: string[]
  answerOptions?: IAnswerOption[]
}

export interface IScreeningQuestionEditable extends IScreeningQuestion {
  answer?: string
}

export type IControlItem = PartialBy<IScreeningQuestion, 'id'>

export interface IAnswerOption {
  body: string
  order: number
}

export enum ScreeningQuestionType {
  boolean = 'boolean',
  multiChoice = 'multiChoice',
  singleChoice = 'singleChoice',
  string = 'string',
  file = 'file'
}

export interface IScreeningQuestionTypeItem {
  label: string
  type: ScreeningQuestionType
  icon: string
}