




























































import { mapGetters } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IUser } from '@/types/models/user.model'
import FileUploader from '@/components/core/FileUploader.vue'
import { uploadCvDialogEvents } from '@/segmentAnalytics/profile'

@Component({
  components: {
    FileUploader
  },
  computed: {
    ...mapGetters(['cvFileName', 'cvFileUrl'])
  }
})
export default class UploadCV extends Vue {
  @Prop({ default: false }) showCvRequiredError: boolean

  // data
  uploadDialog: boolean = false
  uploading: boolean = false

  // computed
  cvFileName: string
  cvFileUrl: string

  // methods
  handleUploadResumeClick () {
    this.$emit('uploadBtnClick')
    this.uploadDialog = true
  }

  hideModal () {
    this.uploadDialog = false
  }

  async upload (file: File) {
    const fileExt = this.getFileExt(file)
    if (!fileExt) return

    uploadCvDialogEvents.trackSubmit(fileExt)
    this.uploading = true
    await this.$store.dispatch('uploadCV', file)
    this.uploading = false
    this.$emit('cvUploaded')
    this.hideModal()
  }

  handleFileSelect (file: File) {
    const fileExt = this.getFileExt(file)
    if (!fileExt) return
    uploadCvDialogEvents.trackSelectFile(fileExt)
  }

  getFileExt (file: File): string|undefined {
    return file.name.split('.').pop()
  }
}
