<template>
  <div>
    <v-row col="12">
      <v-col>
        <SelectWithGroupedOptions
          :id="prefRoles.id"
          :label="prefRoles.label"
          :info="prefRoles.info"
          :options="prefRoles.items"
          :value.sync="prefRoles.value"
          :multi="prefRoles.multi"
          :disabled="prefRoles.value.length >= prefRoles.maxItems"
          @selectChange="updateField(prefRoles.id, $event)"
          @clearAll="handleClearAll(prefRoles)"
          @checkOption="handleOptionCheck"
        />
        <div class="pl-3 pr-3 pt-2 subtitle-2 brownGrey--text">
          Please select {{ prefRoles.maxItems }} functional roles. You have already picked {{ prefRoles.value.length }}/{{ prefRoles.maxItems }}
        </div>
      </v-col>
    </v-row>
    <v-row
      v-for="select in selects"
      col="12"
      :key="select.label"
    >
      <v-col>
        <v-autocomplete
          class="autocomplete-select"
          :label="select.label"
          :items="select.items"
          item-color="#2e00df"
          max-height="39"
          :multiple="select.multi"
          :item-text="select.label"
          :item-value="select.value"
          solo
          single-line
          hide-details
          deletable-chips
          off-icon="radio_button_unchecked"
          on-icon="check_circle"
          v-model="select.value"
          clearable
          @change="handleAutocompleteChange(select, $event)"
        />

        <div class="pl-3 pr-3 pt-2 caption grey--text text--darken-1">
          {{ select.info }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import Roles, { roleGroups } from '@/assets/profile/roles'
import Industries from '@/assets/profile/industries'
import Rates from '@/assets/profile/rates'
import SelectWithGroupedOptions from '@/components/core/SelectWithGroupedOptions'
import { trackDayRateTap, trackIndustryTap, trackRoleChoiceTap } from '@/segmentAnalytics/profile'

export default {
  props: { user: { type: Object } },
  components: {
    SelectWithGroupedOptions
  },
  data () {
    return {
      selectWidth: 350,
      selects: [
        {
          id: 'prefIndustries',
          label: 'Preferred Industries',
          items: Industries,
          info:
            'Selecting your preferred industry helps Riverflex to match you to more relevant opportunities and makes you more visible to the network.',
          value: this.user.prefIndustries,
          multi: true
        },
        {
          id: 'targetRate',
          label: 'Target Day Rate',
          items: Rates,
          info: 'Provide an indication of your target rate or preferred bandwidth.',
          value: this.user.targetRate,
          multi: false
        }
      ],
      prefRoles: {
        id: 'prefRoles',
        label: 'What roles are you looking for?',
        items: roleGroups,
        value: this.user.prefRoles || [],
        multi: true,
        maxItems: 10,
      }
    }
  },
  methods: {
    updateField (key, value) {
      this.$store.dispatch('updateProfile', { [key]: value })
    },
    trackIndustrySelect (industriesCurrentValue = []) {
      if (industriesCurrentValue.length > (this.user.prefIndustries || []).length) {
        trackIndustryTap(industriesCurrentValue[industriesCurrentValue.length - 1])
      }
    },
    handleClearAll (select) {
      Vue.set(select, 'value', [])
      this.updateField(select.id, [])
    },
    handleAutocompleteChange (field, value) {
      if (field.id === 'targetRate') {
        trackDayRateTap(field.value)
      } else if (field.id === 'prefIndustries') {
        this.trackIndustrySelect(value)
      }
      this.updateField(field.id, field.value)
    },
    handleOptionCheck (value) {
      trackRoleChoiceTap(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select ::v-deep .v-select__selections {
  margin: 0;
}
.v-input ::v-deep .v-input__slot {
  padding: 5px 10px 0px;
}
.v-input {
  font-size: 1rem;
}
.autocomplete-select .v-input ::v-deep .v-label {
  font-size: 1.25rem;
}
</style>
