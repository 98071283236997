



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import viewApplicants from '@/components/opportunities/applicants/viewApplicants.vue'
import viewApplication from '@/components/opportunities/applicants/viewApplication.vue'
import declineApplication from '@/components/opportunities/applicants/declineApplication.vue'
import confirmDialog from '@/components/core/ConfirmDialog.vue'

import { IApplicant, IApplication } from '@/types/models/application.model'
import { IUser } from '@/types/models/user.model'
import { IOpportunity } from '@/types/models/opportunity.model'
import { waitAsync } from '@/utils/common'
import { MODAL_TRANSITION_TIME } from '@/constants'
import { oppApplicantionConfirmEvents } from '@/segmentAnalytics/opportunities'
import APPLICATION_STATUSES from '@/assets/application-statuses'

@Component({
  components: {
    viewApplicants,
    viewApplication,
    declineApplication,
    confirmDialog
  },
  computed: {
    ...mapGetters([
      'profile',
      'profileID',
      'applicationByOppId',
    ])
  }
})
export default class ViewApplicantsModule extends Vue {
  @Prop() opportunitySelected?: IOpportunity
  @Prop() showApplicantsDialog: boolean

  // data
  applicationSelected?: IApplication|null = null
  applicantSelected?: IApplicant|IUser|null = null

  // applicantsDialog: boolean = false
  applicationDialog: boolean = false
  declineDialog: boolean = false
  meetingDialog: boolean = false
  confirmDialog: boolean = false

  // computed
  profile?: IUser
  profileID?: number
  applicationByOppId: Function

  get applicantsDialogIsShown (): boolean {
    return this.showApplicantsDialog
  }

  set applicantsDialogIsShown (value: boolean) {
    this.$emit('update:showApplicantsDialog', value)
  }

  // methods
  public viewApp (applicantID: number) {
    this.applicationSelected = this.applicationByOppId(this.opportunitySelected?.id, applicantID)
    this.applicantSelected = (applicantID === this.profileID) ?
      this.profile :
      this.opportunitySelected?.applicants?.find(user => user.id === applicantID)
    this.applicationDialog = true
  }

  private async declineApp () {
    oppApplicantionConfirmEvents.trackConfirm(APPLICATION_STATUSES.DECLINED)
    this.declineDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.applicationDialog = false
    this.$store.dispatch('declineApplication', this.applicationSelected)
  }

  private async scheduleAppMeeting () {
    oppApplicantionConfirmEvents.trackConfirm(APPLICATION_STATUSES.MEETING)
    this.$store.dispatch('scheduleApplicantMeeting', this.applicationSelected)
    this.meetingDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.applicationDialog = false
  }

  private async confirmApp () {
    oppApplicantionConfirmEvents.trackConfirm(APPLICATION_STATUSES.ACCEPTED)
    this.$store.dispatch('confirmApplication', this.applicationSelected)
    this.confirmDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.applicationDialog = false
  }

  private hideApplicantsDialog () {
    this.applicantsDialogIsShown = false
  }

  private hideConfirmDialog () {
    this.confirmDialog = false
    oppApplicantionConfirmEvents.trackClose(APPLICATION_STATUSES.ACCEPTED)
  }

  private hideConfirmMeetingDialog () {
    this.meetingDialog = false
    oppApplicantionConfirmEvents.trackClose(APPLICATION_STATUSES.MEETING)
  }

  private hideConfirmDeclineDialog () {
    this.declineDialog = false
    oppApplicantionConfirmEvents.trackClose(APPLICATION_STATUSES.DECLINED)
  }
}
