import industries from '@/assets/profile/industries'
import locations from '@/assets/fields/location'
import roles from '@/assets/profile/roles'
import rates from '@/assets/profile/rates'
import colorsMap from '@/assets/theme-colors'

const industryValues = [ ...industries ] as const
const locationValues = [ ...locations ] as const
const roleValues = [ ...roles ] as const
const rateValues = [ ...rates ] as const

// convert array to union type, e.g.
// [ 'Afghanistan', 'Albania', 'Algeria', ... ]
// to type
// 'Afghanistan' | 'Albania' | 'Algeria' | ...
export type IIndustry = typeof industryValues[number]

export type ILocation = typeof locationValues[number]

export type IRole = typeof roleValues[number]

export type IRate = typeof rateValues[number]

export type IColor = keyof typeof colorsMap;