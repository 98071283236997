import API from './api.service'
import gql from 'graphql-tag'
import { IAdminSettings } from '@/types/models/adminSettings.model'

export const fragment = gql`
  fragment AdminSettingsData on AdminSettings {
    newJoinersNotificationEmail
    newApplicantsNotificationEmail
    newCVNotificationEmail
  }
`

export class AdminSettingsAPI extends API {

  // queries
  async getAdminSettings (input?: Partial<IAdminSettings>): Promise<IAdminSettings[]> {
    const query = await this.query<{ getAdminSettings: IAdminSettings[] }>(gql`
      ${fragment}
      query getAdminSettings {
        getAdminSettings {
          ...AdminSettingsData
        }
      }`, { fetchPolicy: 'no-cache' })
    return query.getAdminSettings
  }

  // mutations
  async updateAdminSettings (input: Partial<IAdminSettings>): Promise<IAdminSettings> {
    const response = await this.mutation<{ updateAdminSettings: IAdminSettings }>(gql`
      ${fragment}
      mutation updateAdminSettings {
        updateAdminSettings(input: ${this.input(input)}) {
          ...AdminSettingsData
        }
      }
    `)
    return response.updateAdminSettings
  }

}

export default new AdminSettingsAPI()
