import Vue from 'vue'
import router from '@/router'
import axios from 'axios'

import AuthService from '@/services/auth.service'
import UserAPI from '@/services/user.service'
import StorageService from '@/services/storage.service'
import MEMBER_STATUSES from '@/assets/member-statuses'
import { IUser, MemberStatus } from '@/types/models/user.model'
import { Module } from 'vuex'
import RootState from '@/types/rootState'
import { IAuthState, IIdTokenPayload } from '@/types/api'
import { waitAsync } from '@/utils/common'
import { getFileNameFromUrl } from '@/utils/vuex'
import { identifyUser } from '@/segmentAnalytics/core'
import { trackUserLogin } from '@/segmentAnalytics/registration'

interface IState {
  profile: Partial<IUser>,
  // fileProgress: number,
  isNewUser: boolean,
  isProfileUpdated: boolean, // indicator for sending statistics after user leaves profile page
  loggedOut: boolean
}

const state: IState = {
  profile: {},
  // fileProgress: 0,
  isNewUser: false,
  isProfileUpdated: false,
  loggedOut: false
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    authenticated (state): boolean {
      return !!state.profile.id
    },
    profile (state): Partial<IUser>|null {
      return state.profile.id ? state.profile : null
    },
    profileID (state): number|undefined {
      return state.profile.id
    },
    isAdmin (state): boolean {
      return !!state.profile?.admin
    },
    memberStatus (state): MemberStatus|undefined {
      return state.profile?.memberStatus
    },
    isMember (state, getters): boolean {
      return !getters.memberStatus // 0 or null
    },
    isInsider (state): boolean {
      return state.profile?.memberStatus === MemberStatus.INSIDER
    },
    emailSubscription (state): boolean {
      return !!state.profile?.email_subscribe
    },
    profilePercent (state): number {
      const includedFields = [
        'role',
        'location',
        'about',
        'website',
        'linkedin',
        'phone',
        'email',
        'prefRoles',
        'prefIndustries',
        'targetRate'
      ]
      const populatedFields = includedFields.reduce((result: number, name) => {
        const profileValue = (state.profile as any)[name]
        return (Array.isArray(profileValue) ? !profileValue.length : !profileValue) ? result : ++result
      }, 0)
      return Math.round(populatedFields / includedFields.length * 100)
    },
    loggedOut (state): boolean {
      return state.loggedOut
    },
    currentUserHasCv (state, getters): boolean {
      return !!getters.cvFileUrl
    },
    canSeeUsersNetwork (state, getters): boolean {
      if (getters.isAdmin) return true
      if (getters.isMember && !getters.currentUserHasCv) return false
      return true
    },
    isNewUser (state): boolean {
      return state.isNewUser
    },
    cvFileName (state): string {
      if (!state.profile?.cvS3Url) return ''
      return getFileNameFromUrl(state.profile.cvS3Url)
    },
    cvFileUrl (state): string {
      return state.profile?.cvS3Url || ''
    },
    gdprAccepted (state): boolean {
      return !!state.profile?.gdprAccepted
    }
    // fileProgressPercent (state): number {
    //   let percent = Math.round(
    //     (100 / state.fileProgress.total) * state.fileProgress.loaded
    //   )
    //   if (percent > 100) percent = 100
    //   return percent
    // },
  },
  mutations: {
    profile (state, payload: Partial<IUser> = {}) {
      state.profile = payload
    },
    updateCV (state, cvS3Url: string) {
      state.profile = { ...state.profile, cvS3Url }
    },
    setIsNewUser (state, isNewUser: boolean) {
      state.isNewUser = isNewUser
    },
    setProfileUpdated (state, isProfileUpdated: boolean) {
      state.isProfileUpdated = isProfileUpdated
    },
    setLoggedOut (state, loggedOut: boolean) {
      state.loggedOut = loggedOut
    },
    updateProfileBusinessInfoSet (state, isBusinessInfoSet: boolean) {
      state.profile.isBusinessInfoSet = isBusinessInfoSet
    }
  },
  actions: {
    async login (context, session: IIdTokenPayload|null) {
      const authResult = session

      if (!authResult) return

      const authState: IAuthState = authResult.authState || {}
      const user = !!authState.migrantKey ?
        await context.dispatch('migrateUser', authState.migrantKey) :
        await context.dispatch('fetchOrCreateUser', authResult)

      if (!user) {
        Vue.$logger?.logError(new Error('No user returned on login'), authResult)
        if (context.rootState.status.displayError) {
          await waitAsync(5000)
        }
        context.dispatch('localLogout')
        return
      }

      context.commit('profile', user)

      Vue.$logger.setupScope(user)
      identifyUser(user, undefined, () => {
        trackUserLogin(user.lastLogin)
      })
      if (authState.redirectTo || authState.migrantKey) {
        router.push({ path: authState.migrantKey ? '/profile' : authState.redirectTo || '/home' })
        return
      }

      if (router.currentRoute.path === '/') {
        router.push('/home')
      }
    },
    async fetchOrCreateUser (context, authResult: IIdTokenPayload) {
      const user = await context.dispatch('fetchUser', authResult)
      if (user) return user

      const newUser = await UserAPI.upsert({
        input: {
          available: true,
          sub: authResult.sub,
          email: authResult.email,
          email_verified: authResult.email_verified,
          name: authResult.name,
          family_name: authResult.family_name,
          given_name: authResult.given_name,
          picture: authResult.picture,
          aud: authResult.aud,
          nickname: authResult.nickname,
          memberStatus: MEMBER_STATUSES.MEMBER
        }
      }).catch(error => {
        Vue.$logger?.logError(error, {
          authResult: {
            email: authResult.email,
            name: authResult.name,
            aud: authResult.aud,
            sub: authResult.sub,
          }
        })
        context.dispatch('setStatusError', error)
      })

      context.commit('setIsNewUser', true)
      // @ts-ignore
      this._vm.$analytics.sendSignup({ user: newUser })

      return newUser
    },
    async fetchUser (context, authResult: IIdTokenPayload) {
      let user = await UserAPI.me().catch(console.warn)
      if (!user) return null

      // when user changes name, picture in linkedIn profile, we need to update it in profile
      if (user.name !== authResult.name || user.picture !== authResult.picture) {
        const updatedUser = await UserAPI.upsert({
          id: user.id,
          input: {
            name: authResult.name,
            family_name: authResult.family_name,
            given_name: authResult.given_name,
            picture: authResult.picture
          }
        }).catch(error => {
          Vue.$logger?.logError(error, {
            name: authResult.name,
            family_name: authResult.family_name,
            given_name: authResult.given_name,
            sub: authResult.sub,
            aud: authResult.aud,
          })
          context.dispatch('setStatusError', error)
        })
        if (updatedUser) user = { ...user, ...updatedUser }
      }

      // @ts-ignore
      if (!user?.candidateId) {
        await UserAPI.candidate().catch(console.warn)
      }

      // @ts-ignore
      this._vm.$analytics.sendLogin({ user })

      return user
    },
    async migrateUser (context, migrantKey) {
      return UserAPI.migrate(migrantKey)
        .catch(error => {
          Vue.$logger.logError(error, { migrantKey })
          context.dispatch('setStatusError', error)
        })
    },
    logout (context) {
      AuthService.logout({ returnTo: window.location.origin })
    },

    localLogout (context) {
      AuthService.logout({ localOnly: true })
      context.commit('profile', {})
      context.commit('setLoggedOut', true)
      Vue.$logger.clearScope()
      router.push({ name: 'login' })
    },

    async updateProfile (context, payload) {
      const user = await UserAPI.upsert({
        id: context.state.profile.id,
        input: payload
      }).catch(error => context.dispatch('setStatusError', error))
      if (!user) return
      context.commit('setProfileUpdated', true)
      context.commit('profile', user)
      identifyUser(user)
    },

    async uploadCV (context, file: File) {
      const errorMessage = 'File size must not be more than 10MB! Allowed files are pdf, doc, docx!'
      
      const presignedPostData = await StorageService.getCvPresignedPost(`cv/${file.name}`)
        .catch(error => context.dispatch('setStatusError', error))

      if (!presignedPostData) return

      const response = await StorageService.uploadFileToS3(file, presignedPostData).catch(error => {
        Vue.$logger?.logError(error, {
          fileName: file.name,
          fileType: file.type,
          s3responseData: error?.response?.data,
          s3responseStatus: error?.response?.status,
        })
        context.dispatch('setStatusError', new Error(errorMessage))
      })
      if (!response) return
      const fileUrl = await StorageService.updateCv(`cv/${file.name}`)
        .catch(error => context.dispatch('setStatusError', error))
      context.commit('updateCV', fileUrl)

      // @ts-ignore
      this._vm.$analytics.sendCVupload()

      return fileUrl
    },

    sendProfileUpdateStats (context) {
      if (!context.state.isProfileUpdated) return
      context.commit('setProfileUpdated', false)
      // @ts-ignore
      this._vm.$analytics.sendProfileUpdate()
    }
  }
}

export default store