





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import { IOpportunity } from '@/types/models/opportunity.model'
import { IRecruiteeOffer } from '@/types/models/recruitee.model'
import recruiteeAPI from '@/services/recruitee.service'
import { IOption } from '@/types/form'

@Component({
  components: {},
  computed: {
    ...mapGetters([])
  }
})
export default class RecruiteeOfferSelect extends Vue {
  @Prop({ default: 'offerId' }) selectId: string
  @Prop() value: string|number
  selectOptions: IOption[] = []

  get selectedValueModel (): string|number {
    return this.value
  }

  set selectedValueModel (value: string|number) {
    this.$emit('update:value', value)
  }

  async created () {
    const offers = await recruiteeAPI.getJobOffers().catch(error => {
      this.$store.dispatch('setStatusError', error)
    })
    if (offers) {
      this.selectOptions = offers.map(offer => ({ text: offer.title, value: offer.id }))
    }
  }

  handleSelectChange (value: string) {
    this.$emit('selectOffer', value)
  }
}
