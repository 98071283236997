












import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class NetworkStatus extends Vue {

  isOffline: boolean = false

  created () {
    window.addEventListener('online',  this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOfflineStatus)
  }

  updateOnlineStatus () {
    this.isOffline = false
  }

  updateOfflineStatus () {
    this.isOffline = true
  }
}
