import { IRate, IRole, IIndustry } from '@/types/dictionaries'
import IFile from './storage.model'
import { IAchievement } from '@/types/models/achievement.model'

export enum MemberStatus {
  MEMBER = 0,
  INSIDER = 1
}

export interface ISimpleInvite {
  id: number
  from: number
  to: number
}

export interface IInvite {
  id: number
  from: IUser
  to: IUser
}

export interface IUser {
  id: number
  available: boolean
  name: string
  nickname?: string
  family_name: string
  given_name: string
  email: string
  email_verified: boolean
  picture: string
  role?: string
  location?: string
  about?: string
  weeklyAvailability?: string
  nextAvailabilityNotificationAt?: string
  website?: string
  linkedin?: string
  phone?: string
  aud: string
  sub: string
  targetRate?: IRate
  prefRoles?: IRole[]
  prefIndustries?: IIndustry[]
  cvS3Url?: string
  memberStatus?: MemberStatus
  admin?: boolean
  referrerUser?: {
    id: number
    name: string
    picture: string
  }
  email_subscribe?: boolean
  candidateId?: number
  connectionsNumber?: number
  referralsNumber?: number
  opportunitesPublishedNumber?: number
  achievements?: number[]
  isConnectedToMe: boolean
  createdAt?: string
  lastLogin?: string
  gdprAccepted?: boolean
  isBusinessInfoVerified?: boolean
  isBusinessInfoSet?: boolean
}