import API from './api.service'
import gql from 'graphql-tag'
import { ReportType } from '@/types/models/report.model'

interface IGenerateReportsArgs {
  reportNames: ReportType[]
  startDate?: string
  endDate?: string
}

export class ReportService extends API {

  async generateReports ({ reportNames, startDate, endDate }: IGenerateReportsArgs): Promise<{ generateReports: string }> {
    let datesArgs = ''
    if (startDate) datesArgs += `, startDate: "${startDate}"`
    if (endDate) datesArgs += `, endDate: "${endDate}"`

    return await this.mutation<{ generateReports: string }>(gql`
      mutation generateReports {
        generateReports (reportNames: [${reportNames}] ${datesArgs})
      }
    `)
  }

}

export default new ReportService ()
