










































































import messages from '@/assets/notifications'
import networkTypes from '@/assets/network-types'
import Pagination from '@/components/core/Pagination.vue'

import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { IPagination } from '@/types/api'
import INotification from '@/types/models/notification.model'

@Component({
  components: {
    Pagination
  },
  computed: mapGetters([
    'notifications',
    'notificationsTotal'
  ])
})
export default class NotificationMessages extends Vue {
  @Prop() seeMore: boolean

  $refs: {
    notificationsContainer: HTMLDivElement
  }

  // data
  isMounted: boolean = false
  networkTypes: any = networkTypes
  messages: any = messages
  noItems: any = [{
    icon: messages.default.icon,
    content: messages.default.message
  }]
  itemHeight: number = 64
  pagination: any = {
    isFetchingMore: false
  }

  // state
  notifications: INotification[]
  notificationsTotal: number

  get displayNotifications (): any {
    if (!this.notifications.length) return [{ type: 'default', message: 1 }]
    return this.notifications
  }

  get containerHeight (): number {
    return this.itemHeight * (this.seeMore ? 6 : 3)
  }

  get containerStyle (): string {
    return `max-height: ${this.containerHeight}px`
  }

  get $containerRef (): HTMLDivElement {
    return this.$refs.notificationsContainer
  }

  async handleFetchMore ({ page, limit }: IPagination) {
    this.pagination.isFetchingMore = true
    await this.$store.dispatch('fetchMoreNotifications', { page })
    this.pagination.isFetchingMore = false
  }

  created () {
    this.$store.dispatch('listNotifications')
  }

  mounted () {
    // needs for having root contaner $ref for scrolling pagination
    this.isMounted = true
  }

}
