





























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IOption } from '@/types/form'
import { waitAsync } from '@/utils/common'
import { IRole } from '@/types/dictionaries'

interface IOptionItem {
  title: string
  items: IOption[]|string[]|number[]
}

@Component
export default class SelectWithGroupedOptions extends Vue {
  @Prop({ required: true, default: 'SelectWithGroupedOptions' }) id: string
  @Prop({ required: true, default: '' }) label: string
  @Prop({ required: true, default: [] }) options: IOptionItem[]
  @Prop({ default: false }) disabled: boolean
  @Prop() value: any
  @Prop({ default: false }) multi: boolean
  @Prop({ default: 350 }) selectWidth: number

  isOpened: boolean = false
  openedGroup: any = null

  get selectedValue () {
    return this.value
  }

  set selectedValue (value) {
    this.$emit('update:value', value)
  }

  isOptionSelected (value: any): boolean {
    return this.selectedValue.includes(value)
  }

  isOptionDisabled (value: any): boolean {
    return !this.isOptionSelected(value) && this.disabled
  }

  async handleSelectChange (value: any) {
    this.$emit('selectChange', value)
  }

  handleClearAllClick () {
    this.$emit('clearAll')
  }

  async handleCheckboxClick (value: IRole) {
    await waitAsync(100)
    const isCheckboxChecked = !!this.selectedValue.find((item: string) => item === value)
    if (isCheckboxChecked) this.$emit('checkOption', value)
  }
}
