






























import { Component, Prop, Vue } from 'vue-property-decorator'

import ScreeningQuestionIcon from '@/components/screeningQuestions/ScreeningQuestionIcon.vue'
import { IApplicationAnswer } from '@/types/models/application.model'
import { IScreeningQuestion } from '@/types/models/screeningQuestion.model'

@Component({
  components: {
    ScreeningQuestionIcon
  }
})
export default class ScreeningQuestionsList extends Vue {
  @Prop({ required: true, default: [] }) questions: IScreeningQuestion[]

}
