
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ItemsNumberCol extends Vue {
  @Prop() itemsNumber?: number
  @Prop() icon: string
  @Prop({ required: true }) singularLabel: string
  @Prop() pluralLabel: string
  @Prop({ default: 20 }) maxWidth: number

}
