import { IScreeningQuestionTypeItem, ScreeningQuestionType } from '@/types/models/screeningQuestion.model'

export const MODAL_TRANSITION_TIME = 500
export const PAGINATION_LIMIT = 10
export const NEW_VERSION_AVAILABLE_DISPLAY_TIME = 3000
export const SEA_BLUE_BG_GRADIENT = 'background-image: linear-gradient(270deg, rgba(7,209,182,1) 0%, rgba(46,149,197,1) 100%)'
export const ORANGE_BG_GRADIENT = 'background-image: linear-gradient(90deg, rgba(255,139,92,1) 0%, rgba(255,174,0,1) 100%)'
export const ORANGE_BG_GRADIENT_TOP = 'background-image: linear-gradient(360deg, rgba(255,139,92,1) 0%, rgba(255,174,0,1) 100%)'
export const HEADER_HEIGHT = 64
export const PERMANENT_DURATION = 'Permanent Role'
export const SUPPORT_EMAIL = 'info@riverflex.com'

// Comment/uncomment when you have updated secrets and now you simply need to re-deploy app
const GITHUB_SECRETS_UPDATED = '1234'

export const QUESTIONS_LIST: IScreeningQuestionTypeItem[] = [
  {
    label: 'Text',
    type: ScreeningQuestionType.string,
    icon: 'mdi-text-short'
  },
  {
    label: 'Yes/no',
    type: ScreeningQuestionType.boolean,
    icon: 'mdi-radiobox-marked'
  },
  {
    label: 'Single choice',
    type: ScreeningQuestionType.singleChoice,
    icon: 'mdi-check'
  },
  {
    label: 'Multiple choice',
    type: ScreeningQuestionType.multiChoice,
    icon: 'mdi-check-all'
  },
  {
    label: 'Add a file',
    type: ScreeningQuestionType.file,
    icon: 'mdi-paperclip'
  }
]

export const QUESTION_TYPES_MAP: { [key:string]: IScreeningQuestionTypeItem } = QUESTIONS_LIST.reduce((result: any, item: IScreeningQuestionTypeItem) => {
  result[item.type] = item
  return result
}, {})
