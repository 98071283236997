
























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { mapGetters } from 'vuex'

import { isIos, isInstallPWAEventExists, canAddAppToHomeScreen, waitAsync } from '@/utils/common'
import LoginBtnWithRules from '@/components/core/LoginBtnWithRules.vue'
import AuthService from '@/services/auth.service'

@Component({
  components: {
    LoginBtnWithRules
  },
  computed: {
    ...mapGetters(['authenticated'])
  }
})
export default class LoginDialog extends Vue {
  @Prop({ default: false, required: true }) show: boolean
  @Prop({ required: false }) authState: { [key:string]: any }

  // data
  logoUrl: string = require('@/assets/images/logo.svg')

  // computed
  authenticated: boolean

  get showDialog (): boolean {
    return this.show
  }

  set showDialog (value: boolean) {
    this.$emit('update:show', value)
  }

  // lifecycle hooks

  // methods
  hideModal () {
    this.showDialog = false
    this.$emit('hide')
  }

  handleLoginClick () {
    const appState = JSON.stringify(this.authState || {
      redirectTo: this.$route.fullPath
    })
    AuthService.login(appState)
  }

}
