export default {
  heading: 'Member status',
  body: {
    0: {
      title: 'Riverflex Member',
      body:
        'All independent consultants who have created a profile on the Riverflex platform are assigned Riverflex Member status. As a member you are able to use our platform to apply to opportunities owned by other network members, and to connect with fellow independent consultants.'
    },
    1: {
      title: 'Riverflex Insider',
      body:
        'Independent consultants with the appropriate skills and experience will be invited to become a Riverflex Insider through an interview with one of the Riverflex team. As an Insider, you will have access to a broader range of Riverflex resources and be first considered by Riverflex for opportunities. Updating your profile to 100% complete will increase your chance to be invited to Insider status.'
    }
  }
}
