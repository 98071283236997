import { IUser } from '@/types/models/user.model'
import memberStatuses from '@/assets/member-statuses'
import { Route } from 'vue-router'
import { convertToCohortDate } from '@/utils/date'
import { memberStatusToEnum } from '@/utils/user'

export const trackPageScreen = (pageEventName: string) => {
  if (!pageEventName) return
  trackEvent(pageEventName)
}

/**
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 */
export const trackEvent = (eventName: string, props?: {[key:string]: any}, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) => {
  if (!window.analytics) {
    console.warn('Segment is not defined!')
    return
  }
  window.analytics.track(eventName, props, options, callback)
}

/**
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
 */
export const identifyUser = (user: IUser, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) => {
  if (!window.analytics) {
    console.warn('Segment is not defined!')
    return
  }
  window.analytics.identify(String(user.id), {
    email: user.email,
    name: user.name,
    member_status: memberStatusToEnum(user.memberStatus),
    admin: !!user.admin,
    registration_date: convertToCohortDate(user.createdAt),
    availability: !!user.available,
    email_notification: !!user.email_subscribe,
  }, options, callback)
}