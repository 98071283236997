


































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component
export default class ConfirmDialog extends Vue {
  @Prop({ default: 'Confirmation' }) headline: string
  @Prop({ default: false }) loading: boolean

  hideModal () {
    this.$emit('hide')
  }

  confirm () {
    this.$emit('confirm')
  }
}
