
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import LoadingProgress from '@/components/core/LoadingProgress.vue'
import { IAchievement } from '@/types/models/achievement.model'
import { IOption } from '@/types/form'
import { IUser } from '@/types/models/user.model'

@Component({
  components: {
    LoadingProgress
  },
  computed: {
    ...mapGetters(['achievements'])
  }
})
export default class AchievementsSelect extends Vue {
  @Prop() value: number[]
  @Prop() user: IUser

  // data
  isFetchingAchievements: boolean = false
  isUpdatingAchievements: boolean = false

  // computed
  achievements: IAchievement[]

  get options (): IOption[] {
    return (this.achievements || []).map(item => ({
      text: item.title,
      value: item.id,
    }))
  }

  get selected (): number[] {
    return this.value
  }

  set selected (value: number[]) {
    this.$emit('update:value', value)
  }

  // lifecycle hooks
  async created () {
    this.isFetchingAchievements = true
    await this.$store.dispatch('fetchAchievements')
    this.isFetchingAchievements = false
  }

  // methods
  private async handleAchievementsChange (values: number[]) {
    this.isUpdatingAchievements = true
    await this.$store.dispatch('updateUserAchievements', {
      user: this.user,
      achievementIds: values
    })
    this.isUpdatingAchievements = false
  }

}
