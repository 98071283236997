































import { Component, Prop, Vue, Mixins, Emit } from 'vue-property-decorator'

import { IScreeningQuestion, ScreeningQuestionType, IScreeningQuestionTypeItem } from '@/types/models/screeningQuestion.model'
import { QUESTION_TYPES_MAP, QUESTIONS_LIST } from '@/constants'

@Component
export default class ScreeningQuestionsMenu extends Vue {
  questionsList: IScreeningQuestionTypeItem[] = QUESTIONS_LIST

  handleQuestionSelect (question: IScreeningQuestionTypeItem) {
    this.$emit('select', question)
  }
}
