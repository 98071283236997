










































import { Component, Prop, Vue } from 'vue-property-decorator'

import HTMLEditor from '@/components/core/HTMLEditor.vue'
import { IRuleFunction } from '@/types/form'

interface IOpportunityField {
  id: string
  value: string
  minLength: number
  maxLength: number
  rules: IRuleFunction[]
}

@Component({
  components: {
    HTMLEditor
  }
})
export default class Description extends Vue {
  @Prop({ required: true }) descFields: any

  descriptionEditorOptions = {
    placeholder: `For best results please describe:
      1. Your client or project context
      2. The activities or responsibilities for the role
      3. Any deliverables expected"
    `
  }
}
