import _Vue from 'vue'
import { Store } from 'vuex'
import VueAnalytics from 'vue-analytics'

import RootState from '@/types/rootState'
import { IUser } from '@/types/models/user.model'
import { ApplicationStatus } from '@/types/models/application.model'

interface PluginOptions {
  store: Store<RootState>
}

interface EventOptions {
  eventCategory: string
  eventAction?: string
  eventLabel?: string
  eventValue?: number
}

export interface IAnalytics {
  sendSignup: (data: { user: IUser }) => void
  sendLogin: (data: { user: IUser }) => void
  sendReferralSignup: (data: { user: IUser, referral: IUser, oppId: number }) => void
  sendProfileUpdate: () => void
  sendCVupload: () => void
  sendOppPublish: (data: { oppID: number }) => void
  sendOppUnpublish: (data: { oppID: number }) => void
  sendApplyToOpp: (data: { oppID: number }) => void
  sendApplicationState: (data: { appID: number, oppID: number, state: ApplicationStatus }) => void
  sendInvite: (data: { user: IUser }) => void
  sendInviteAccept: (data: { user: IUser }) => void
  sendInviteDecline: (data: { user: IUser }) => void
  sendCopyLinkClick: () => void
  sendInviteViaEmailClick: () => void
}

class Analytics implements IAnalytics {
  private _ga: VueAnalytics
  private _store?: Store<RootState>

  constructor (Vue: typeof _Vue, options: PluginOptions) {
    this._ga = Vue.prototype.$ga
    this._store = options.store
  }

  /////////////////////////////
  ////////// PRIVATE //////////
  /////////////////////////////

  private get _isGAinstalled (): boolean {
    return !!this._ga
  }

  private get _profile (): IUser|undefined {
    return this._store ? this._store.getters.profile : undefined
  }

  private get _analyticsUserId (): string|undefined {
    return this._createAnalyticsUserId(this._profile)
  }

  private _sendEvent ({ eventCategory, eventAction, eventLabel, eventValue }: EventOptions) {
    if (!this._isGAinstalled) return
    if (!eventCategory || !eventAction) {
      return console.warn(`eventCategory: ${eventCategory} or eventAction: ${eventAction} is undefined`)
    }
    if (this._profile) this._ga.set({ userId: this._profile.id, userName: this._profile.name })
    const eventOptions = { eventCategory, eventAction, eventLabel, eventValue }
    // console.log(eventOptions)
    this._ga.event(eventOptions)
  }

  private _createAnalyticsUserId (user?: IUser): string|undefined {
    return !!user ? `${user.name} (${user.id})` : undefined
  }

  //////////////////////////////
  /////////// PUBLIC ///////////
  //////////////////////////////

  public sendSignup ({ user }: Parameters<IAnalytics['sendSignup']>[0]) {
    if (!user) return
    this._sendEvent({
      eventCategory: 'Signup',
      eventAction: `Signup: ${this._createAnalyticsUserId(user)}`,
    })
  }

  public sendLogin ({ user }: Parameters<IAnalytics['sendLogin']>[0]) {
    if (!user) return
    this._sendEvent({
      eventCategory: 'Login',
      eventAction: `Login: ${this._createAnalyticsUserId(user)}`,
    })
  }

  public sendReferralSignup ({ user, referral, oppId }: Parameters<IAnalytics['sendReferralSignup']>[0]) {
    if (!user || !referral) return
    let eventLabel = `Signup referred from user: ${this._createAnalyticsUserId(referral)}`
    if (oppId) eventLabel = `${eventLabel}, shared opportunity: (${oppId})`

    this._sendEvent({
      eventCategory: 'Referral signup',
      eventAction: `Referral signup: ${this._createAnalyticsUserId(user)}`,
      eventLabel
    })
  }

  public sendProfileUpdate () {
    this._sendEvent({
      eventCategory: 'Profile',
      eventAction: 'Profile update',
      eventLabel: `Profile updated by: ${this._analyticsUserId}`,
    })
  }

  public sendCVupload () {
    this._sendEvent({
      eventCategory: 'CV',
      eventAction: 'CV upload',
      eventLabel: `CV uploaded by: ${this._analyticsUserId}`,
    })
  }

  public sendOppPublish ({ oppID }: Parameters<IAnalytics['sendOppPublish']>[0]) {
    if (!oppID) return
    this._sendEvent({
      eventCategory: 'Opportunity published',
      eventAction: `Opportunity published: (${oppID})`,
      eventLabel: `Opportunity published by: ${this._analyticsUserId}`,
    })
  }

  public sendOppUnpublish ({ oppID }: Parameters<IAnalytics['sendOppUnpublish']>[0]) {
    if (!oppID) return
    this._sendEvent({
      eventCategory: 'Opportunity unpublished',
      eventAction: `Opportunity unpublished: (${oppID})`,
      eventLabel: `Opportunity unpublished by: ${this._analyticsUserId}`,
    })
  }

  public sendApplyToOpp ({ oppID }: Parameters<IAnalytics['sendApplyToOpp']>[0]) {
    if (!oppID) return
    this._sendEvent({
      eventCategory: 'Application',
      eventAction: `Applied to opportunity: (${oppID})`,
      eventLabel: `Applied by: ${this._analyticsUserId}`,
    })
  }

  public sendApplicationState ({ appID, oppID, state }: Parameters<IAnalytics['sendApplicationState']>[0]) {
    if (!oppID || !appID || !state) {
      console.warn(`oppID: ${oppID} or appID: ${appID} or state: ${state} is missing`)
      return
    }
    this._sendEvent({
      eventCategory: `Application state: ${state}`, // total number of each {state}
      eventAction: `Application of opportunity (${oppID}) changed state on: ${state} `, // total number apps with {state} of {oppId}
      eventLabel: `State of application (${appID}) changed on: ${state}`,
    })
  }

  public sendInvite ({ user }: Parameters<IAnalytics['sendInvite']>[0]) {
    this._sendEvent({
      eventCategory: 'Invite sent',
      eventAction: `Invite sent from: ${this._analyticsUserId}`,
      eventLabel: `Invite sent to: ${this._createAnalyticsUserId(user)}`,
    })
  }

  public sendInviteAccept ({ user }: Parameters<IAnalytics['sendInviteAccept']>[0]) {
    this._sendEvent({
      eventCategory: 'Invite accepted',
      eventAction: `Invite accepted by: ${this._analyticsUserId}`,
      eventLabel: `Invite accepted from: ${this._createAnalyticsUserId(user)}`,
    })
  }

  public sendInviteDecline ({ user }: Parameters<IAnalytics['sendInviteDecline']>[0]) {
    this._sendEvent({
      eventCategory: 'Invite declined',
      eventAction: `Invite declined by: ${this._analyticsUserId}`,
      eventLabel: `Invite declined from: ${this._createAnalyticsUserId(user)}`,
    })
  }

  public sendCopyLinkClick () {
    this._sendEvent({
      eventCategory: 'Copy referral link click',
      eventAction: `Referral link copy clicked by: ${this._analyticsUserId}`,
    })
  }

  public sendInviteViaEmailClick () {
    this._sendEvent({
      eventCategory: 'Invite via email click',
      eventAction: `Invite via email clicked by: ${this._analyticsUserId}`,
    })
  }

}

export default {
  install (Vue: typeof _Vue, options: PluginOptions) {
    Vue.prototype.$analytics = new Analytics(Vue, options)
  }
}
