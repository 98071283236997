import { Module } from 'vuex'
import Vue from 'vue'

import businessInfoAPI, { BusinessInfoCreateInput } from '@/services/businessInfo.service'
import RootState from '@/types/rootState'
import { IBusinessInfo } from '@/types/models/businessInfo.model'
import StorageAPI from '@/services/storage.service'

interface IState {
  info: Partial<IBusinessInfo>
}

const state: IState = {
  info: {
    isVerified: false
  },
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    businessInfo (state): Partial<IBusinessInfo> {
      return state.info
    },
    isBusinessInfoVerified (state): boolean {
      return !!state.info.isVerified
    },
  },
  mutations: {
    setBusinessInfo (state, payload: IBusinessInfo): void {
      state.info = payload
    },
  },
  actions: {
    async fetchBusinessInfo (context) {
      const data = await businessInfoAPI.getBusinessInfo()
        .catch(error => {
          context.dispatch('setStatusError', error)
        })
      if (!data) return
      context.commit('setBusinessInfo', data)
    },
    async updateBusinessInfo (context, input: BusinessInfoCreateInput): Promise<IBusinessInfo|undefined> {
      const businessInfo = await businessInfoAPI.updateBusinessInfo(input)
        .catch(error => context.dispatch('setStatusError', error))
      if (!businessInfo) return
      if (businessInfo) {
        context.commit('setBusinessInfo', businessInfo)
        context.commit('updateProfileBusinessInfoSet', true)
        return businessInfo
      }
    },

    async uploadBusinessInfoFilesToS3 (context, files: Array<[string, File]>) {
      const fileS3Keys: Record<string, string> = {}
      for (let [fieldName, fileToBeUploaded] of files) {
        const fileKey = `businessInfo/${fileToBeUploaded.name}`
        const presignedPostData = await StorageAPI.getBusinessInfoPresignedPost(fileKey)
          .catch(error => context.dispatch('setStatusError', error))

        const fileUrl = await StorageAPI.uploadFileToS3(fileToBeUploaded, presignedPostData).catch(error => {
          Vue.$logger?.logError(error, {
            fileName: fileToBeUploaded.name,
            s3responseData: error?.response?.data,
            s3responseStatus: error?.response?.status,
          })
        })
        if (fileUrl) fileS3Keys[`${fieldName}S3Key`] = fileKey
      }
      return fileS3Keys
    },
  }
}

export default store