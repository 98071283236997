import { trackEvent } from '@/segmentAnalytics/core'

export const trackConnectionsScreenTab = (tab: string): void => {
  trackEvent('connections_screen_tab', { tab })
}

export const trackConnectionsPageScroll = (tab: string): void => {
  trackEvent('connect_scroll', { tab })
}

export const trackInviteToNetworkBtnTap = (): void => {
  trackEvent('new_connect_tap')
}

type InviteToNewtorkAction = 'copy_link'|'invite_via_email'|'more_information_tap'|'close'
const trackInviteToNewtorkActions = (action: InviteToNewtorkAction): void => {
  trackEvent('new_connect_pop', { action })
}

export const inviteToNewtorkEvents = {
  trackCopyLink: () => {
    trackInviteToNewtorkActions('copy_link')
    trackEvent('new_connect_copy_link_click')
  },
  trackInviteViaEmail: () => {
    trackInviteToNewtorkActions('invite_via_email')
    trackEvent('new_connect_email_click')
  },
  trackMoreInfoTap: () => trackInviteToNewtorkActions('more_information_tap'),
  trackClose: () => trackInviteToNewtorkActions('close'),
}

export const trackConnectUserBtnTap = (): void => {
  trackEvent('net_connect_tap')
}

export const trackConnectionProfileTap = (): void => {
  trackEvent('connect_profile_tap')
}

type ProfileScreenAction = 'phone'|'email'|'referral_to_the_platform'|'close'|'view'
const trackProfileScreenEvents = (action: ProfileScreenAction, profile_id: number): void => {
  trackEvent('connect_profile_screen', { action, profile_id })
}

export const userProfileEvents = {
  trackPhoneTap: (profileId: number) => {
    trackProfileScreenEvents('phone', profileId)
    trackEvent('connect_profile_phone_click', { profileId })
  },
  trackEmailTap: (profileId: number) => {
    trackProfileScreenEvents('email', profileId)
    trackEvent('connect_profile_email_click', { profileId })
  },
  trackReferralsToPlatformTap: (profileId: number) => trackProfileScreenEvents('referral_to_the_platform', profileId),
  trackClose: (profileId: number) => trackProfileScreenEvents('close', profileId),
  trackView: (profileId: number) => trackProfileScreenEvents('view', profileId),
}

const trackUserReferralsActions = (action: 'view_profile'|'close'): void => {
  trackEvent('connect_referral_pop', { action })
}

const trackReferralProfileActions = (action:  'phone'|'email'|'close'): void => {
  trackEvent('connect_referral_profile_pop', { action })
}
export const userReferralsEvents = {
  trackProfileView: () => trackUserReferralsActions('view_profile'),
  trackDialogClose: () => trackUserReferralsActions('close'),
  trackProfileClose: () => trackReferralProfileActions('close'),
}

const trackInviteActions = (action: 'accept'|'decline'): void => {
  trackEvent('invite_pend_screen', { action })
}

export const inviteEvents = {
  trackAccept: () => {
    trackInviteActions('accept')
    trackEvent('invite_pend_accept_click')
  },
  trackDecline: () => {
    trackInviteActions('decline')
    trackEvent('invite_pend_decline_click')
  },
}
