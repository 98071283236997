import { Component, Vue } from 'vue-property-decorator'
import { waitAsync } from '@/utils/common'
import { wasInfoDialogDisplayed, setInfoDialogDisplayed } from '@/utils/common'
import { mapGetters } from 'vuex'

export interface IShowDialogMixin {
  showInfoDialog: boolean
  pageName: string
}

@Component({
  computed: {
    ...mapGetters(['authenticated'])
  }
})
export default class ShowInfoDialogMixin extends Vue implements IShowDialogMixin {
  showInfoDialog: boolean = false
  pageName: string = ''

  authenticated: boolean
  
  private setInfoDialogDisplayed = (pageName: string): boolean => {
    if (wasInfoDialogDisplayed(pageName)) return true
    setInfoDialogDisplayed(pageName)
    return false
  }

  mounted () {
    if (!this.pageName || !this.authenticated) return

    const infoDialogWasDisplayedOnce = this.setInfoDialogDisplayed(this.pageName)
    if (infoDialogWasDisplayedOnce) return
    waitAsync(700).then(() => this.showInfoDialog = true)
  }
}