










import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component
export default class InfoIcon extends Vue {
  @Prop() background: string
  @Prop() color: string
  @Prop({ default: 'pointer' }) cursor: string

  get iconStyle (): string {
    let style = `cursor: ${this.cursor};`
    if (this.background) style += `background: ${this.background};`
    if (this.color) style += `color: ${this.color};`
    return style
  }

  handleClick () {
    this.$emit('onClick')
  }
}
