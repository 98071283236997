






































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import draggable from 'vuedraggable'

import { ScreeningQuestionType, IScreeningQuestionEditable } from '@/types/models/screeningQuestion.model'
import IFile from '@/types/models/storage.model'
import StorageAPI from '@/services/storage.service'
import FileUploader from '@/components/core/FileUploader.vue'
import ScreeningQuestionIcon from '@/components/screeningQuestions/ScreeningQuestionIcon.vue'
import { QUESTION_TYPES_MAP } from '@/constants'
import { waitAsync, isNil } from '@/utils/common'
import { getFileNameFromUrl } from '@/utils/vuex'

@Component({
  components: {
    FileUploader,
    ScreeningQuestionIcon
  }
})
export default class ScreeningQuestionsSurvey extends Vue {
  @Prop({ required: false, default: 'Please answer a survey below' }) title: string
  @Prop({ required: true, default: [] }) questions: IScreeningQuestionEditable[]

  booleanAnswers = [
    { title: 'Yes', value: 'Yes' },
    { title: 'No', value: 'No' }
  ]

  $refs: {
    form: any
  }
  formModel: boolean = true

  showFileUploadDialog: boolean = false
  isFileUploading: boolean = false
  s3FileKey: string = ''
  showFileRequiredError: boolean = false
  questionsData = QUESTION_TYPES_MAP

  rules = {
    requiredRule: [
      (value: string) => !isNil(value) || 'This field is required!',
    ],
    stringRule: [
      (value: string) => !!value || 'This field is required!',
    ],
    isOneOptionSelected: (value?: []): boolean|string => {
      return !(!value || !value.length) || 'Select at least one option!'
    }
  }

  get attachmentFileName (): string {
    return getFileNameFromUrl(this.s3FileKey || '')
  }

  // methods
  public validate () {
    const isValid = this.$refs.form.validate()
    const hasFileAttachment = !!this.questions.find(item => item.type === ScreeningQuestionType.file)
    if (hasFileAttachment && !this.s3FileKey) {
      this.showFileRequiredError = true
    }
    return isValid && !this.showFileRequiredError
  }

  private hideFileUploadDialog () {
    this.showFileUploadDialog = false
  }

  private async uploadFile (file: File) {
    this.isFileUploading = true
    const fileTypeQuestion = this.questions.find(item => item.type === ScreeningQuestionType.file)
    if (!fileTypeQuestion) return

    const s3Folder = `screeningQuestion/${fileTypeQuestion.id}/${file.name}`
    const presignedPostData = await StorageAPI.getScrenningQuestionPresignedPost(s3Folder, fileTypeQuestion.id)
      .catch(error => this.$store.dispatch('setStatusError', error))

    if (!presignedPostData) {
      this.isFileUploading = false
      return
    }

    const response = await StorageAPI.uploadFileToS3(file, presignedPostData).catch(error => {
      Vue.$logger?.logError(error, { userId: this.$store.getters.profileID })
      const errorMessage = 'File size must not be more than 10MB!'
      this.$store.dispatch('setStatusError', new Error(errorMessage))
    })

    if (response) this.s3FileKey = JSON.parse(presignedPostData.fields).key
    this.isFileUploading = false

    if (this.s3FileKey) {
      this.showFileRequiredError = false
      fileTypeQuestion.answer = this.s3FileKey
    }
    this.hideFileUploadDialog()
  }
}
