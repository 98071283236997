<template>
  <v-card class="pa-2 pt-0 pb-0">
    <v-container>
      <v-row
        no-gutters
        align="center"
        class="mb-3"
      >
        <v-col
          v-if="opportunity"
          class="headline"
        >
          {{ opportunity.headline }}
        </v-col>

        <v-col cols="auto">
          <v-btn
            small
            fab
            icon
            @click="hideModal"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div
        v-if="isFetchingData"
        class="text-center py-10"
      >
        <LoadingProgress />
      </div>
      <v-alert
        v-else-if="applicants.length == 0"
        class="text-center"
        type="warning"
        :icon="false"
      >
        This opportunity doesn't have applicants
      </v-alert>

      <div v-else>
        <v-row no-gutters>
          <v-col class="title">
            {{ applicants.length }} Application{{ applicants.length > 1 ? 's' : '' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <!-- <pre style="font-size:12px;">{{this.applicants}}</pre> -->
            <ListApplicants
              :opportunity="opportunity"
              @viewApp="viewApp"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>
<script>
import Vue from 'vue'

import ListApplicants from '@/components/opportunities/applicants/listApplicants'
import LoadingProgress from '@/components/core/LoadingProgress'
import { MODAL_TRANSITION_TIME } from '@/constants'
import { waitAsync } from '@/utils/common'
import { oppApplicantsEvents } from '@/segmentAnalytics/opportunities'

export default {
  components: {
    ListApplicants,
    LoadingProgress
  },
  props: ['opportunity', 'isDisplayed'],
  data: () => ({
    isFetchingData: false,
  }),
  computed: {
    applicants () {
      return this.opportunity?.applicants ? this.opportunity.applicants : []
    }
  },
  watch: {
    isDisplayed () {
      if (this.isDisplayed) {
        this.fetchApplications()
      }
    }
  },
  methods: {
    async hideModal () {
      oppApplicantsEvents.trackClose()
      this.$emit('hide')
    },
    viewApp (app) {
      oppApplicantsEvents.trackViewApplication()
      this.$emit('viewApp', app)
    },
    async fetchApplications () {
      if (!this.opportunity) return
      this.isFetchingData = true
      await waitAsync(MODAL_TRANSITION_TIME)

      if (!this.opportunity.applicants) {
        const applicants = await this.$store.dispatch('listOpportunityApplicants', this.opportunity.id)
        Vue.set(this.opportunity, 'applicants', applicants)
      }

      await this.$store.dispatch('fetchOpportunityApplications', this.opportunity.id)
      this.isFetchingData = false
    }
  },
  mounted () {
    this.fetchApplications()
  }
}
</script>
