import { trackEvent } from '@/segmentAnalytics/core'

const trackRewardOppPostPop = (action: 'post_a_job'|'close'): void => {
  trackEvent('reward_opp_post_pop', { action })
}

export const postOppCampaignEvents = {
  trackPostJobClick: () => trackRewardOppPostPop('post_a_job'),
  trackClose: () => trackRewardOppPostPop('close'),
}

export const trackDashboardMenuTap = (action: 'profile'|'opportunities'|'rewards'|'connections'): void => {
  trackEvent('dash_tap', { action })
}