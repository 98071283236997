
































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { State } from 'vuex-class'

import { canAddAppToHomeScreen } from '@/utils/common'
import { trackMenuTap, MenuTapAction } from '@/segmentAnalytics/common'
import { IColor } from '@/types/dictionaries'
import { IUser } from '@/types/models/user.model'

interface IMenuItem {
  title: string
  icon?: string
  extraIcon?: {
    name: string
    color?: IColor
  }
  to: string
  eventAction: MenuTapAction
}

@Component({
  computed: {
    ...mapGetters(['isAdmin', 'authenticated', 'profile']),
  }
})
export default class Drawer extends Vue {
  name: string = 'Drawer'

  isAdmin: boolean
  authenticated: boolean
  profile: IUser
  get isBusinessInfoVerified (): boolean {
    return !!this.profile.isBusinessInfoVerified
  }

  @State(state => state.canInstallPWA) canInstallPWA: boolean

  get drawer () {
    return this.$store.state.drawer
  }

  set drawer (val) {
    this.$store.commit('setDrawer', val)
  }

  get items (): Array<IMenuItem|null> {
    return [
      { title: 'Home', icon: 'mdi-view-dashboard', to: '/home', eventAction: 'home' },
      { title: 'Profile', icon: 'mdi-image', to: '/profile', eventAction: 'profile' },
      {
        title: 'Opportunities',
        icon: 'mdi-help-box',
        to: this.isAdmin ? '/admin/opportunities' : '/opportunities',
        eventAction: 'opportunities'
      },
      { title: 'Connections', icon: 'mdi-help-box', to: '/connections', eventAction: 'connections' },
      { title: 'Rewards', icon: 'mdi-help-box', to: '/rewards', eventAction: 'rewards' },
      {
        title: 'Business details',
        extraIcon: !this.isBusinessInfoVerified ? {
          name: 'mdi-alert-circle',
          color: 'orange',
        } : undefined,
        to: '/business-details',
        eventAction: 'businessDetails',
      },
      { title: 'Help', icon: 'mdi-help-box', to: '/faq', eventAction: 'faq' },
      this.isAdmin ? { title: 'Reports', icon: 'mdi-help-box', to: '/admin/reports', eventAction: 'reports' } : null,
      this.isAdmin ? { title: 'Settings', icon: 'mdi-help-box', to: '/admin/settings', eventAction: 'settings' } : null
    ]
  }

  get canAddToHomeScreen (): boolean {
    return canAddAppToHomeScreen() || this.canInstallPWA
  }

  private toggleDrawer () {
    this.$store.commit('toggleDrawer')
  }

  private login () {
    this.$store.dispatch('login')
  }

  private logout () {
    trackMenuTap('logout')
    this.$store.dispatch('logout')
  }

  private handleAddToHomeClick () {
    trackMenuTap('add_to_home')
    this.$store.commit('toggleAddToHomeScreenDialog')
  }

  private handleMenuItemClick (menuItem: IMenuItem) {
    if (this.$route.path === menuItem.to) return
    trackMenuTap(menuItem.eventAction)
    this.$router.push(menuItem.to)
  }
}
