







































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { IRuleFunction } from '@/types/form'
import { IAdminSettings } from '@/types/models/adminSettings.model'
import emailValidator from 'email-validator'
import { waitAsync } from '@/utils/common'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'

interface IField {
  id: string
  value: string|number|null
  label: string
  placeholder: string
  type?: string
  hint?: string
  rules?: IRuleFunction[]
}

@Component({
  computed: {
    ...mapGetters(['adminSettings', 'isAdmin'])
  },
  components: {
    LegacyPageContent,
  }
})
export default class AdminSettings extends Vue {
  @Prop() hasProfile: boolean

  $refs: {
    form: any
  }

  valid: boolean = false
  updatingFieldsInProgress: boolean = false
  fields: {[key: string]: IField} = {
    newJoinersNotificationEmail: {
      id: 'newJoinersNotificationEmail',
      value: '',
      label: 'New joiners notification email',
      placeholder: 'Enter email',
      type: 'email',
      hint: 'When new users register in the platform, notification will be sent to this email address',
      rules: [
        (value: string) => {
          if (!value) return true
          return emailValidator.validate(value) || 'Email is not valid!'
        }
      ]
    },
    newApplicantsNotificationEmail: {
      id: 'newApplicantsNotificationEmail',
      value: '',
      label: 'New applicants notification email',
      placeholder: 'Enter email',
      hint: 'When users apply to opportunity, notification will be sent to this email address',
      type: 'email',
      rules: [
        (value: string) => {
          if (!value) return true
          return emailValidator.validate(value) || 'Email is not valid!'
        }
      ]
    },
    newCVNotificationEmail: {
      id: 'newCVNotificationEmail',
      value: '',
      label: 'New CV notification email',
      placeholder: 'Enter email',
      type: 'email',
      hint: 'When users upload thier CV in the platform, notification will be sent to this email address',
      rules: [
        (value: string) => {
          if (!value) return true
          return emailValidator.validate(value) || 'Email is not valid!'
        }
      ]
    }
  }

  adminSettings: IAdminSettings
  isAdmin: boolean

  async created () {
    const settings = await this.$store.dispatch('getAdminSettings')
    if (!settings) return
    Object.values(this.fields).forEach(item => {
      Vue.set(this.fields[item.id], 'value', settings[item.id])
    })
  }

  async handleSaveClick () {
    if (!this.$refs.form.validate()) return
    this.updatingFieldsInProgress = true

    const fieldsData = Object.values(this.fields)
      .reduce<{[key: string]: any} | any>((result, item: IField) => {
        result[item.id] = item.value
        return result
      }, {})

    await this.$store.dispatch('updateAdminSettings', fieldsData)
    await waitAsync(1000)

    this.updatingFieldsInProgress = false
  }
}
