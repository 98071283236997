





















































import { mapGetters } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Notifications from '@/components/dashboard/Notifications.vue'
import Greetings from '@/components/core/Greetings.vue'
import SectionPanels from '@/components/dashboard/SectionPanels.vue'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'
// import PostOpportunityCampaignDialog from '@/components/campaigns/PostOpportunityCampaignDialog.vue'
import { IUser } from '@/types/models/user.model'
import WeeklyAvailability from '@/components/core/WeeklyAvailability.vue'

@Component({
  components: {
    Notifications,
    Greetings,
    SectionPanels,
    LegacyPageContent,
    // PostOpportunityCampaignDialog,
    WeeklyAvailability,
  },
  computed: {
    ...mapGetters([
      'allTotal',
      'connectionsTotal',
      'profilePercent',
      'profile'
    ]),
  },
})
export default class Home extends Vue {
  @Prop() hasProfile: boolean

  // data
  section = {
    rewards: ''
  }

  // computed
  // mapGetters
  allTotal: number
  connectionsTotal: number
  profilePercent: number
  profile: IUser|null

  now: string = new Date().toISOString()

  nextAvailabilityNotification () {    
    const nextNotification = new Date()
    nextNotification.setDate(nextNotification.getDate() + 1)
    this.$store.dispatch('updateProfile', {
      nextAvailabilityNotificationAt: nextNotification.toISOString()
    })
  }
  // lifecycle hooks
  created () {
    if (!this.connectionsTotal) this.$store.dispatch('listConnections')
    if (!this.allTotal) this.$store.dispatch('listOpportunities')
    // this.$store.commit('showPostOpportunityCampaignDialog')
  }
}
