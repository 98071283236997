<template>
  <div>
    <Pagination
      :loader-size="30"
      :is-fetching="isFetchingMore"
      :total="total"
      :page="page"
      @fetchMore="handleFetchMore"
    >
      <v-list two-line>
        <v-list-item-group
          v-if="items && items.length"
          multiple
        >
          <template v-for="(item, index) in items">
            <v-list-item
              v-if="item"
              :key="item.id"
              inactive
              @click="seeMore(item)"
              :class="['pa-0 px-2 pt-0', {
                'shared-to-me': item.sharedToMe
              }]"
            >
              <v-list-item-content>
                <v-list-item-title class="title pb-0 mb-0">
                  <div class="body-2 network mb-1">
                    <div class="body-2 network mb-1">
                      <!-- <span :class="labels.networkTypes[item.networkType].class + '--text'">
                        {{ labels.networkTypes[item.networkType].label }}
                      </span> -->

                      <div
                        v-if="item.owner && item.owner.id === profileID"
                        :class="labels.networkTypes[item.networkType].class + '--text'"
                      >
                        Published to {{ labels.networkTypes[item.networkType].label }}
                      </div>
                      <div
                        v-else
                        class="ultramarineBlue--text"
                      >
                        Published
                        <template v-if="item.owner">
                          by <b>{{ item.owner.name }}</b>
                        </template>
                        on {{ formatDate(item.timestamp) }}
                      </div>

                      <div
                        v-if="isOppSupportProvided(item)"
                        class="melon--text"
                      >
                        This opportunity is supported by Riverflex
                      </div>
                    </div>
                  </div>

                  <div class="mb-1 text-wrap">
                    <strong>{{ item.headline }}</strong>
                  </div>
                </v-list-item-title>

                <v-list-item-subtitle
                  v-if="display.role"
                  class="subtitle-1 role"
                  v-text="item.rolePreview"
                />

                <v-list-item-subtitle
                  v-if="display.location"
                  v-text="item.location"
                  class="location"
                />

                <v-list-item-subtitle
                  v-if="display.date"
                  class="subtitle-2 date"
                  v-text="timeSince(item.timestamp)"
                />
              </v-list-item-content>

              <v-list-item-action class="ma-0">
                <!-- <v-list-item-action-text v-if="item.applied || item.applicants" v-text="`APPLIED`" class="subtitle-2 aqua-marine"></v-list-item-action-text> -->
                <div class="counters">
                  <v-chip
                    v-if="item.applicationsCount"
                    class="counter-badge white--text"
                    color="melon"
                    x-small
                  >
                    {{ item.applicationsCount }} {{ item.applicationsCount | pluralize('applicant') }}
                  </v-chip>
                  <v-chip
                    v-if="item.shares"
                    class="counter-badge"
                    color="info"
                    x-small
                  >
                    {{ item.shares }} {{ item.shares | pluralize('share') }}
                  </v-chip>
                </div>

                <v-spacer />

                <ViewBtn
                  v-if="canDisplayApplicants(item)"
                  title="view applicants"
                  @onClick="viewApplicants(item)"
                />

                <div
                  v-else-if="isAppApplied(item.id)"
                  class="body-2 melon--text"
                >
                  APPLIED
                </div>
                <span
                  v-else-if="isAppInMeeting(item.id)"
                  class="body-2 secondary--text text-right"
                >
                  INTERVIEW<br>REQUESTED
                </span>
                <div
                  v-else-if="isAppDeclined(item.id)"
                  class="body-2 brownGrey--text"
                >
                  DECLINED
                </div>
                <div
                  v-else-if="isAppAccepted(item.id)"
                  class="body-2 ultramarineBlue--text"
                >
                  ACCEPTED
                </div>

                <v-btn
                  v-else
                  fab
                  dark
                  x-small
                  text
                  color="primary"
                >
                  <v-icon dark>
                    keyboard_arrow_right
                  </v-icon>
                </v-btn>

                <v-spacer />

                <!-- <v-btn rounded color="primary" x-small class="caption pb-1 pt-0">{{display.cta}}</v-btn> -->
              </v-list-item-action>
            </v-list-item>

            <v-divider
              v-if="index + 1 < items.length"
              :key="index"
            />
          </template>
        </v-list-item-group>
        <v-list-item-group
          v-else-if="isSearching && !isFetchingMore && !items.length"
          class="text-center mt-5"
        >
          <i class="body-1">There are no results for this search</i>
        </v-list-item-group>
      </v-list>
    </Pagination>
  </div>
</template>
<script>
import moment from 'moment'
import networkTypes from '@/assets/network-types'
import ViewBtn from '@/components/core/ViewBtn'
import Pagination from '@/components/core/Pagination'

import { mapGetters } from 'vuex'
import { trackViewOppTap } from '@/segmentAnalytics/opportunities'

export default {
  props: [
    'items',
    'display',
    'isFetchingMore',
    'total',
    'page',
    'isSearching'
  ],
  components: {
    ViewBtn,
    Pagination
  },
  data () {
    return {
      labels: {
        networkTypes: networkTypes
      },
      disabledView: false,
      currentDate: new Date().toISOString().substr(0, 10)
      //newTimeFrameDate: moment(this.currentDate).add(10, 'days')
    }
  },
  computed: {
    ...mapGetters([
      'profileID',
      'isAppApplied',
      'isAppDeclined',
      'isAppInMeeting',
      'isAppAccepted',
      'isOppSupportProvided'
    ])
  },
  methods: {
    seeMore (opp) {
      if (!this.disabledView) {
        trackViewOppTap()
        this.$emit('viewOppDetails', opp)
      }
      this.disabledView = false
    },
    viewApplicants (opp) {
      this.disabledView = true
      this.$emit('showApplicants', opp)
    },
    timeSince (timestamp) {
      return moment(timestamp).fromNow()
    },
    formatDate (timestamp) {
      const momentDate = moment(timestamp)
      return momentDate.format(`Do MMMM ${momentDate.year() !== moment().year() ? 'YYYY' : ''}`)
    },
    handleFetchMore ($event) {
      this.$emit('fetchMore', $event)
    },
    canDisplayApplicants (item) {
      if (!item) return false
      return !!this.display?.applicants && !!item.applicationsCount
    }
  }
}
</script>
<style lang="scss" scoped>
  .title {
    line-height: 1.1em !important;
    white-space: normal;
  }
  .role, .location, .date, .network {
    color: grey;
    letter-spacing: 1px;
  }
  .counters {
    margin: 13px 0 0 5px;
  }
  .counter-badge {
    width: auto;
    margin-left: 5px;
  }
  .shared-to-me {
    background: var(--v-battleshipGrey-lighten5);
  }
</style>
