






















import { mapState } from 'vuex'
import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class ErrorToast extends Vue {
  @State(state => state.status.error) error: any

  get displayError (): boolean {
    return this.$store.state.status.displayError
  }

  set displayError (value) {
    this.toggleError(value)
  }

  private toggleError (value: boolean) {
    this.$store.dispatch('displayError', value)
  }
}
