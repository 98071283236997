























































import { mapGetters } from 'vuex'
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'

// import Summary from '@/components/rewards/Summary.vue'
import MoreInfo from '@/components/rewards/MoreInfo.vue'
import InfoDialog from '@/components/core/InfoDialog.vue'
import InfoIcon from '@/components/core/InfoIcon.vue'
import ShowInfoDialogMixin, { IShowDialogMixin } from '@/mixins/ShowInfoDialog.mixin'
import { trackHelpBtnTap } from '@/segmentAnalytics/common'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'

@Component({
  components: {
    // Summary,
    MoreInfo,
    InfoDialog,
    InfoIcon,
    LegacyPageContent
  },
})
export default class Rewards extends Mixins(ShowInfoDialogMixin) implements IShowDialogMixin {
  @Prop() hasProfile: boolean

  // data
  pageName: string = 'rewards'
  tabs: null|string = null
  showInfoDialog: boolean = false

  // lifecycle hooks
  mounted () {
    this.tabs = this.$route.hash.replace('#', '')
  }

  handleInfoIconClick () {
    trackHelpBtnTap('rewards')
    this.showInfoDialog = true
  }
}
