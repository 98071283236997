


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LoginBtnWithRules from '@/components/core/LoginBtnWithRules.vue'
import { trackNextSlideTap, trackPrevSlideTap } from '@/segmentAnalytics/registration'

@Component({
  components: {
    LoginBtnWithRules
  }
})
export default class LoginSlider extends Vue {
  logoUrl: string = require('@/assets/images/logo.svg')
  linkedInLogo: string = require('@/assets/images/ui/bottom-nav/linked-in-logo.svg')

  items: any = [
    {
      src: require('@/assets/images/login/network.svg'),
      title: 'Sign up for exclusive job opportunities at world-leading organizations',
      body: 'Our members have access to work opportunities from Riverflex clients and partners - you will not find these roles elsewhere.'
    },
    {
      src: require('@/assets/images/login/briefcase-green.svg'),
      title: 'Get access to our vast professional network',
      body: 'Riverflex Platform hosts 1600+ digital consultants with top-tier industry experience - it’s a great way to grow your professional network & connect with peers.'
    },
    {
      src: require('@/assets/images/login/noun-trophy-863380.svg'),
      title: 'Earn beyond your day-rate',
      body: 'Take advantage of the Riverflex Platform rewards program and earn a financial bonus if you refer a new contact or send a project opportunity our way.'
    },
  ]

  carouselModel: number = 0

  @Watch('carouselModel')
  onCarouselSliderChange (val: number, oldVal: number) {
    val > oldVal ? trackNextSlideTap(val) : trackPrevSlideTap(val)
  }

  get isLargeDesktop (): boolean {
    return this.$mediaQuery.isLargeDesktop
  }

  // get bgUrl (): string {
  // return this.isLargeDesktop ?
  //   require('@/assets/images/login/login-waves-bg-desktop.svg') :
  //   require('@/assets/images/login/login-waves-bg-mobile.svg')
  // }

  get logoWidth (): number {
    return this.isLargeDesktop ? 248 : 180
  }

  get linkedInLogoWidth (): number {
    return this.isLargeDesktop ? 46 : 30
  }

  get carouselImgWidth (): number {
    return this.isLargeDesktop ? 74 : 55
  }

  get carouselHeight (): number {
    return this.isLargeDesktop ? 330 : 315
  }

  private login () {
    this.$emit('login')
  }
}
