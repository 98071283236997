import API from './api.service'
import gql from 'graphql-tag'
import { IAchievement } from '@/types/models/achievement.model'
import { fragment as userFragment } from '@/services/user.service'
import { IUser } from '@/types/models/user.model'

export const fragment = gql`
  fragment AchievementData on Achievement {
    id
    name
    title
    description
  }
`

export class AchievementAPI extends API {

  // queries
  async getAchievements (): Promise<IAchievement[]> {
    const query = await this.query<{ getAchievements: IAchievement[] }>(gql`
      ${fragment}
      query getAchievements {
        getAchievements {
          ...AchievementData
        }
      }`)
    return query.getAchievements
  }


  // mutations
  async addUserAchievement (userId: number, achievementId: number): Promise<IAchievement> {
    const response = await this.mutation<{ addUserAchievement: IAchievement }>(gql`
      ${fragment}
      mutation addUserAchievement {
        addUserAchievement(userId: ${userId}, achievementId: ${achievementId}) {
          ...AchievementData
        }
      }
    `)
    return response.addUserAchievement
  }

  async removeUserAchievement (userId: number, achievementId: number): Promise<IAchievement> {
    const response = await this.mutation<{ removeUserAchievement: IAchievement }>(gql`
      ${fragment}
      mutation removeUserAchievement {
        removeUserAchievement(userId: ${userId}, achievementId: ${achievementId}) {
          ...AchievementData
        }
      }
    `)
    return response.removeUserAchievement
  }

  async updateUserAchievements (userId: number, achievementIds: number[]): Promise<IUser> {
    const response = await this.mutation<{ updateUserAchievements: IUser }>(gql`
      ${userFragment}
      mutation updateUserAchievements {
        updateUserAchievements(userId: ${userId}, achievementIds: ${JSON.stringify(achievementIds)}) {
          ...UserData
        }
      }
    `)
    return response.updateUserAchievements
  }

}

export default new AchievementAPI()
