<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        id="availableFrom"
        v-model="date"
        append-icon="event"
        readonly
        v-on="on"
      />
    </template>

    <v-date-picker
      v-model="date"
      scrollable
      first-day-of-week="1"
    >
      <div class="flex-grow-1" />
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    modal: false,
    date: new Date().toISOString().substr(0, 10)
  })
}
</script>
