import { trackEvent } from '@/segmentAnalytics/core'

const trackPostJobRewardActions = (action: 'post_a_job'|'close'): void => {
  trackEvent('reward_opp_post_pop', { action })
}

export const postJobRewardEvents = {
  trackClose: () => trackPostJobRewardActions('close'),
  trackPostJob: () => trackPostJobRewardActions('post_a_job'),
}

const trackReferContactRewardActions = (action: 'refer_a_contact'|'close'): void => {
  trackEvent('reward_connect_pop', { action })
}

export const referContactRewardEvents = {
  trackClose: () => trackReferContactRewardActions('close'),
  trackReferContact: () => trackReferContactRewardActions('refer_a_contact'),
}

export const trackShareOppRewardActions = (action: 'post_a_job'|'email_for_support'|'close'): void => {
  trackEvent('reward_opp_share_pop', { action })
}

export const shareOppRewardEvents = {
  trackClose: () => trackShareOppRewardActions('close'),
  trackPostJob: () => trackShareOppRewardActions('post_a_job'),
  trackEmailForSupport: () => trackShareOppRewardActions('email_for_support'),
}
