








import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ProfilePic extends Vue {
  @Prop() src: string
  @Prop() width: number

  failedImage: boolean = false
  blankImage: any = require('@/assets/images/profile-blank.png')
  profileImage: string = this.blankImage

  @Watch('src')
  onImageSrcChanged (val: string, oldVal: string) {
    if (!val) {
      this.profileImage = this.blankImage
      return
    }
    this.profileImage = val
  }

  created () {
    if (this.src) this.profileImage = this.src
  }

  onImgError () {
    this.profileImage = this.blankImage
  }

}
//https://github.com/jofftiquez/v-img-fallback <- another soluton for fallback
