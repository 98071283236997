















































































import { mapGetters } from 'vuex'
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'

import ReportAPI from '@/services/report.service'
import { ReportType } from '@/types/models/report.model'
import LoadingProgress from '@/components/core/LoadingProgress.vue'
import Dates from '@/components/opportunities/create/Dates.vue'
import ConfirmDialog from '@/components/core/ConfirmDialog.vue'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'
import { waitAsync } from '@/utils/common'

interface IReportType {
  label: string
  value: ReportType
}

@Component({
  components: {
    LoadingProgress,
    Dates,
    ConfirmDialog,
    LegacyPageContent
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
})
export default class Rewards extends Vue {
  @Prop() hasProfile: boolean

  selectedReports: ReportType[] = []
  reportsInProgress: boolean = false
  showReportsBuildingDialog: boolean = false

  reportTypes: IReportType[] = [
    {
      label: 'Users report',
      value: ReportType.users
    },
    {
      label: 'Opportunities report',
      value: ReportType.opps
    },
    {
      label: 'Surveys report',
      value: ReportType.surveys
    },
    {
      label: 'Email newsletter subscription report',
      value: ReportType.users_email_subscription
    },
  ]

  dates = {
    start: {
      id: 'startDate',
      value: '',
    },
    end: {
      id: 'endDate',
      value: '',
    }
  }

  isAdmin: boolean
  get createReportsBtnDisabled () {
    return !this.selectedReports.length
  }

  async handleCreateReportsClick () {
    this.reportsInProgress = true
    if (!this.selectedReports.length) return

    const data = await ReportAPI.generateReports({
      reportNames: this.selectedReports,
      startDate: this.dates.start.value,
      endDate: this.dates.end.value
    }).catch(error => {
      this.$store.dispatch('setStatusError', error)
      return error
    })
    await waitAsync(1500)

    this.reportsInProgress = false
    if (data instanceof Error) return
    this.showReportsBuildingDialog = true
  }

  handleReportsBuildingDialogClose () {
    this.showReportsBuildingDialog = false
    this.reportsInProgress = false
    this.selectedReports = []
    Vue.set(this.dates.start, 'value', '')
    Vue.set(this.dates.end, 'value', '')
  }
}
