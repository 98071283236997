














































import { mapGetters } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IUser } from '@/types/models/user.model'
import { trackMyconnectionsTap } from '@/segmentAnalytics/profile'

@Component({
  computed: {
    ...mapGetters(['profile'])
  }
})
export default class MyConnections extends Vue {
  @Prop() user: IUser
  @Prop() linkToConnections: boolean

  profile: IUser

  get title (): string {
    return this.user ? 'Connections' : 'My connections'
  }

  get connectionsNumber (): number {
    if (this.user) return this.user?.connectionsNumber || 0
    return this.profile?.connectionsNumber || 0
  }

  handleConnectionsLinkClick () {
    trackMyconnectionsTap()
  }
}
