



























import { mapState } from 'vuex'
import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { trackSignInTap } from '@/segmentAnalytics/registration'

@Component
export default class LoginBtnWithRules extends Vue {
  linkedInLogo: string = require('@/assets/images/ui/bottom-nav/linked-in-logo.svg')

  get linkedInLogoWidth (): number {
    return this.$mediaQuery.isLargeDesktop ? 46 : 30
  }

  private login () {
    trackSignInTap()
    this.$emit('login')
  }
}
