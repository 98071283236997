import { Module } from 'vuex'

import AdminSettingsAPI from '@/services/adminSettings.service'
import RootState from '@/types/rootState'
import { IAdminSettings } from '@/types/models/adminSettings.model'

interface IState {
  settings: IAdminSettings
}

const state: IState = {
  settings: {
    newJoinersNotificationEmail: '',
    newApplicantsNotificationEmail: '',
    newCVNotificationEmail: ''
  }
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    adminSettings (state): IAdminSettings {
      return state.settings
    }
  },
  mutations: {
    setAdminSettings (state, payload: IAdminSettings): void {
      state.settings = payload
    }
  },
  actions: {
    async getAdminSettings (context): Promise<IAdminSettings|void> {
      if (!context.getters.isAdmin) return

      const settings = await AdminSettingsAPI.getAdminSettings()
        .catch(error => context.dispatch('setStatusError', error))

      if (!settings) return

      context.commit('setAdminSettings', settings)
      return settings
    },
    async updateAdminSettings (context, settings: IAdminSettings) {
      if (!context.getters.isAdmin) return

      const newSettings = await AdminSettingsAPI.updateAdminSettings(settings)
        .catch(error => context.dispatch('setStatusError', error))

      if (!newSettings) return

      context.commit('setAdminSettings', newSettings)
    }
  }
}

export default store