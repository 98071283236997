import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import { set, toggle } from './utils/vuex'

import applications from '@/store/applications'
import connections from '@/store/connections'
import opportunities from '@/store/opportunities'
import adminOpportunities from '@/store/adminOpportunities'
import notifications from '@/store/notifications'
import profile from '@/store/profile'
import achievements from '@/store/achievements'
import adminSettings from '@/store/adminSettings'
import pushNotifications from '@/store/pushNotifications'
import campaigns from '@/store/campaigns'
import businessInfo from '@/store/businessInfo'
import RootState from '@/types/rootState'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  modules: {
    applications,
    connections,
    opportunities,
    adminOpportunities,
    notifications,
    profile,
    achievements,
    adminSettings,
    pushNotifications,
    campaigns,
    businessInfo,
  },
  plugins: [
    // store => store.dispatch('_initStoreHook'),
    store => {
      const unsubscribe = store.subscribe((mutation, state: RootState) => {
        if (mutation.type === 'profile' && mutation.payload && mutation.payload.id && mutation.payload.sub) {
          // called once after user logs in.
          // Use it for any subscripitons which require user data
          store.dispatch('_afterUserAuthHook')
          unsubscribe()
        }
      })
    }
  ],
  state: {
    status: {
      error: null,
      displayError: false,
    },
    drawer: null,
    showAddToHomeScreenDialog: false,
    showNewVersionAvailable: false,
    canInstallPWA: false
  },
  getters: {},
  mutations: {
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    toggleAddToHomeScreenDialog: toggle('showAddToHomeScreenDialog'),
    toggleNewVersionAvailable: toggle('showNewVersionAvailable'),
    setCanInstallPWA: (state, canInstallPWA: boolean) => state.canInstallPWA = canInstallPWA,
    setStatusError: (state, error = {}) => {
      if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
        state.status.error = 'Something went wrong! Please contact our support!'
        return
      }
      state.status.error = error && error.message ? error.message : error
    },
    displayError: (state, display) => {
      state.status.displayError = display
    }
  },
  actions: {
    setStatusError (context, error) {
      console.warn(error)
      Vue.$logger.logError(error)
      context.commit('setStatusError', error)
      context.commit('displayError', true)
    },
    displayError (context, display) {
      context.commit('displayError', !!display)
    },
    async showNewVersionAvailable (context, display) {
      if (!context.getters.authenticated) return
      context.commit('toggleNewVersionAvailable')
    },
  }
})
