




















import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class NewVersionAvailable extends Vue {

  @State(state => state.showNewVersionAvailable) show: boolean
  isRefreshing: boolean = false

  handleRefreshClick () {
    this.isRefreshing = true
    window.location.reload(true)
  }
}
