import { trackEvent } from '@/segmentAnalytics/core'
import { ScreeningQuestionType } from '@/types/models/screeningQuestion.model'
import { NETWORK_TYPES } from '@/assets/network-types'

export const trackOppScreenTab = (tab: string): void => {
  trackEvent('opp_screen_tab', { tab })
}

export type ISearchField = 'title'|'industry'|'location'
export const trackSearchFieldFill = (search_field: ISearchField): void => {
  trackEvent('select_opp_fill', { search_field })
}

export const trackOppScroll = (tab: string): void => {
  trackEvent('opp_scroll', { tab })
}

export const trackPostJobTap = (): void => {
  trackEvent('post_job_tap')
}

export const trackViewOppTap = (): void => {
  trackEvent('opp_case_tap')
}

type OppEditScreenAction = 'next'|'close'|'save'
const trackOppEditActions = (action: OppEditScreenAction): void => {
  trackEvent('opp_post_screen', { action })
}

export const oppEditEvents = {
  trackNextTap: () => trackOppEditActions('next'),
  trackClose: () => trackOppEditActions('close'),
  trackSave: () => trackOppEditActions('save')
}

const createOppFieldEventName = (fieldName: string): string => `opp_${fieldName.toLowerCase()}_fill`

export const trackOppFieldEdit = (fieldName: string): void => {
  trackEvent(createOppFieldEventName(fieldName), { action: 'edit' })
}

export const trackOppFieldFinishEdit = (fieldName: string): void => {
  trackEvent(createOppFieldEventName(fieldName), { action: 'close_editing' })
}

export const trackOppSelectFieldChange = (fieldName: string, value: string|number): void => {
  trackEvent(createOppFieldEventName(fieldName), { value })
}

export const trackScreeningQuestionAdd = (select: ScreeningQuestionType): void => {
  trackEvent('opp_screen_q_fill', { select })
}

export const trackOppAllowShareTap = (is_allowed: boolean): void => {
  trackEvent('opp_allow_share_opt_tap', { is_allowed })
}

const getPublishOptionName = (networkType: number): string => {
  let networkName = ''
  switch (networkType) {
  case NETWORK_TYPES.ALL:
    networkName = 'publish_to_all_member'
    break
  case NETWORK_TYPES.PERSONAL_NETWORK:
    networkName = 'publish_to_my_network'
    break
  case NETWORK_TYPES.SPECIFIC_CONNECTIONS:
    networkName = 'publish_to_specific_connection'
    break
  }
  return networkName
}

interface PublishOptionsProps {
  action: 'close'|'publish'|'terms_and_cond_tap'|'ask_for_support'
  publish_option?: string
}
const trackPublishOptionsActions = (props: PublishOptionsProps) => {
  trackEvent('opp_publish_pop', props)
}

export const oppPublishOptionsEvents = {
  trackClose: () => trackPublishOptionsActions({ action: 'close' }),
  trackTermsAndCondTap: () => trackPublishOptionsActions({ action: 'terms_and_cond_tap' }),
  trackPublish: (networkType: number) => {
    trackPublishOptionsActions({ action: 'publish', publish_option: getPublishOptionName(networkType) })
    trackEvent('opp_publish_click')
  },
  trackPublishOptionSelect: (networkType: number) => {
    const name = getPublishOptionName(networkType)
    
    if (!name) return
    trackEvent('opp_select_publish_opt_tap', { select: name })
  },
  trackAskForSupport: () => trackPublishOptionsActions({ action: 'ask_for_support' }),
}

const trackAskForSupportActions = (action: 'close'|'submit') => {
  trackEvent('opp_ask_for_support_pop', { action })
}

export const askForSupportEvents = {
  trackClose: () => trackAskForSupportActions('close'),
  trackSubmit: () => trackAskForSupportActions('submit')
}

interface OppPublishTapProps {
  action: 'publish'|'close'
  publish_option?: 'all'|'my_network'|'specific_connection'
}
export const trackOppPublishTap = (props: OppPublishTapProps): void => {
  trackEvent('opp_publish_tap', props)
}

type OppScreenAction = 'apply_tap'|'share_tap'|'unpublish_tap'|'close_tap'|'edit_tap'|'view_applicants_tap'|'view_applicantion_tap'
const trackOppScreenActions = (action: OppScreenAction): void => {
  trackEvent('opp_case_screen', { action })
}

export const oppScreenEvents = {
  trackClose: () => trackOppScreenActions('close_tap'),
  trackApplyTap: () => trackOppScreenActions('apply_tap'),
  trackShareTap: () => trackOppScreenActions('share_tap'),
  trackUnpublishTap: () => trackOppScreenActions('unpublish_tap'),
  trackEditTap: () => trackOppScreenActions('edit_tap'),
  trackViewApplicantsTap: () => trackOppScreenActions('view_applicants_tap'),
  trackViewApplicantionTap: () => trackOppScreenActions('view_applicantion_tap'),
}

type ApplyOppScreenAction = 'edit_personal_message'|'upload_resume'|'apply'|'close'|'cancel'
const trackApplyOppActions = (action: ApplyOppScreenAction): void => {
  trackEvent('opp_apply_screen', { action })
}

export const applyScreenEvents = {
  trackApply: () => {
    trackApplyOppActions('apply')
    trackEvent('opp_apply_click')
  },
  trackClose: () => trackApplyOppActions('close'),
  trackCancel: () => trackApplyOppActions('cancel'),
}

enum ShareOptionType {
  existing_contact = 'existing_contact',
  email = 'email'
}

const trackOppShareActions = (action: 'share'|'close', selected_connection?: ShareOptionType): void => {
  if (!selected_connection) return
  trackEvent('opp_share_screen', { action, selected_connection })
}
const getShareOptionName = (shareOption: number) => {
  if (shareOption === 1) return ShareOptionType.existing_contact
  else if (shareOption === 2) return ShareOptionType.email
}
export const shareScreenEvents = {
  trackClose: (shareOption: number) => {
    trackOppShareActions('close', getShareOptionName(shareOption))
  },
  trackShare: (shareOption: number) => {
    trackOppShareActions('share', getShareOptionName(shareOption))
    trackEvent('opp_share_click')
  }
}

const trackUnpublishScreenActions = (action: 'confirm'|'close'): void => {
  trackEvent('confirm_unpublish_pop', { action })
}

export const unpublishScreenEvents = {
  trackClose: () => trackUnpublishScreenActions('close'),
  trackConfirm: () => trackUnpublishScreenActions('confirm')
}

type ShareOppCampaignAction = 'post_a_job'|'close'|'email_for_support'
const trackShareOppCampaignActions = (action: ShareOppCampaignAction): void => {
  trackEvent('reward_opp_share_pop', { action })
}

export const shareOppCampaignEvents = {
  trackClose: () => trackShareOppCampaignActions('close'),
  trackPostClick: () => trackShareOppCampaignActions('post_a_job'),
  trackEmailClick: () => trackShareOppCampaignActions('email_for_support')
}

const trackOppApplicantsActions = (action: 'view_application'|'close'): void => {
  trackEvent('opp_applicants_pop', { action })
}

export const oppApplicantsEvents = {
  trackClose: () => trackOppApplicantsActions('close'),
  trackViewApplication: () => trackOppApplicantsActions('view_application'),
}

type ApplicationAction = 'view_profile'|'close'|'schedule_meeting'|'confirm'|'download_cv'|'decline'
const trackApplicationActions = (action: ApplicationAction): void => {
  trackEvent('opp_application_pop', { action })
}

export const oppApplicantionEvents = {
  trackClose: () => trackApplicationActions('close'),
  trackViewProfile: () => trackApplicationActions('view_profile'),
  trackScheduleMeeting: () => trackApplicationActions('schedule_meeting'),
  trackConfirm: () => {
    trackApplicationActions('confirm')
    trackEvent('opp_application_confirm_click')
  },
  trackDownloadCv: () => trackApplicationActions('download_cv'),
  trackDecline: () => {
    trackApplicationActions('decline')
    trackEvent('opp_application_decline_click')
  },
}

const trackOppApplicantionStatusAction = (action: 'confirm'|'close', status: string): void => {
  trackEvent('opp_application_status_confirm_pop', { action, application_status: status })
}

export const oppApplicantionConfirmEvents = {
  trackClose: (status: string) => trackOppApplicantionStatusAction('close', status),
  trackConfirm: (status: string) => trackOppApplicantionStatusAction('confirm', status),
}