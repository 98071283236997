<template>
  <v-bottom-navigation
    app
    fixed
    grow
    background-color="grey lighten-3"
    height="58"
    v-model="bottomNav"
    v-show="hasProfile"
  >
    <v-btn
      v-for="(link, i) in links"
      :key="i"
      :value="link.name"
      :to="link.to"
      :disabled="link.disabled"
      height="100%"
      min-width="70"
      v-trackEvent:click="{
        event: 'bottom_tap', props: { button: link.name.toLowerCase() }
      }"
    >
      <v-img
        v-if="link.src"
        :src="link.src"
        :max-height="link.height"
        contain
      />
      <v-icon
        v-else-if="link.icon"
        color="primary"
        :size="link.size"
      >
        {{ link.icon }}
      </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['hasProfile'],
  data: () => ({
    bottomNav: 'Home',
  }),
  computed: {
    ...mapGetters(['isAdmin']),
    links () {
      return [
        {
          to: '/home',
          src: require('@/assets/images/ui/bottom-nav/noun-home-2749445.svg'),
          srcset:
            './assets/images/ui/bottom-nav/home-icon.png 1x, ./assets/images/ui/bottom-nav/home-icon@2x.png 2x, ./assets/images/ui/bottom-nav/home-icon@3x.png 3x',
          name: 'Home',
          height: 30,
          disabled: false,
        },
        {
          to: this.isAdmin ? '/admin/opportunities' : '/opportunities',
          src: require('@/assets/images/ui/bottom-nav/noun-job-2043873.svg'),
          srcset:
            './assets/images/ui/bottom-nav/opportunities-icon.png 1x, ./assets/images/ui/bottom-nav/opportunities-icon@2x.png 2x, ./assets/images/ui/bottom-nav/opportunities-icon@3x.png 3x',
          name: 'Opportunities',
          height: 28,
          disabled: false
        },
        {
          to: '/connections',
          src: require('@/assets/images/ui/bottom-nav/network.svg'),
          srcset:
            './assets/images/ui/bottom-nav/contacts-icon.png 1x, ./assets/images/ui/bottom-nav/contacts-icon@2x.png 2x, ./assets/images/ui/bottom-nav/contacts-icon@3x.png 3x',
          name: 'Connections',
          height: 33,
          disabled: false
        },
        {
          to: '/rewards',
          src: require('@/assets/images/ui/bottom-nav/noun-trophy-863380.svg'),
          srcset:
            './assets/images/ui/bottom-nav/rewards-icon.png 1x, ./assets/images/ui/bottom-nav/rewards-icon@2x.png 2x, ./assets/images/ui/bottom-nav/rewards-icon@3x.png 3x',
          name: 'Rewards',
          height: 33,
          disabled: false
        },
        {
          to: '/faq',
          src: require('@/assets/images/ui/bottom-nav/faq-icon.svg'),
          name: 'FAQ',
          height: 33,
          disabled: false
        }
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
.v-btn--disabled {
  opacity: 0.2 !important;
}
</style>
