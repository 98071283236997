import API from './api.service'
import gql from 'graphql-tag'
import { IRecruiteeOffer } from '@/types/models/recruitee.model'

export class RecruiteeAPI extends API {

  // queries
  async getJobOffers (): Promise<IRecruiteeOffer[]> {
    const query = await this.query<{ getJobOffers: IRecruiteeOffer[] }>(gql`
      query getJobOffers {
        getJobOffers {
          id, title, kind, slug, status
        }
      }`)
    return query.getJobOffers
  }

}

export default new RecruiteeAPI()
