













































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { mapGetters } from 'vuex'

import { isIos, isInstallPWAEventExists, canAddAppToHomeScreen, waitAsync } from '@/utils/common'
import { MODAL_TRANSITION_TIME } from '@/constants'
import { addToHomeEvents } from '@/segmentAnalytics/common'

@Component({
  computed: {
    ...mapGetters(['authenticated'])
  }
})
export default class AddToHomeDialog extends Vue {
  // data
  iosShareIcon: string = require('@/assets/images/ui/ios-share-icon.svg')
  dontShowCheckboxName: string = 'dont_show_add_to_home_dialog_anymore'
  displayDontShowCheckbox: boolean = false

  @State(state => state.showAddToHomeScreenDialog) show: boolean
  @State(state => state.canInstallPWA) canInstallPWA: boolean

  // computed
  authenticated: boolean
  get canDisplayAddToHomeDialogOnAppLoad (): boolean {
    return !window.localStorage.getItem(this.dontShowCheckboxName)
  }

  get displayAddToHomeScreenInstruction (): boolean {
    return isIos() && !this.canInstallPWA
  }

  @Watch('canInstallPWA')
  onCanInstallPWAChanged (val: number, oldVal: number) {
    if (!val) return
    this.displayAddToHomeScreenDialog()
  }

  // lifecycle hooks
  created () {
    this.displayAddToHomeScreenDialog()
  }

  // methods

  displayAddToHomeScreenDialog () {
    if (!this.authenticated) return
    if (this.$mediaQuery.tabletsAndDown && canAddAppToHomeScreen() && this.canDisplayAddToHomeDialogOnAppLoad) {
      this.displayDontShowCheckbox = true
      this.$store.commit('toggleAddToHomeScreenDialog')
    }
  }

  async hideModal () {
    this.$store.commit('toggleAddToHomeScreenDialog')
    await waitAsync(MODAL_TRANSITION_TIME)
    this.displayDontShowCheckbox = false
  }

  async handleInstallAppClick () {
    if (!isInstallPWAEventExists()) return

    addToHomeEvents.trackAdd()
    window.$installAppDeferredPrompt.prompt()
    const choiceResult = await window.$installAppDeferredPrompt.userChoice
    console.log(choiceResult.outcome === 'accepted' ? 'User accepted app' : 'User declined app')
    this.hideModal()
  }

  handleDontShowCheckboxChange (dontShow: boolean) {
    addToHomeEvents.trackDoNotShow()
    if (dontShow) {
      this.hideModal()
      window.localStorage.setItem(this.dontShowCheckboxName, 'true')
    }
  }

  handleModalClose () {
    addToHomeEvents.trackClose()
    this.hideModal()
  }
}
