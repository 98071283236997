import { DirectiveOptions } from 'vue'
import sanitizeHtml from 'sanitize-html'

function insertHTML (el: HTMLElement, value?: string): void {
  el.innerHTML = sanitizeHtml(value || '')
}

const directive: DirectiveOptions = {
  inserted (el, binding, vnode) {
    insertHTML(el, binding.value)
  },
  componentUpdated (el, binding, vnode) {
    insertHTML(el, binding.value)
  },
}

export default directive
