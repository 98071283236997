export default {
  primary: '#1f1750',
  secondary: '#2ce4b5',
  error: '#b71c1c',
  white: '#ffffff',
  whiteTwo: '#f5f5f5',
  grey: '#9e9e9e',
  brownGrey: '#979797',
  battleshipGrey: '#6a7181',
  lightGrey: '#FAFAFA',
  melon: '#ff8b5c',
  ultramarineBlue: '#2e00df',
  appRed: '#F44336',
  lightSlateBlue: '#8c9eff',
  orange: '#ff9800',
  darkBlue: '#2E00DF',
}
