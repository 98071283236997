import API from './api.service'
import gql from 'graphql-tag'

import { fragment as applicationFragment } from './application.service'
import { IPagination, IApiMeta, INextCallback, IOppsRequestOptions } from '@/types/api'
import { IOpportunity } from '@/types/models/opportunity.model'
import { IApplication } from '@/types/models/application.model'
import { createSeachArgs } from '@/utils/api'

const OPP_COMMON_FIELDS = `
  id
  headline
  description
  role
  rolePreview
  industry
  location
  duration
  timeframe
  timestamp
  updatedAt
  currency
  rate
  startDate
  endDate
  networkType
`

const publicOppFragment = gql`
  fragment PublicOpportunityData on PublicOpportunity {
    ${OPP_COMMON_FIELDS}
  }
`

const fragment = gql`
  fragment OpportunityData on Opportunity {
    ${OPP_COMMON_FIELDS}
    selectedConns
    owner {
      id
      name
    }
    status
    shareable
    shares
    sharedToMe
    applicationsCount
  }
`

const screeningQuestionsFragment = `
screeningQuestions {
  id
  body
  order
  type
  extensions
  answerOptions {
    body
    order
  }
}
`

export class OpportunityAPI extends API {

  async getOpportunities (options: IOppsRequestOptions = {}): Promise<{ opportunity: IOpportunity[], meta: IApiMeta }> {
    let args = ''
    if (options.owner) args += `owner: "${options.owner || ''}",`
    args = createSeachArgs(options, args)

    return this.query<{ opportunity: IOpportunity[], meta: IApiMeta }>(gql`
    ${fragment}
    query opportunity {
      opportunity ${args} {
        ...OpportunityData
      }
    }`, { fetchPolicy: 'no-cache' })
  }

  async getPublicOpportunities (options: IOppsRequestOptions = {}): Promise<{ opportunity: IOpportunity[], meta: IApiMeta }> {
    const args = createSeachArgs(options, '')

    const data = await this.query<{ publicOpportunities: IOpportunity[], meta: IApiMeta }>(gql`
    ${publicOppFragment}
    query publicOpportunities {
      publicOpportunities ${args} {
        ...PublicOpportunityData
      }
    }`, { fetchPolicy: 'no-cache' })
    return { opportunity: data.publicOpportunities, meta: data.meta }
  }

  async getOpportunityById (id: number): Promise<IOpportunity> {
    const query = await this.query<{ opportunityById: IOpportunity }>(gql`
    ${fragment}
    query opportunityById {
      opportunityById (id: ${id}) {
        ...OpportunityData, offerId, ${screeningQuestionsFragment}
      }
    }`, { fetchPolicy: 'no-cache' })
    return query.opportunityById
  }

  async getPublicOpportunityById (id: number): Promise<IOpportunity> {
    const query = await this.query<{ publicOpportunityById: IOpportunity }>(gql`
    ${publicOppFragment}
    query publicOpportunityById {
      publicOpportunityById (id: ${id}) {
        ...PublicOpportunityData
      }
    }`)
    return query.publicOpportunityById
  }

  async getOpportunityByOfferId (offerId: number): Promise<IOpportunity> {
    const query = await this.query<{ opportunityByOfferId: IOpportunity }>(gql`
    ${publicOppFragment}
    query opportunityByOfferId {
      opportunityByOfferId (offerId: ${offerId}) {
        ...PublicOpportunityData
      }
    }`, { fetchPolicy: 'no-cache' })
    return query.opportunityByOfferId
  }

  async getUsersWhomSharedOpp (oppId: number): Promise<number[]> {
    const query = await this.query<{ sharedWithUsers: number[] }>(gql`
      query sharedWithUsers { sharedWithUsers(id: ${oppId}) }`, { fetchPolicy: 'no-cache' }
    )
    return query.sharedWithUsers
  }

  async getUserAppliedOpps (options: IOppsRequestOptions = {}): Promise<{ userApplied: IOpportunity[], meta: IApiMeta }> {
    const args = createSeachArgs(options)
    return this.query<{ userApplied: IOpportunity[], meta: IApiMeta }>(gql`
      ${fragment}
      query userApplied {
        userApplied ${args} { ...OpportunityData }
      }`, { fetchPolicy: 'no-cache' }
    )
  }

  // mutations

  // @Outdated
  async upsert ({ id, input }: { id?: number, input: any }): Promise<IOpportunity> {
    const response = await this.mutation<{ opportunity: IOpportunity }>(gql`
      ${fragment}
      mutation opportunity($input: OpportunityInput!) {
        opportunity(${id ? `id: ${id}` : ''}, input: $input) {
          ...OpportunityData
        }
      }
    `, { input })
    return response.opportunity
  }

  async createOpp (input: any): Promise<IOpportunity> {
    const response = await this.mutation<{ opportunity: IOpportunity }>(gql`
      ${fragment}
      mutation createOpportunity($input: OpportunityInput!) {
        createOpportunity(input: $input) {
          ...OpportunityData
        }
      }
    `, { input })
    return response.opportunity
  }


  async updateOpp (id: number, input: any): Promise<IOpportunity> {
    const response = await this.mutation<{ opportunity: IOpportunity }>(gql`
      ${fragment}
      mutation updateOpportunity($input: OpportunityInput!) {
        updateOpportunity(id: ${id}, input: $input) {
          ...OpportunityData
        }
      }
    `, { input })
    return response.opportunity
  }

  async applyOpportunity ({ id, input = {} }: { id: number, input: any }):Promise<IApplication> {
    const response = await this.mutation<{ applyOpportunity: IApplication }>(gql`
      ${applicationFragment}
      mutation applyOpportunity {
        applyOpportunity(id: ${id}, input: ${this.input(input)}) { ...ApplicationData }
      }
    `)
    return response.applyOpportunity
  }

  async shareOppWithUsers ({ id, to }: { id: number, to: string[] }): Promise<IOpportunity> {
    const response = await this.mutation<{ share: IOpportunity }>(gql`
      ${fragment}
      mutation share {
        share(id: ${id}, to: ${JSON.stringify(to)}) { ...OpportunityData }
      }
    `)
    return response.share
  }

  async shareOppByEmail ({ id, email, name }: { id: number, email: string, name: string }): Promise<IOpportunity> {
    const response = await this.mutation<{ shareByEmail: IOpportunity }>(gql`
      ${fragment}
      mutation shareByEmail {
        shareByEmail(id: ${id}, to: "${email}", name: "${name}") { ...OpportunityData }
      }
    `)
    return response.shareByEmail
  }

  // subscriptions
  subscribeOpportunityUpdate (userId: number, next: INextCallback<{ updateOpportunity: IOpportunity }>): void {
    this.subscribe(gql`
      ${fragment}
      subscription updateOpportunity($userId: ID!) {
        updateOpportunity(userId: $userId) { ...OpportunityData }
      }
    `, next, { userId })
  }

  subscribeOpportunityCreate (userId: number, next: INextCallback<{ createOpportunity: IOpportunity }>): void {
    this.subscribe(gql`
      ${fragment}
      subscription createOpportunity($userId: ID!) {
        createOpportunity(userId: $userId) { ...OpportunityData }
      }
    `, next, { userId })
  }

  subscribeOpportunityApply (userId: number, next: INextCallback<{ applyOpportunity: IApplication }>): void {
    this.subscribe(gql`
      ${applicationFragment}
      subscription applyOpportunity($userId: ID!) {
        applyOpportunity(userId: $userId) { ...ApplicationData }
      }
    `, next, { userId })
  }
}

export default new OpportunityAPI()
