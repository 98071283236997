






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoDialog from '@/components/core/InfoDialog.vue'
import addConnection from '@/components/connections/AddConnection.vue'
import { SEA_BLUE_BG_GRADIENT } from '@/constants'
import { referContactRewardEvents } from '@/segmentAnalytics/rewards'

@Component({
  components: {
    InfoDialog,
    addConnection
  }
})
export default class ReferContactDialog extends Vue {
  @Prop({ required: true }) show: boolean
  @Prop({ default: true }) showReferContactBtn: boolean
  @Prop({ default: false }) showMoreInfo: boolean

  contentStyle: string = SEA_BLUE_BG_GRADIENT
  showAddConnectionDialog: boolean = false

  handleReferContactClick () {
    referContactRewardEvents.trackReferContact()
    this.showAddConnectionDialog = true
  }

  handleDialogHide () {
    referContactRewardEvents.trackClose()
    this.$emit('hide')
  }
}
