<template>
  <div>
    <div v-if="error">
      <v-expansion-panels
        v-model="panel"
        :readonly="readonly"
        single
      >
        <v-expansion-panel>
          <v-expansion-panel-header>API Error</v-expansion-panel-header>
          <v-expansion-panel-content>
            <pre class="error-message">{{ error }}</pre>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    panel: [0, 1],
    readonly: false,
  }),
  computed: {
    ...mapState({
      error: state => state.status.error,
    }),
  },
}
</script>

<style lang="scss" scoped>
.error-message {
  font-family: arial;
}
</style>
