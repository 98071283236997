import { Module } from 'vuex'
import Vue from 'vue'

import RootState from '@/types/rootState'
import { getPushSubscription, subscribePushNotifications, isPushNotificationsSupported } from '@/utils/pushNotifications'
import PushNotificationsAPI from '@/services/pushNotifications.service'

interface IState {
  subscription?: PushSubscriptionJSON
  subscribed: boolean
  subscriptionUpdateInProgress: boolean
}

const state: IState = {
  subscription: undefined,
  subscribed: false,
  subscriptionUpdateInProgress: false
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    subscribedToPushNotifications (state): boolean {
      return state.subscribed
    }
  },
  mutations: {
    setSubscription (state, payload: PushSubscriptionJSON): void {
      state.subscription = payload
      state.subscribed = true
      state.subscriptionUpdateInProgress = false
    },
    deleteSubscription (state): void {
      state.subscription = undefined
      state.subscribed = false
      state.subscriptionUpdateInProgress = false
    },
    setSubscriptionUpdateInProgress (state, toggle: boolean): void {
      state.subscriptionUpdateInProgress = toggle
    },
  },
  actions: {
    _afterUserAuthHook (context) {
      if (!context.getters.profileID) return
      if ('serviceWorker' in navigator && isPushNotificationsSupported()) {
        context.dispatch('initPushNotifications')
      }
    },
    async initPushNotifications (context) {
      const sw = await navigator.serviceWorker.ready
      if (!isPushNotificationsSupported()) return

      // user disabled push notifications
      if (Notification.permission === 'denied') return

      // user hasn't yet asked to subscribe push notifications, so we're asking right now
      if (Notification.permission === 'default') {
        await context.dispatch('subscribePushNotifications')
        return
      }

      // user enabled push notifications
      const pushSubscription = await getPushSubscription(sw).catch(console.error)

      if (pushSubscription) {
        await context.dispatch('storePushSubscription', pushSubscription)
      } else {
        await context.dispatch('subscribePushNotifications')
      }
    },
    async subscribePushNotifications (context) {
      context.commit('setSubscriptionUpdateInProgress', true)
      
      const sw = await navigator.serviceWorker.ready
      const subscription = await subscribePushNotifications(sw)
        .catch((error) => {
          console.warn(error)
          Vue.$logger?.logError(error, {
            profile: {
              id: context.getters.profile?.id,
              name: context.getters.profile?.name,
              memberStatus: context.getters.profile?.memberStatus,
              email: context.getters.profile?.email,
            }
          })
        })
      
      if (typeof subscription == 'object' && subscription?.endpoint) {
        // console.log(`User ${context.getters.profileID} allowed push notifications`)
        Vue.$logger?.logMessage(`User ${context.getters.profileID} allowed push notifications`, {
          id: context.getters.profile?.id,
          name: context.getters.profile?.name,
          endpoint: subscription.endpoint,
        })
        await context.dispatch('storePushSubscription', subscription)
      } else if (subscription === 'denied') {
        // console.log(`User ${context.getters.profileID} denied push notifications`)
        Vue.$logger?.logMessage(`User ${context.getters.profileID} denied push notifications`, {
          id: context.getters.profile?.id,
          name: context.getters.profile?.name,
        })
        localStorage.setItem('subscribedPushNotifications', 'true')
      }
      context.commit('setSubscriptionUpdateInProgress', false)
    },
    async unsubscribePushNotifications (context) {
      context.commit('setSubscriptionUpdateInProgress', true)
      const sw = await navigator.serviceWorker.ready
      const userSubscription: void | PushSubscription | null = await getPushSubscription(sw)
        .catch((error) => context.dispatch('setStatusError', error))

      if (!userSubscription) {
        return context.commit('setSubscriptionUpdateInProgress', false)
      }

      const unsubscribed: boolean | void = await userSubscription.unsubscribe()
        .catch((error) => context.dispatch('setStatusError', error))

      if (unsubscribed) {
        await context.dispatch('deletePushSubscription', userSubscription)
      }
      context.commit('setSubscriptionUpdateInProgress', false)
    },
    async storePushSubscription (context, subscription: PushSubscription) {
      await PushNotificationsAPI.addPushSubscription(subscription)
        .catch((error) => context.dispatch('setStatusError', error))
      context.commit('setSubscription', subscription)
      localStorage.setItem('subscribedPushNotifications', 'true')
    },
    async deletePushSubscription (context, subscription: PushSubscription) {
      await PushNotificationsAPI.deletePushSubscription(context.getters.profileID, subscription)
        .catch((error) => context.dispatch('setStatusError', error))
      context.commit('deleteSubscription')
      localStorage.setItem('subscribedPushNotifications', 'false')
    },
  }
}

export default store