import { Component, Vue } from 'vue-property-decorator'

export interface IIsMountedMixin {
  isMounted: boolean
}

@Component
export default class IsMountedMixin extends Vue implements IIsMountedMixin {
  isMounted: boolean = false

  mounted () {
    this.$nextTick(() => this.isMounted = true)
  }
}