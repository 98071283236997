import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'

import 'highlight.js/styles/monokai-sublime.css'

hljs.registerLanguage('json', json)

export default {
  deep: true,
  bind (el, binding) {
    // on first bind, highlight all targets
    const targets = el.querySelectorAll('code')
    targets.forEach(target => {
      // if a value is directly assigned to the directive, use this
      // instead of the element content.
      if (binding.value) {
        target.textContent = binding.value
      }
      hljs.highlightElement(target)
    })
  },
  componentUpdated (el, binding) {
    // after an update, re-fill the content and then highlight
    const targets = el.querySelectorAll('code')
    targets.forEach(target => {
      if (binding.value) {
        target.textContent = binding.value
        hljs.highlightElement(target)
      }
    })
  }
}
