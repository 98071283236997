import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import colors from '@/assets/theme-colors.js'

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    //disable: true,
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        ...colors,
      }
    }
  }
})
