import IFile from '@/types/models/storage.model'
import { ScreeningQuestionType } from '@/types/models/screeningQuestion.model'

export enum ApplicationStatus {
  APPLIED = 'applied',
  DECLINED = 'declined',
  MEETING = 'meeting',
  ACCEPTED = 'accepted',
}

export interface IApplication {
  id: string
  message: string
  status: ApplicationStatus
  user: number
  opportunity: string
  availableFrom: string
  timestamp: number
  cv?: string
  answers?: IApplicationAnswer[]
}

export interface IApplicant {
  id: number
  name: string
  picture: string
  location: string
  role: string
}

export interface IApplicants {
  opportunity: string
  users: IApplicant[]
}

export interface IApplicationAnswer {
  answer: string
  question: string
  type: ScreeningQuestionType
  order: number
}