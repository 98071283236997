<template>
  <v-row>
    <v-col
      cols="6"
      class="pb-0"
    >
      <v-dialog
        ref="dialogStartDate"
        v-model="modalStartDateDialog"
        :return-value.sync="dates.start.value"
        persistent
        width="290px"
        @input="handleDialogToggle"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :id="dates.start.id"
            v-model="dates.start.value"
            :label="startDateLabel"
            append-icon="event"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dates.start.value"
          scrollable
          first-day-of-week="1"
        >
          <div class="flex-grow-1" />
          <v-btn
            text
            color="primary"
            @click="handleStartDateCancelClick"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="handleStartDateOkClick"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>

    <div class="flex-grow-1" />

    <v-col
      v-if="showEndDate"
      cols="6"
      class="pb-0"
    >
      <v-dialog
        ref="dialogEndDate"
        v-model="modalEndDate"
        :return-value.sync="dates.end.value"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :id="dates.end.id"
            v-model="dates.end.value"
            :rules="dates.end.rules"
            :label="endDateLabel"
            append-icon="event"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dates.end.value"
          scrollable
          first-day-of-week="1"
          :min="dates.start.date"
        >
          <div class="flex-grow-1" />
          <v-btn
            text
            color="primary"
            @click="modalEndDate = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialogEndDate.save(dates.end.value)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    dates: {
      type: Object
    },
    startDateLabel: String,
    endDateLabel: String,
    showEndDate: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    //   startDate: new Date().toISOString().substr(0, 10),
    //   endDate: new Date().toISOString().substr(0, 10),
    modalStartDateDialog: false,
    modalEndDate: false
  }),
  methods: {
    handleDialogToggle (isOpen) {
      if (isOpen) {
        this.$emit('startDatePickerActive')
      }
    },
    handleStartDateOkClick () {
      this.$refs.dialogStartDate.save(this.dates.start.value)
      this.$emit('startDateChange', this.dates.start.value)
    },
    handleStartDateCancelClick () {
      this.modalStartDateDialog = false
      this.$emit('startDateCancel')
    }
  }
}
</script>
