




import { Component, Prop, Vue } from 'vue-property-decorator'

export interface IObserverOpts {
  root?: HTMLElement
  rootMargin?: string
}

@Component
export default class Observer extends Vue {
  @Prop() options?: IObserverOpts

  private observer: IntersectionObserver

  mounted () {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect')
      }
    }, this.options || {})

    this.observer.observe(this.$el)
  }

  destroyed () {
    this.observer.disconnect()
  }

}
