



































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import { waitAsync } from '@/utils/common'
import { IOpportunity } from '@/types/models/opportunity.model'


@Component
export default class OpportunityNotificationsTrigger extends Vue {
  @Prop({ required: true }) opportunity: IOpportunity

  triggersList: any[] = [
    {
      id: 'NOT7',
      hint: 'Send "Opportunity published" email notification to all users',
      title: 'Opportunity published',
      loading: false
    },
  ]

  async onTriggerClick (item: any) {
    Vue.set(item, 'loading', true)
    await this.$store.dispatch('triggerEmailNotification', { id: item.id, oppId: this.opportunity.id })
    Vue.set(item, 'loading', false)
  }
}
