import { Module } from 'vuex'

import AchievementAPI from '@/services/achievement.service'
import RootState from '@/types/rootState'
import { IAchievement } from '@/types/models/achievement.model'

interface IState {
  list: IAchievement[]
}

const state: IState = {
  list: [],
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    achievements (state): IAchievement[] {
      return state.list || []
    },
    achievementById: (state, getters) => (id: number): IAchievement|undefined => {
      return state.list.find(item => item.id === id)
    },
  },
  mutations: {
    setAchievementsList (state, payload: IAchievement[] = []): void {
      state.list = payload
    },
  },
  actions: {
    async fetchAchievements (context) {
      const data = await AchievementAPI.getAchievements()
        .catch(error => {
          context.dispatch('setStatusError', error)
        })
      if (!data) return
      context.commit('setAchievementsList', data)
    },
  }
}

export default store