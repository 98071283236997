import { render, staticRenderFns } from "./NotificationMessages.vue?vue&type=template&id=6764b751&scoped=true&"
import script from "./NotificationMessages.vue?vue&type=script&lang=ts&"
export * from "./NotificationMessages.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationMessages.vue?vue&type=style&index=0&id=6764b751&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6764b751",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VImg,VListItem,VListItemIcon,VListItemSubtitle})
