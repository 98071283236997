import { ICohortDate } from '@/types/date'
import moment from 'moment'

export const convertToCohortDate = (date?: string): ICohortDate|{} => {
  if (!date) return {}
  const momentDate = moment(date)

  return {
    day: momentDate.date(),
    week: momentDate.week(),
    month: momentDate.month() + 1,
    year: momentDate.year()
  }
}