import API from './api.service'
import gql from 'graphql-tag'
import axios, { AxiosResponse } from 'axios'
import mime from 'mime'

import IFile, { IS3PresignedPost } from '@/types/models/storage.model'

export class StorageService extends API {

  // query
  async getCvPresignedPost (filename: string): Promise<IS3PresignedPost> {
    const query = await this.query<{ getCvPresignedPost: IS3PresignedPost }>(gql`
      query getCvPresignedPost {
        getCvPresignedPost (filename: "${filename}") {
          url,
          fields
        }
      }
    `, { fetchPolicy: 'no-cache' })
    return query.getCvPresignedPost
  }

  async getScrenningQuestionPresignedPost (filename: string, questionId: number): Promise<IS3PresignedPost> {
    const query = await this.query<{ getScrenningQuestionPresignedPost: IS3PresignedPost }>(gql`
      query getScrenningQuestionPresignedPost {
        getScrenningQuestionPresignedPost (filename: "${filename}", questionId: ${questionId}) {
          url,
          fields
        }
      }
    `)
    return query.getScrenningQuestionPresignedPost
  }

  async getBusinessInfoPresignedPost (filename: string): Promise<IS3PresignedPost> {
    const query = await this.query<{ getBusinessInfoPresignedPost: IS3PresignedPost }>(gql`
      query getBusinessInfoPresignedPost {
        getBusinessInfoPresignedPost (filename: "${filename}") {
          url,
          fields
        }
      }
    `)
    return query.getBusinessInfoPresignedPost
  }

  // mutation
  async updateCv (pathToCv: string): Promise<string> {
    const data = await this.mutation<{ updateCv: string }>(gql`
      mutation updateCv {
        updateCv (pathToCv: "${pathToCv}")
      }
    `)
    return data.updateCv
  }

  async uploadFileToS3 (file: File, presignedPostData: IS3PresignedPost): Promise<AxiosResponse|void> {
    if (!presignedPostData) return
    const fields = JSON.parse(presignedPostData.fields)

    const formData: any = {}
    for (let key in fields) {
      formData[key] = fields[key]
    }

    // formData['Content-type'] = file.type
    formData['Content-type'] = mime.getType(file.name)
    formData['file'] = file

    const response = await axios.post(presignedPostData.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response
  }
}

export default new StorageService ()
