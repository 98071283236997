import { render, staticRenderFns } from "./Preferences.vue?vue&type=template&id=3176b870&scoped=true&"
import script from "./Preferences.vue?vue&type=script&lang=js&"
export * from "./Preferences.vue?vue&type=script&lang=js&"
import style0 from "./Preferences.vue?vue&type=style&index=0&id=3176b870&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3176b870",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VCol,VRow})
