<template>
  <v-row>
    <v-col>
      <v-checkbox
        class="mt-0"
        :id="shareable.id"
        v-model="shareable.value"
        label="Do you want to allow other Riverflex members to share this opportunity with people they know?"
        @change="handleChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import { trackOppAllowShareTap } from '@/segmentAnalytics/opportunities'

export default {
  props: ['shareable'],
  methods: {
    handleChange (value) {
      trackOppAllowShareTap(value)
    }
  }
}
</script>
