import netwrokTypes from './network-types'

// If you need to have tags in your message, add your template to the NotificationMessages.vue
export default {
  default: {
    icon: 'info',
    message: {
      1: () => 'You have no recent activity'
    }
  },
  profile: {
    message: {
      1: user => `${user} viewed your profile`
    }
  },
  opportunity: {
    image: 'assets/images/ui/bottom-nav/noun-job-2043873.svg',
    message: {
      1: opportunity => `You applied for ${opportunity}`, //[Opportunity]
      2: (opportunity, status) => `Your application to ${opportunity} has changed status to ${status}`, // [Opportunity] [status] (meeting/declined)
      3: (user, network) => `${user} successfully published to ${netwrokTypes[network].label}`, //[Name] [Opportunity: Network / RF / Private group]
      4: opportunity => `You asked Riverflex for support with ${opportunity}`, //[Opportunity]
      5: (user, opportunity) => `${user} applied to your opportunity: ${opportunity}`, //[Name] [Opportunity]
      6: opportunity => `${opportunity} was unpublished`, //[Opportunity]
      7: opportunity => `Riverflex are now supporting ${opportunity}`, //[Opportunity]
      8: opportunity => `Riverflex are unable to support ${opportunity}`, //[Opportunity]
    }
  },
  connection: {
    image: 'assets/images/ui/bottom-nav/network.svg',
    message: {
      1: user => `Your referral ${user} joined the Riverflex network`, //[Name]
      2: user => `${user} changed their status to available`, //[Name]
      3: user => `${user} wants to add you to their network`, //[Name]
      4: user => `${user} is now part of your network` //[Name]
    }
  },
  rewards: {
    image: 'assets/images/ui/bottom-nav/noun-trophy-863380.svg',
    message: {
      1: 'You’ve earned a reward!'
    }
  }
}
