


































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import NotificationMessages from '@/components/dashboard/NotificationMessages.vue'
import INotification from '@/types/models/notification.model'

@Component({
  components: {
    NotificationMessages
  },
  computed: {
    ...mapGetters(['notifications'])
  }
})
export default class Notifications extends Vue {
  seeMore: boolean = false

  handleSeeMoreClick () {
    this.seeMore = !this.seeMore
  }
}
