


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { trackRiverflexLeftHeaderLogoTap, trackRiverflexCenterHeaderLogoTap } from '@/segmentAnalytics/common'

interface ILogo {
  to: string
  src: string
}

@Component
export default class TopBar extends Vue {
  @Prop() hasProfile: boolean

  badge: ILogo = {
    to: '/home',
    src: require('@/assets/images/ui/top-bar/riverflex-logo.svg')
  }

  logo: ILogo = {
    to: '/home',
    src: require('@/assets/images/logo.svg')
  }

  setDrawer (value: boolean) {
    this.$store.commit('setDrawer', value)
  }

  toggleDrawer () {
    this.$store.commit('toggleDrawer')
  }

  onClickBtn () {
    this.setDrawer(!this.$store.state.drawer)
  }

  handleLogoClick (logo: ILogo, position: string) {
    if (position === 'left') {
      trackRiverflexLeftHeaderLogoTap()
    } else {
      trackRiverflexCenterHeaderLogoTap()
    }
    this.go(logo.to)
  }

  go (path: string) {
    this.$router.push(path)
  }
}
