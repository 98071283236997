



























































































































































































































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import draggable from 'vuedraggable'

import { IScreeningQuestion, ScreeningQuestionType, IAnswerOption, IControlItem } from '@/types/models/screeningQuestion.model'
import ScreeningQuestionIcon from '@/components/screeningQuestions/ScreeningQuestionIcon.vue'
import extensionsList from '@/assets/screening-question-file-extensions-list'
import { PartialBy } from '@/types/utils'
import { waitAsync } from '@/utils/common'
import { IOption } from '@/types/form'

@Component({
  components: {
    draggable,
    ScreeningQuestionIcon
  }
})
export default class ScreeningQuestionControl extends Vue {
  @Prop({ required: true }) question: IControlItem
  @Prop({ required: true }) label: string

  isInEditMode: boolean = false
  formModel: boolean = false
  extensionsList: IOption[] = extensionsList.map(item => ({
    text: item,
    value: item,
    divider: item === '-'
  }))
  rules = {
    requiredStringRule: [
      (value: string) => !!value || 'This field is required!',
    ]
  }

  $refs: {
    bodyInputEl: HTMLInputElement
    answerOptionInputEls: HTMLInputElement[]
  }

  get canDeleteAnswerOption (): boolean {
    if (!this.question.answerOptions) return false
    return this.question.answerOptions.length > 1
  }

  public focus () {
    this.toggleEditMode(true)
  }

  private async toggleEditMode (toggle: boolean) {
    this.isInEditMode = toggle

    if (this.isInEditMode) {
      await waitAsync(100)
      this.$refs.bodyInputEl.focus()
    }
  }

  private createEmptyAnswerOption (order: number = 0): IAnswerOption {
    return { body: '', order }
  }

  private handleQuestionDeleteClick () {
    this.$emit('delete')
  }

  private handleAnswerOptionDelete (index: number) {
    this.question.answerOptions?.splice(index, 1)
  }

  private async handleAnswerAddClick () {
    if (!this.question.answerOptions) {
      this.question.answerOptions = [this.createEmptyAnswerOption()]
    }

    const maxOrder = !this.question.answerOptions.length ? 0 : Math.max(...this.question.answerOptions.map(item => item.order))
    this.question.answerOptions = [
      ...this.question.answerOptions,
      this.createEmptyAnswerOption(maxOrder + 1)
    ]

    await waitAsync(100)
    const lastInputEl = this.$refs.answerOptionInputEls[this.$refs.answerOptionInputEls.length - 1]
    lastInputEl?.focus()
  }
}
