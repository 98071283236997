import Vue from 'vue'
import { ApolloClient, QueryOptions, ApolloError } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from 'apollo-link-ws'
import { split, DocumentNode, from } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { Subscription } from 'apollo-client/util/Observable'
import { onError } from 'apollo-link-error'

import AuthService from '@/services/auth.service'
import { INextCallback } from '@/types/api'
import { mapObjToKeyValueString } from '@/utils/api'
import store from '@/store'

const authMiddleware = setContext((_, { headers }) => {
  const token = AuthService.token
  return {
    headers: {
      ...headers,
      authorization: token ||  '',
    }
  }
})

const ws = new WebSocketLink({
  uri: `${process.env.VUE_APP_BACKEND_WS_URL}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    timeout: 30000,
    async connectionParams () {
      return {
        headers: { authorization: AuthService.token || '' }
      }
    },
    connectionCallback: (error, result) => {
      if (error) console.error(error)
    }
  }
})

const socketStatus = ['connecting', 'connected', 'reconnecting', 'reconnected', 'disconnected']
// @ts-ignore
for (let status of socketStatus) ws.subscriptionClient.on(status, () => console.info(status))
// @ts-ignore
ws.subscriptionClient.maxConnectTimeGenerator.duration = () => ws.subscriptionClient.maxConnectTimeGenerator.max

const apolloLink = from([
  onError(({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        const { message, locations, path, extensions } = err
        if (extensions?.code === 'UNAUTHENTICATED') {
          store.dispatch('localLogout')
          break
        }
      }
    }
  }),
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    ws,
    authMiddleware.concat(createUploadLink({ uri: process.env.VUE_APP_BACKEND_URL }))
  ),
])

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: apolloLink,
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

export default class API {

  protected async query<T> (query: DocumentNode, options?: Omit<QueryOptions, 'query'>): Promise<T> {
    const response = await client.query<T>({ query, ...options })
    return response.data
  }

  protected async mutation<T> (mutation: DocumentNode, variables?: { [key:string]: any }): Promise<T> {
    const response = await client.mutate<any>({ mutation, variables })
    return response.data
  }

  protected input (data: { [key:string]: any } = {}): string {
    return mapObjToKeyValueString(data)
  }

  protected subscribe (query: DocumentNode, next: INextCallback<any>, variables?: { [key:string]: any }): Subscription {
    return client.subscribe({ query, variables }).subscribe({
      next,
      error: (error: any) => {
        this.handleSubscriptionError(error)
      }
    }, this.handleSubscriptionError)
  }
  
  private handleSubscriptionError (error: any) {
    console.log(error)
    // workaround until we don't have extentions with code in error
    // TODO: update it on extensions.code === 'UNAUTHENTICATED' when it's ready
    if (error?.message === 'You must be logged in') {
      store.dispatch('localLogout')
    }
  }
}
