export default [
  '<200',
  '200-400',
  '400-500',
  '500-600',
  '600-800',
  '800-1000',
  '1000-1200',
  '1200-1400',
  '1400-1600',
  '1600-1800',
  '1800-2000',
  '2000>'
]
