import Vue from 'vue'
import { Module } from 'vuex'
import ApplicationAPI from '@/services/application.service'
import OpportunityAPI from '@/services/opportunity.service'

import { updateArrayItemById, mergeArraysUniqItems } from '@/utils/vuex'
import { IApplication, ApplicationStatus } from '@/types/models/application.model'
import RootState from '@/types/rootState'

interface IState {
  applications: IApplication[]
}

const state: IState = {
  applications: []
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    applicationByOppId: (state, getters) => (opportunityId: string, userId: number = getters.profileID): IApplication|undefined => {
      return state.applications.find(app => app.opportunity === opportunityId && app.user === userId)
    },
    getAppStatus: (state, getters) => (opportunityId: number, userId: number): string|null => {
      const app = getters.applicationByOppId(opportunityId, userId)
      return app ? app.status : null
    },
    isAppApplied: (state, getters) => (opportunityId: number, userId: number) => {
      return getters.getAppStatus(opportunityId, userId) === ApplicationStatus.APPLIED
    },
    isAppDeclined: (state, getters) => (opportunityId: number, userId: number): boolean => {
      return getters.getAppStatus(opportunityId, userId) === ApplicationStatus.DECLINED
    },
    isAppInMeeting: (state, getters) => (opportunityId: number, userId: number): boolean => {
      return getters.getAppStatus(opportunityId, userId) === ApplicationStatus.MEETING
    },
    isAppAccepted: (state, getters) => (opportunityId: number, userId: number): boolean => {
      return getters.getAppStatus(opportunityId, userId) === ApplicationStatus.ACCEPTED
    },
  },
  mutations: {
    setApplications (state, applications: IApplication[] = []) {
      state.applications = applications
    },
    addApplication (state, application: IApplication) {
      if (!application) return
      state.applications = [ application, ...state.applications ]
    },
    updateApplication (state, application: IApplication) {
      if (!application) return
      state.applications = updateArrayItemById<IApplication>(state.applications, application)
    },
    addApplications (state, applications: IApplication[]) {
      if (!applications) return
      state.applications = mergeArraysUniqItems<IApplication>(state.applications, applications)
    }
  },
  actions: {
    _afterUserAuthHook (context) {
      if (!context.getters.profileID) return console.warn('No profile id!')

      OpportunityAPI.subscribeOpportunityApply(context.getters.profileID, ({ data }) => {
        if (!data.applyOpportunity) return
        context.commit('addApplication', data.applyOpportunity)
        context.dispatch('listOpportunityApplicants', data.applyOpportunity.opportunity)
      })

      ApplicationAPI.subscribeApplicationUpdate(({ data }) => {
        if (!data.application) return
        context.commit('updateApplication', data.application)
      })
    },
    async listApplications (context) {
      const applications = await ApplicationAPI.getApplications().catch(err => console.warn(err))
      context.commit('setApplications', applications)
    },
    async declineApplication (context, application: IApplication) {
      const { id, opportunity } = application
      context.dispatch('_updateApplicationStatus', { id, status: ApplicationStatus.DECLINED, opportunity })
    },
    async scheduleApplicantMeeting (context, application: IApplication) {
      const { id, opportunity } = application
      context.dispatch('_updateApplicationStatus', { id, status: ApplicationStatus.MEETING, opportunity })
    },
    async confirmApplication (context, application: IApplication) {
      const { id, opportunity } = application
      context.dispatch('_updateApplicationStatus', { id, status: ApplicationStatus.ACCEPTED, opportunity })
    },
    async fetchOpportunityApplications (context, oppId: number) {
      const applications = await ApplicationAPI.getOpportunityApplications(oppId)
        .catch(err => context.dispatch('setStatusError', err))
      context.commit('addApplications', applications)
    },
    async fetchOpportunitiesApplications (context, oppsIds: number[]) {
      if (!oppsIds) return
      const applications = await ApplicationAPI.getOpportunitiesApplications(oppsIds)
        .catch(err => context.dispatch('setStatusError', err))
      context.commit('addApplications', applications)
    },
    async _updateApplicationStatus (context, payload: { id: number, status: ApplicationStatus, opportunity: number }) {
      const { id, status, opportunity } = payload
      await ApplicationAPI.upsert({ id, input: { status } })
        .catch(err => context.dispatch('setStatusError', err))

      // @ts-ignore
      // Vue.prototype.$analytics
      this._vm.$analytics.sendApplicationState({
        appID: id,
        oppID: opportunity,
        state: status
      })
    }
  }
}

export default store