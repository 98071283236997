





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import ProfilePic from '@/components/core/ProfilePic.vue'
import ViewBtn from '@/components/core/ViewBtn.vue'
import { IUser } from '@/types/models/user.model'
import { waitAsync } from '@/utils/common'
import { MODAL_TRANSITION_TIME } from '@/constants'
import { userReferralsEvents } from '@/segmentAnalytics/connections'

@Component({
  components: {
    ProfilePic,
    ViewBtn
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
})
export default class ListReferrals extends Vue {
  @Prop({ required: true, default: [] }) users: IUser

  showViewProfileDialog: boolean = false
  referralProfile: IUser|null = null
  isFetchingProfile: {[key:string]: boolean} = {}

  beforeCreate () {
    if (this.$options.components) {
      // circular dependency
      this.$options.components.UserProfileDialog = require('@/components/profile/UserProfileDialog.vue').default
    }
  }

  async handleProfileViewClick (id: number, index: number) {
    userReferralsEvents.trackProfileView()
    this.isFetchingProfile[index] = true
    const userData = await this.$store.dispatch('getUser', id)
    this.isFetchingProfile[index] = false
    if (!userData) return
    this.referralProfile = userData
    this.showViewProfileDialog = true
  }

  async handleViewUserDialogHide () {
    this.showViewProfileDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.referralProfile = null
  }
}
