<template>
  <v-col
    cols="12"
    class="text-center line pa-0 ma-0 pb-2"
  >
    <slot />
  </v-col>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.line {
  margin: 0 0 1em;
  line-height: 2em;
  padding: 0;
  //font-size:1.5em;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.line:before,
.line:after {
  content: " ";
  position: absolute;
  top: 50%;
  margin-left: -999em;
  height: 2px;
  width: 998em;
  border-top: 1px solid #e7e7e7;
}
.line:after {
  left: auto;
  width: 999em;
  margin: 0 0 0 1em;
}
</style>
