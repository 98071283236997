import Vue from 'vue'
import { IOpportunity } from '@/types/models/opportunity.model'
import { IOppSearchField } from '@/types/filters'

Vue.filter('filterOppsList', function (list: IOpportunity[], searchField: IOppSearchField): IOpportunity[] {
  if (!list || !searchField) return []
  const { headline = '', industry = '', location = '' } = searchField

  return list
    .filter(item => (item.headline || '').toLowerCase().indexOf((headline || '').toLowerCase()) > -1)
    .filter(item => (item.industry || '').toLowerCase().indexOf((industry || '').toLowerCase()) > -1)
    .filter(item => (item.location || '').toLowerCase().indexOf((location || '').toLowerCase()) > -1)
})