// https://github.com/web-push-libs/web-push#using-vapid-key-for-applicationserverkey
export function urlBase64ToUint8Array (base64String: string): Uint8Array {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const subscribePushNotifications = async (sw: ServiceWorkerRegistration): Promise<PushSubscription|NotificationPermission|void> => {
  return await sw.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_VAPID_PUBLIC_KEY as string)
  }).then(subscription => {
    return subscription
  }).catch(e => {
    if (Notification.permission === 'denied') {
      console.warn('Permission for notifications was denied')
      return Notification.permission
    } else {
      console.error('Unable to subscribe to push', e)
    }
  })
}

export const getPushSubscription = async (sw: ServiceWorkerRegistration) => {
  return sw.pushManager.getSubscription()
}

export const isPushNotificationsSupported = (): boolean => {
  return ('PushManager' in window)
}