<template>
  <div>
    <v-row
      v-for="(select, index) in prefsSelects"
      col="12"
      :key="select.label"
    >
      <v-col class="py-0 my-2">
        <v-autocomplete
          :id="select.id"
          :label="select.label"
          :items="select.items"
          v-model="select.value"
          item-color="#2e00df"
          max-height="39"
          :chips="select.chips"
          :multiple="select.multi"
          :rules="select.rules"
          solo
          single-line
          hide-details
          deletable-chips
          off-icon="radio_button_unchecked"
          on-icon="check_circle"
          clearable
          @click:clear="handleClear(index)"
          @change="handleChange(select)"
        />

        <div
          class="pl-3 pr-3 pt-2 caption grey--text text--darken-1"
          v-if="select.info"
        >
          {{ select.info }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { trackOppSelectFieldChange } from '@/segmentAnalytics/opportunities'
export default {
  props: { prefsSelects: { type: Array } },
  methods: {
    handleClear (index) {
      this.$emit('clear', index)
    },
    handleChange (field) {
      trackOppSelectFieldChange(field.id, field.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input ::v-deep .v-label {
  font-size: 1.25rem;
}
</style>
