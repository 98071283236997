

















import LoadingProgress from '@/components/core/LoadingProgress.vue'
import Observer, { IObserverOpts } from '@/components/core/Observer.vue'
import { PAGINATION_LIMIT } from '@/constants'

import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { isNil } from '@/utils/common'

// TODO: implement https://github.com/Akryum/vue-virtual-scroller in future
@Component({
  components: {
    LoadingProgress,
    Observer
  },
})
export default class Pagination extends Vue {
  @Prop() page?: number
  @Prop() limit?: number
  @Prop() total: number
  @Prop() loaderSize?: number
  @Prop() isFetching: boolean
  @Prop() observerOptions?: IObserverOpts

  currentPage: number = 0

  get isLoaderDisplayed (): boolean {
    return !!this.isFetching
  }

  get pageLimit (): number {
    return this.limit ? this.limit : PAGINATION_LIMIT
  }

  @Watch('page')
  onPageChanged (val: number, oldVal: number) {
    if (isNil(val)) return
    this.currentPage = val
  }

  handleIntersect () {
    if (!this.total) return
    const nextPage = this.currentPage + 1
    if ((nextPage * this.pageLimit) >= this.total) return
    this.currentPage = nextPage
    const pagination = { limit: this.pageLimit, page: nextPage }
    this.$emit('fetchMore', pagination)
  }

  created () {
    if (this.page) this.currentPage = this.page
  }

}
