export const waitAsync = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const isIos = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test( userAgent )
}

export const isInStandaloneMode = (): boolean => {
  // @ts-ignore
  const isInWebAppiOS = !!window.navigator.standalone
  const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches)
  return isInWebAppiOS || isInWebAppChrome
}

export const isInstallPWAEventExists = (): boolean => {
  return !!window.$installAppDeferredPrompt
}

export const canAddAppToHomeScreen = (): boolean => {
  if (isInStandaloneMode()) return false
  return isIos() || isInstallPWAEventExists()
}

export const isNil = (value: any): boolean => (
  value === null || value === undefined
)

export const getInfoDialogStorageName = (pageName: string) => `infoDialog_${pageName}_once_displayed`
export const wasInfoDialogDisplayed = (pageName: string): boolean => !!localStorage.getItem(getInfoDialogStorageName(pageName))
export const setInfoDialogDisplayed = (pageName: string): void => {
  localStorage.setItem(getInfoDialogStorageName(pageName), 'true')
}
