

















import { Component, Prop, Vue } from 'vue-property-decorator'

import InfoIcon from '@/components/core/InfoIcon.vue'

@Component({
  components: {
    InfoIcon
  }
})
export default class Greetings extends Vue {
  @Prop({ required: true }) title: string
  @Prop({ default: false }) showInfoIcon: boolean

  private handleInfoIconClick () {
    this.$emit('infoIconClick')
  }
}
