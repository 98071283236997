<template>
  <div>
    <v-list two-line>
      <v-list-item-group
        multiple
        v-if="applicants"
      >
        <template v-for="(profile, index) in applicants">
          <v-list-item
            :key="profile.id"
            inactive
            @click.stop="viewApp(profile.id)"
            class="pa-0 px-2 pt-0"
          >
            <template>
              <v-list-item-avatar
                width="62"
                height="62"
                class="mr-3"
              >
                <ProfilePic
                  :src="profile.picture"
                  :width="62"
                />
              </v-list-item-avatar>

              <v-list-item-content align="top">
                <v-list-item-title class="subtitle-1 mb-0 pb-0">
                  <strong>{{ profile.name }}</strong>
                </v-list-item-title>

                <v-list-item-subtitle class="role subtitle-1 ma-0 mb-1">
                  {{ profile.role }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="location">
                  {{ profile.location }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-spacer />
                <span
                  v-if="isAppApplied(opportunityId, profile.id)"
                  class="melon--text text-right"
                >
                  Applied
                </span>
                <span
                  v-else-if="isAppInMeeting(opportunityId, profile.id)"
                  class="secondary--text text-right"
                >
                  Invited to interview
                </span>
                <span
                  v-else-if="isAppDeclined(opportunityId, profile.id)"
                  class="brownGrey--text text-right"
                >
                  Declined
                </span>
                <span
                  v-else-if="isAppAccepted(opportunityId, profile.id)"
                  class="ultramarineBlue--text text-right"
                >
                  Accepted
                </span>
                <ViewBtn
                  class="mt-1"
                  title="view application"
                />
                <v-spacer />
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index + 1 < applicants.length"
            :key="index"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import ProfilePic from '@/components/core/ProfilePic'
import ViewBtn from '@/components/core/ViewBtn'
import { mapGetters } from 'vuex'

export default {
  props: ['opportunity'],
  components: {
    ProfilePic,
    ViewBtn
  },
  data () {
    return {
      disabledView: false
    }
  },
  computed: {
    ...mapGetters([
      'isAppApplied',
      'isAppDeclined',
      'isAppInMeeting',
      'isAppAccepted'
    ]),
    applicants () {
      return this.opportunity?.applicants ? this.opportunity.applicants : []
    },
    opportunityId () {
      return this.opportunity.id
    }
  },
  methods: {
    viewApp (app) {
      this.$emit('viewApp', app)
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  line-height: 1.1em !important;
}
.role,
.location,
.date {
  color: grey;
}
.red {
  color: red;
}
</style>
