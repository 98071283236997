<template>
  <div>
    <v-row no-gutters>
      <v-col
        class="profile-picture pr-3"
        cols="auto"
      >
        <ProfilePic
          :src="user.picture"
          :width="105"
        />
      </v-col>

      <v-col>
        <div class="mb-2">
          {{ user.name }}
        </div>
        <FormFields :fields="fields" />
        <UserAvailability />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfilePic from '@/components/core/ProfilePic'
import FormFields from '@/components/core/FormFields'
import UserAvailability from '@/components/profile/UserAvailability'

export default {
  name: 'ProfileHeader',
  props: { user: { type: Object } },
  components: {
    ProfilePic,
    FormFields,
    UserAvailability,
  },
  data () {
    return {
      fields: [
        //move to JSON
        {
          label: 'Role',
          name: 'role',
          isActive: false,
          value: this.user.role,
          editable: true
        },
        {
          label: 'Location',
          name: 'location',
          isActive: false,
          value: this.user.location,
          editable: true
        },
      ],
    }
  },
}
</script>
