import API from './api.service'
import gql from 'graphql-tag'

export class PushNotificationsAPI extends API {
  async addPushSubscription (subscription: PushSubscription): Promise<boolean> {
    const jsonData = subscription.toJSON()
    if (!jsonData.expirationTime) delete jsonData.expirationTime

    const response = await this.mutation<{ addPushSubscription: boolean }>(gql`
      mutation addPushSubscription {
        addPushSubscription(subscription: ${this.input(jsonData)})
      }`, { fetchPolicy: 'no-cache' })
    return response.addPushSubscription
  }

  async deletePushSubscription (userId: number, subscription: PushSubscription): Promise<boolean> {
    const jsonData = subscription.toJSON()
    const response = await this.mutation<{ deletePushSubscription: boolean }>(gql`
      mutation deletePushSubscription {
        deletePushSubscription (userId: ${userId}, endpoint:${jsonData.endpoint})
      }
    `)
    return response.deletePushSubscription
  }

}

export default new PushNotificationsAPI()
