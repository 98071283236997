import { NETWORK_TYPES } from '@/assets/network-types'
import OPP_STATUSES from '@/assets/opportunity-statuses'
import { IOpportunity } from '@/types/models/opportunity.model'

interface IItemWithId {
  id: number|string
}

export const set = (property: string) => (state: any, payload: any) => (state[property] = payload)

export const toggle = (property: string) => (state: any) => (state[property] = !state[property])

export const updateArrayItemById = <T extends IItemWithId>(array: T[], item: T): T[] => (
  (array || []).map(arrayItem => arrayItem.id !== item.id ? arrayItem : { ...arrayItem, ...item })
)

export const removeArrayItemById = <T extends IItemWithId>(array: T[], item: T): T[] => (
  (array || []).filter(arrayItem => arrayItem.id !== item.id)
)

export const findArrayItemById = <T extends IItemWithId>(array: T[], item: T): T|undefined => (
  (array || []).find(arrayItem => arrayItem.id === item.id)
)

export const updateOrAddItemById = <T extends IItemWithId>(array: T[], item: T): T[] => {
  const itemInArray = findArrayItemById(array, item)
  return !!itemInArray ? updateArrayItemById(array, item) : [ item, ...array ]
}

export const mergeArraysUniqItems = <T>(first: any[], second: any[], propName: string = 'id'): T[] => {
  const itemsMap = [...first, ...second].reduce((result, item, index) => {
    item.__mergeIndex = index
    result[item[propName]] = item
    return result
  }, {})
  return Object.values(itemsMap).sort((a: any, b: any) => a.__mergeIndex - b.__mergeIndex) as T[]
}

export const isOppSupportRequested = (opportunity?: IOpportunity): boolean => (
  (opportunity || {}).networkType === NETWORK_TYPES.SUPPORT_REQUESTED
)

export const isOppSupportProvided = (opportunity?: IOpportunity): boolean => (
  (opportunity || {}).status === OPP_STATUSES.SUPPORT_PROVIDED
)

export const getFileNameFromUrl = (url: string = ''): string => (
  url.split('/').pop()!.split('?').shift() || ''
)