import { IAuthState } from '@/types/api'

export const parseAuthState = (): IAuthState|null => {
  try {
    const state = window.location.hash.split('state=')[1]
    if (!state) return null
    return JSON.parse(decodeURIComponent(state))
  } catch (error) {
    console.warn(error)
    return null
  }
}