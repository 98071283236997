<template>
  <component :is="transformed" />
</template>

<script>
// Add "runtimeCompiler: true" to vue.config.js if you need to utilize dynamic components in runtime
export default {
  props: ['content'],
  computed: {
    transformed () {
      return {
        template: `<div>${this.content}</div>`,
        props: this.$options.props
      }
    }
  }
}
</script>
