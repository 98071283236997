



















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import ProfilePic from '@/components/core/ProfilePic.vue'
import Pagination from '@/components/core/Pagination.vue'
import ItemsNumberCol from '@/components/connections/ItemsNumberCol.vue'
import UserAchievements from '@/components/achievements/UserAchievements.vue'
import { ISimpleInvite, IUser } from '@/types/models/user.model'
import { IPagination } from '@/types/api'
import { trackConnectUserBtnTap, inviteEvents, userProfileEvents } from '@/segmentAnalytics/connections'

interface IOptionProp {
  type: 'connections'|'network'|'invites'
}

@Component({
  components: {
    ProfilePic,
    Pagination,
    ItemsNumberCol,
    UserAchievements
  },
  computed: {
    ...mapGetters([
      'profileID',
      'isAdmin',
      'invites',
      'connections',
    ])
  },
})
export default class ListConnections extends Vue {
  @Prop({ required: true }) items: IUser[]
  @Prop({ required: true }) option: IOptionProp
  @Prop({ required: true }) isFetchingMore: boolean
  @Prop({ required: true }) total: number
  @Prop({ default: false }) isSearching: boolean
  @Prop() page: number

  // data
  disabledView: boolean = false
  inviteSentTo: { [key: number]: boolean } = {}

  // computed
  profileID: number
  isAdmin: boolean
  invites: ISimpleInvite[]
  connections: IUser[]

  // methods
  phoneUser (user: IUser) {
    userProfileEvents.trackPhoneTap(user.id)
    this.disabledView = true
    // console.log('phone user: ', phone)
    window.location.href = `tel:${user.phone}`
  }
  emailUser (user: IUser) {
    userProfileEvents.trackEmailTap(user.id)
    this.disabledView = true
    // console.log('email user: ', email)
    window.location.href= `mailto:${user.email}`
  }
  viewUser (id: number) {
    if (!this.disabledView) {
      this.$emit('showUser', id)
    }
    this.disabledView = false
  }
  createInvite (id: number) {
    this.inviteSentTo = { ...this.inviteSentTo, [id]: true }
    trackConnectUserBtnTap()
    this.$emit('createInvite', id)
  }
  acceptInvite (id: number) {
    inviteEvents.trackAccept()
    this.disabledView = true
    this.$emit('acceptInvite', id)
  }
  declineInvite (id: number) {
    inviteEvents.trackDecline()
    this.disabledView = true
    this.$emit('declineInvite', id)
  }
  handleFetchMore ($event: IPagination) {
    this.$emit('fetchMore', $event)
  }
  isInviteSent (userItem?: IUser) {
    if (!userItem) return false
    return !!this.invites?.find(invite => invite?.to === userItem?.id) || !!this.inviteSentTo[userItem.id]
  }
  isInvitePending (userItem?: IUser) {
    if (!userItem) return false
    return !!this.invites?.find(invite => {
      return invite?.from === userItem?.id && invite?.to === this.profileID
    })
  }
}
