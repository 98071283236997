














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import viewProfileDialog from '@/components/profile/viewProfileDialog.vue'
import { userProfileEvents } from '@/segmentAnalytics/connections'
import { IUser } from '@/types/models/user.model'

@Component({
  components: {
    viewProfileDialog,
  }
})
export default class UserProfileDialog extends Vue {
  @Prop({ required: true, default: false }) show: boolean
  @Prop({ required: true }) profile: IUser

  @Watch('show')
  onShowChange (val: number, oldVal: number) {
    if (!!val) {
      userProfileEvents.trackView(this.profile.id)
    }
  }

  get showDialog (): boolean {
    return this.show
  }

  set showDialog (value: boolean) {
    this.$emit('update:show', value)
  }

  handleDialogHide () {
    this.$emit('hide')
  }
}
