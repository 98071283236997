import Vue from 'vue'

import App from './App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import '@/filters'

import VueAnalytics from 'vue-analytics'
if (process.env.VUE_APP_GA) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA,
    router,
    ignoreRoutes: ['callback']
  })
}

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueMoment from 'vue-moment'
Vue.use(VueMoment)

import HighlightJs from './directives/highlight'
Vue.directive('highlightjs', HighlightJs)

import trackEvent from './directives/trackEvent'
Vue.directive('trackEvent', trackEvent)

import insertSanitizedHtml from './directives/insertSanitizedHTML'
Vue.directive('htmlSanitized', insertSanitizedHtml)

import analyticsPlugin from '@/plugins/analytics.plugin'
Vue.use(analyticsPlugin, { store })

import mediaQueryPlugin from '@/plugins/mediaQuery.plugin'
Vue.use(mediaQueryPlugin, { vuetify })

import loggerPlugin from '@/plugins/logger.plugin'
Vue.use(loggerPlugin, {
  router,
  origins: ['localhost', /riverflex/],
  ignoreErrors: ['NavigationDuplicated'],
  environment: process.env.VUE_APP_ENVIRONMENT,
  store,
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h: any) => h(App)
}).$mount('#app')