

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoDialog from '@/components/core/InfoDialog.vue'
import createOpportunity from '@/components/opportunities/createOpportunity.vue'
import { waitAsync } from '@/utils/common'
import { MODAL_TRANSITION_TIME, ORANGE_BG_GRADIENT } from '@/constants'
import { postJobRewardEvents } from '@/segmentAnalytics/rewards'

@Component({
  components: {
    InfoDialog,
    createOpportunity
  }
})
export default class PostJobCampaignDialog extends Vue {
  @Prop({ required: true }) show: boolean
  contentStyle: string = ORANGE_BG_GRADIENT

  showCreateDialog: boolean = false

  handlePostOppClick () {
    postJobRewardEvents.trackPostJob()
    this.showCreateDialog = true
  }

  handleDialogHide () {
    postJobRewardEvents.trackClose()
    this.$emit('hide')
  }

  async submitOpp (input: any) {
    this.showCreateDialog = false
    await waitAsync(MODAL_TRANSITION_TIME)
    this.$store.dispatch('upsertOpportunity', input)
  }
}
