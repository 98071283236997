
























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'
import { IUser } from '@/types/models/user.model'
import { trackReferralSignIn, trackOppShareReferralSignIn } from '@/segmentAnalytics/registration'
import errorConnection from '@/components/connections/ErrorConnection.vue'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'
import GDPRAgreement from '@/components/gdpr/GDPRAgreement.vue'
import InfoDialog from '@/components/core/InfoDialog.vue'

@Component({
  components: {
    errorConnection,
    LegacyPageContent,
    GDPRAgreement,
    InfoDialog,
  }
})
export default class Connect extends Vue {
  @Prop() hasProfile!: boolean

  @State(state => state.profile.profile) user: IUser
  @State(state => state.profile.isNewUser) isNewUser!: boolean
  @State(state => state.profile.gdprAccepted) gdprAccepted: boolean

  connectUser: IUser|null|'not-found' = null
  userHasReferrer: boolean = false
  showGDPRDialog: boolean = false;

  get connectedWithUserMsg (): string {
    if (typeof this.connectUser === 'string') return ''
    return `You are connected with ${this.connectUser ? this.connectUser.name : ''}`
  }

  get oppId (): string {
    return (this.$route.query as { opp: string }).opp
  }

  get linkToOpp (): string {
    return `/opportunities/${this.oppId}`
  }

  async created () {
    if (this.isNewUser) {
      await this.$store.dispatch('connectReferral', {
        userId: this.$route.params.id,
        oppId: this.oppId
      }).then(() => {
        if (!this.gdprAccepted) this.showGDPRDialog = true
      }).catch(error => this.userHasReferrer = true)
    }

    this.connectUser = await this.$store.dispatch('getUser', this.$route.params.id) || 'not found'
    this.trackAnalytics()
  }

  private trackAnalytics () {
    if (!this.isNewUser || this.userHasReferrer) return
    !!this.oppId ? trackOppShareReferralSignIn() : trackReferralSignIn()
  }

  handleAcceptAgreementClick () {
    this.showGDPRDialog = false
  }
}
