<template>
  <v-row
    no-gutters
    align="center"
    class="mt-2"
  >
    <v-col
      class="title"
      cols="4"
    >
      Day Rate
    </v-col>

    <v-col
      cols="3"
      class="mr-3"
    >
      <v-select
        :id="dayRate.currency.id"
        v-model="dayRate.currency.value"
        :items="dayRate.currency.symbols"
        :label="dayRate.currency.label"
        :rules="dayRate.currency.rules"
        item-color="#2e00df"
        max-height="39"
        solo
        single-line
        hide-details
        @change="handleChange(dayRate.currency)"
      />
    </v-col>

    <v-col>
      <v-select
        :id="dayRate.rate.id"
        v-model="dayRate.rate.value"
        :items="dayRate.rate.amounts"
        :label="dayRate.rate.label"
        :rules="dayRate.rate.rules"
        item-color="#2e00df"
        max-height="39"
        solo
        single-line
        hide-details
        @change="handleChange(dayRate.rate)"
      />
    </v-col>
  </v-row>
</template>
<script>
import { trackOppSelectFieldChange } from '../../../segmentAnalytics/opportunities'
export default {
  props: { dayRate: { type: Object } },
  methods: {
    handleChange (field) {
      trackOppSelectFieldChange(field.id, field.value)
      // this.$emit('change', field)
    }
  }
}
</script>
