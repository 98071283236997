export default [
  'Accounting',
  'Agile technology development',
  'Benchmarking and competitive analysis',
  'Big data',
  'Branding',
  'Building leadership skills',
  'Business plan development',
  'Business Transformation',
  'Business unit strategy',
  'Change management',
  'Customer analysis',
  'Customer life cycle management',
  'Customer segmentation',
  'Design Sprint facilitation',
  'Digital marketing',
  'Digital Transformation',
  'Digitalisation',
  'E-commerce',
  'Go-to-market strategy',
  'Growth strategy',
  'Growth trend analysis',
  'Innovation management',
  'IT Architecture',
  'IT Strategy',
  'Lean management',
  'Market entry strategy',
  'Market sizing',
  'Marketing ROI',
  'Marketing- and communications planning',
  'Organisational design',
  'Performance marketing',
  'Portfolio strategy',
  'Post merger integration',
  'Pricing',
  'Process management',
  'Product development',
  'Production optimisation',
  'Project management',
  'Project management office',
  'Regulatory strategy',
  'Sales and account management',
  'Statistical analysis',
  'Supply chain management',
  'Sustainability strategy',
  'Tax advisory services',
  'Website design',
  'UX Design',
  'UI Design',
  'Website development',
  'Business architecture'
]

export const roleGroups = [
  {
    title: 'Organization',
    items: [
      'Agile technology development',
      'Change management',
      'Organisational design',
      'Building leadership skills',
      'Post merger integration',
      'Digital culture',
      'KPIs & Incentives',
      'Business architecture'
    ]
  },
  {
    title: 'Product',
    items: [
      'Product Development',
      'Process management',
      'Production optimisation',
      'Supply chain management'
    ]
  },
  {
    title: 'Marketing & sales',
    items: [
      'Branding',
      'Digital marketing',
      'Customer segmentation',
      'Customer life cycle management',
      'E-commerce',
      'Marketing ROI',
      'Marketing-and communications planning',
      'Performance marketing',
      'Pricing',
      'Sales and accounts management',
      'Growth hacking'
    ]
  },
  {
    title: 'Strategy',
    items: [
      'Business plan development',
      'Business unit strategy',
      'Market entry strategy',
      'Go to market strategy',
      'Growth strategy',
      'Portfolio strategy',
      'Innovation management',
      'Sustainability strategy',
      'Regulatory strategy',
      'eCommerce and omnichannel strategy'
    ]
  },
  {
    title: 'Market research',
    items: [
      'Customer analysis',
      'Benchmarking and competitive analysis',
      'Market sizing',
      'Growth trend analysis',
      'Statistical analysis'
    ]
  },
  {
    title: 'Transformation',
    items: [
      'Transformation leadership (incl. Programme & Project Management)',
      'Business Transformation',
      'Digital Transformation',
      'Digitalisation',
      'Lean management',
      'Operating Model',
      'Digital Process Design',
      'Project management'
    ]
  },
  {
    title: 'Design & Web Dev',
    items: [
      'Website development',
      'UX/UI Design',
      'Customer experience design',
      'Innovation',
      'Strategic design',
      'Design Sprint facilitation'
    ]
  },
  {
    title: 'IT',
    items: [
      'IT Architecture',
      'IT strategy',
      'Robotic process automation',
      'Enterprise Apps (I.e. ERP)',
      'Business analysis and system design',
      'Software engineering',
      'Cloud',
      'Infrastructure and platforms',
    ]
  },
  {
    title: 'Security',
    items: [
      'Security Strategy',
      'IAM',
      'Cyber Defense',
      'Security architecture',
      'Security Governance',
    ]
  },
  {
    title: 'Agile (Transformation)',
    items: [
      'Agile and lean theory and philosophy',
      'Agile methodologies (e.g. Scrum, Kanban etc.)',
      'Scaled agile methodologies (e.g. SAFe)',
      'Agile coaching',
      'Product ownership / management',
    ]
  },
  {
    title: 'Data and Insight-Driven Organisation',
    items: [
      'Big data',
      'Data & Analytics',
      'Business intelligence',
      'AI / ML',
    ]
  }
]
