










































import { Component, Prop, Vue } from 'vue-property-decorator'

import ScreeningQuestionIcon from '@/components/screeningQuestions/ScreeningQuestionIcon.vue'
import { IApplicationAnswer } from '@/types/models/application.model'

@Component({
  components: {
    ScreeningQuestionIcon
  }
})
export default class ScreeningQuestionsAnswers extends Vue {
  @Prop({ required: true, default: [] }) answers: IApplicationAnswer[]
}
