


























































































































































































































































import { mapGetters } from 'vuex'
import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator'
import { IDebouncedFunction, debounce } from 'debounce'
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
])

import MyConnections from '@/components/profile/MyConnections.vue'
import SearchField from '@/components/core/SearchFieldConnection.vue'
import listConnections from '@/components/connections/ListConnections.vue'
import addConnection from '@/components/connections/AddConnection.vue'
import UserProfileDialog from '@/components/profile/UserProfileDialog.vue'
import InfoDialog from '@/components/core/InfoDialog.vue'
import InfoIcon from '@/components/core/InfoIcon.vue'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'

import { PAGINATION_LIMIT, MODAL_TRANSITION_TIME } from '@/constants'
import ShowInfoDialogMixin, { IShowDialogMixin } from '@/mixins/ShowInfoDialog.mixin'
import ViewSearchMixin, { IViewSearchMixin } from '@/mixins/ViewSearch.mixin'
import { IUser, ISimpleInvite } from '@/types/models/user.model'
import { IPagination } from '@/types/api'
import { IConnSearchField } from '@/types/filters'
import { ConnsListName } from '@/store/connections'
import ReferContactRewardsDialog from '@/components/rewards/ReferContactRewardsDialog.vue'
import { Route } from 'vue-router'
import { waitAsync } from '@/utils/common'
import { trackConnectionsScreenTab, trackConnectionsPageScroll, trackInviteToNetworkBtnTap, trackConnectionProfileTap } from '@/segmentAnalytics/connections'
import { trackHelpBtnTap } from '@/segmentAnalytics/common'

const ViewMixins = Mixins(ShowInfoDialogMixin, ViewSearchMixin)
@Component({
  components: {
    MyConnections,
    SearchField,
    listConnections,
    addConnection,
    UserProfileDialog,
    InfoIcon,
    InfoDialog,
    ReferContactRewardsDialog,
    LegacyPageContent
  },
  computed: {
    ...mapGetters([
      'profileID',
      'isAdmin',
      'isInsider',
      'isMember',
      'connections',
      'users',
      'incomingInvites',
      'connectionsTotal',
      'usersTotal',
      'incomingInvitesTotal',
      'invites',
      'canSeeUsersNetwork'
    ]),
  }
})
export default class Connections extends ViewMixins implements IShowDialogMixin, IViewSearchMixin {
  @Prop() hasProfile: boolean

  // data
  pageName: string = 'connections'
  routeName: string = 'connections'
  activeTab: string = 'connections'
  searchField: {
    [ConnsListName.connections]: IConnSearchField
    [ConnsListName.users]: IConnSearchField
    [ConnsListName.incomingInvites]: IConnSearchField
  } = {
    connections: {
      name: ''
    },
    users: {
      name: ''
    },
    incomingInvites: {
      name: ''
    }
  }
  pagination: any = {
    connections: {
      isFetching: false,
      page: 0,
    },
    incomingInvites: {
      isFetching: false,
      page: 0
    },
    users: {
      isFetching: false,
      page: 0
    }
  }
  addConnectionDialog: boolean = false
  viewProfileDialog: boolean = false
  selectedUser: IUser|null = null
  showInfoDialog: boolean = false
  showReferContactDialog: boolean = false
  handleScrollDebounced: IDebouncedFunction

  // computed
  profileID?: number
  isAdmin: boolean
  isInsider: boolean
  isMember: boolean
  connections: IUser[]
  users: IUser[]
  incomingInvites: IUser[]
  connectionsTotal: number
  usersTotal: number
  incomingInvitesTotal: number
  invites: ISimpleInvite[]
  canSeeUsersNetwork: boolean

  // lifecycle hools
  async created () {
    this.handleScrollDebounced = debounce(this.handlePageScroll, 1000)
    window.addEventListener('scroll', this.handleScrollDebounced)

    if (this.$route.params.id) {
      await this.showViewProfileDialog(+this.$route.params.id)
      await waitAsync(MODAL_TRANSITION_TIME)
    }

    if (this.canSeeUsersNetwork) {
      await this.$store.dispatch('listUsers')
    } else {
      await this.$store.dispatch('getAllUsersNumber')
    }
    // this.$store.dispatch('listUsers')
    await this.$store.dispatch('listConnections')
    await this.$store.dispatch('listIncomingInvites')
    await this.$store.dispatch('fetchAchievements')
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScrollDebounced)
  }

  // https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
  async beforeRouteUpdate (to: Route, from: Route, next: Function) {
    if (to.params.id) {
      await this.showViewProfileDialog(+to.params.id)
    } else {
      this.hideViewProfileDialog()
    }
    next()
  }

  // methods
  private async showViewProfileDialog (userId: number) {
    if (!userId) return
    this.selectedUser = await this.$store.dispatch('getUser', userId)
    if (!this.selectedUser) {
      this.routeBackToConnectionsPage()
      return
    }
    this.viewProfileDialog = true
  }

  private hideViewProfileDialog () {
    this.viewProfileDialog = false
  }

  private createInvite (inviteId: number) {
    this.$store.dispatch('sendInvite', inviteId)
  }

  private acceptInvite (userId: number) {
    this.$store.dispatch('acceptInvite',this.getInvite({ from: userId, to: this.profileID }))
  }

  private declineInvite (userId: number) {
    this.$store.dispatch('declineInvite', this.getInvite({ from: userId, to: this.profileID }))
  }

  private getInvite ({ from, to }: { from: number, to?: number }): ISimpleInvite|undefined {
    return this.invites?.find((invite: ISimpleInvite) => invite.to === to && invite.from === from)
  }

  private handleInviteToNetworkClick () {
    trackInviteToNetworkBtnTap()
    this.addConnectionDialog = true
  }

  protected routeToViewProfileDialog (userId: number) {
    this.$router.push({ name: this.routeName, params: { 'id': `${userId}` } })
  }

  protected routeBackToConnectionsPage () {
    this.$router.push({ name: this.routeName })
  }

  private async handleFetchMore (listName: ConnsListName, pagination: IPagination) {
    this.fetchData(listName, pagination)
  }

  private async handleSearch (listName: ConnsListName, search: IConnSearchField) {
    this.fetchData(listName, { page: 0, limit: PAGINATION_LIMIT })
  }

  private handleHideViewProfileDialog () {
    this.routeBackToConnectionsPage()
  }

  private handleTabsChange (tab: string) {
    trackConnectionsScreenTab(tab)
  }

  private handleShowViewProfileDialog (userId: number) {
    if (this.activeTab === 'connections') {
      trackConnectionProfileTap()
    }
    this.routeToViewProfileDialog(userId)
  }

  private handlePageScroll () {
    trackConnectionsPageScroll(this.activeTab)
  }

  private handleInfoIconClick () {
    trackHelpBtnTap('connections')
    this.showInfoDialog = true
  }

  getActionByListName (listName: ConnsListName): string|undefined {
    let requestAction
    switch (listName) {
    case 'users':
      requestAction = 'listUsers'
      break
    case 'connections':
      requestAction = 'listConnections'
      break
    case 'incomingInvites':
      requestAction = 'listIncomingInvites'
      break
    }
    if (!requestAction) {
      console.warn('RequestAction is not found!', listName)
    }
    return requestAction
  }

  isSearching (listName: ConnsListName): boolean {
    return !!this.searchField[listName].name
  }
}
