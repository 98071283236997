














































































import { mapGetters } from 'vuex'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IUser } from '@/types/models/user.model'

@Component({
  computed: {
    ...mapGetters(['profile'])
  }
})
export default class UploadCV extends Vue {
  @Prop({ default: false }) uploading: boolean
  @Prop({ default: false }) showUploadDialog: boolean
  @Prop({ default: 'Upload' }) title: string
  @Prop() supportedFiles: string

  // data
  clearable: boolean = false
  file: null|File = null

  // computed
  profile: IUser

  @Watch('showUploadDialog')
  onShowUploadDialogChanged (val: number, oldVal: number) {
    if (val) {
      this.file = null
    }
  }

  // methods
  hideModal () {
    this.$emit('hideModal')
  }

  async upload () {
    this.$emit('upload', this.file)
  }

  handleFileSelect (file: File) {
    this.$emit('selectFile', file)
  }
}
