import { IIndustry, ILocation } from '@/types/dictionaries'
import { IApplicant } from '@/types/models/application.model'
import { IScreeningQuestion } from '@/types/models/screeningQuestion.model'

export enum NetworkType {
  UNPUBLISHED = 0,
  ALL = 1,
  PERSONAL_NETWORK = 2,
  SPECIFIC_CONNECTIONS = 3,
  SUPPORT_REQUESTED = 10,
}

export enum OpportunityStatus {
  SUPPORT_PROVIDED = 'supportProvided'
}

export interface IOpportunity {
  id: string
  headline: string
  description: string
  role: string
  rolePreview?: string
  industry: IIndustry
  location: ILocation
  duration: number
  timeframe: string
  timestamp: string
  updatedAt: string
  currency: string
  rate: number
  startDate: string
  endDate: string
  networkType: NetworkType
  selectedConns?: number[]
  owner?: {
    id: number
    name: string
  }
  status?: OpportunityStatus
  shareable: boolean
  shares: number
  sharedToMe: boolean
  applicants?: IApplicant[]
  offerId?: number
  screeningQuestions?: IScreeningQuestion[]
  applicationsCount?: number
}
