


















































import { mapGetters } from 'vuex'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { IUser } from '@/types/models/user.model'
import ListReferrals from '@/components/profile/ListReferrals.vue'
import Pagination from '@/components/core/Pagination.vue'
import UserAPI from '@/services/user.service'
import { IPagination } from '@/types/api'
import { PAGINATION_LIMIT, MODAL_TRANSITION_TIME } from '@/constants'
import { waitAsync } from '@/utils/common'
import { userReferralsEvents } from '@/segmentAnalytics/connections'

@Component({
  components: {
    ListReferrals,
    Pagination
  }
})
export default class UserReferralsDialog extends Vue {
  @Prop({ required: true }) userId: number
  @Prop({ required: true, default: false }) show: boolean

  // data
  referralsList: IUser[] = []
  isFetching: boolean = false
  total: number = 0
  page: number = 0

  // getters
  get showDialog (): boolean {
    return this.show
  }

  set showDialog (value: boolean) {
    this.$emit('update:show', value)
  }

  @Watch('show')
  async onShowDialogChanged (val: number, oldVal: number) {
    if (!val) {
      userReferralsEvents.trackDialogClose()
      this.referralsList = []
      return
    }
    await waitAsync(MODAL_TRANSITION_TIME)
    this.fetchUserReferrals()
  }

  // methods
  private async fetchUserReferrals (page = 0, limit = PAGINATION_LIMIT) {
    if (this.isFetching) return
    this.isFetching = true
    this.page = page
    const referrals = await UserAPI.getUserReferrals(this.userId, { page })
      .catch(error => this.$store.dispatch('setStatusError', error))
    this.isFetching = false
    if (!referrals) return

    this.total = referrals?.meta?.total || referrals.getUserReferrals.length
    this.referralsList = page === 0 ?
      referrals.getUserReferrals :
      [...this.referralsList, ...referrals.getUserReferrals ]
  }

  private async handleFetchMore (pagination: IPagination) {
    this.fetchUserReferrals(pagination.page, pagination.limit)
  }
}
