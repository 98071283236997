






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoIcon from '@/components/core/InfoIcon.vue'

@Component({
  components: {
    InfoIcon
  }
})
export default class InfoDialog extends Vue {
  @Prop({ required: true }) title: string
  @Prop({ required: true }) show: boolean
  @Prop({ default: false }) persistent: boolean
  @Prop() contentStyle: string
  @Prop() contentClass: string

  get showDialog (): boolean {
    return this.show
  }

  set showDialog (value: boolean) {
    this.$emit('update:show', value)
  }

  hideModal () {
    this.showDialog = false
    this.$emit('hide')
  }
}
