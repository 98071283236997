import OpportunityAPI from '@/services/opportunity.service'
import ApplicationAPI from '@/services/application.service'
import {
  updateArrayItemById, removeArrayItemById, updateOrAddItemById,
  mergeArraysUniqItems, isOppSupportRequested, isOppSupportProvided
} from '@/utils/vuex'
import { IOpportunity } from '@/types/models/opportunity.model'
import RootState from '@/types/rootState'
import { Module } from 'vuex'
import { IApplicants, IApplicant, IApplication, ApplicationStatus } from '@/types/models/application.model'
import { IPagination, IApiMeta, IOppsRequestOptions } from '@/types/api'

interface IState {
  all: {
    list: IOpportunity[],
    total: number
  },
  published: {
    list: IOpportunity[],
    total: number
  },
  userApplied: {
    list: IOpportunity[],
    total: number
  },
}

export enum OppsListName {
  all = 'all',
  published = 'published',
  userApplied = 'userApplied'
}

const state: IState = {
  all: {
    list: [],
    total: 0
  },
  published: {
    list: [],
    total: 0
  },
  userApplied: {
    list: [],
    total: 0
  },
}

const store: Module<IState, RootState> = {
  state,
  getters: {
    all (state): IOpportunity[] {
      return state.all.list || []
    },
    published (state): IOpportunity[] {
      return state.published.list || []
    },
    userApplied (state): IOpportunity[] {
      return state.userApplied.list || []
    },
    allTotal: (state): number => {
      return state.all.total
    },
    publishedTotal: (state): number => {
      return state.published.total
    },
    userAppliedTotal: (state): number => {
      return state.userApplied.total
    },
    isOppSupportProvided: (state, getters) => (opportunity: IOpportunity): boolean => {
      return isOppSupportProvided(opportunity)
    },
  },
  mutations: {
    setAll (state, { opportunities, pagination, meta } = {}) {
      if (!opportunities) return
      state.all.list = !!pagination?.page ?
        mergeArraysUniqItems(state.all.list, opportunities)
        : opportunities
      if (meta) state.all.total = meta.total
    },
    setPublished (state, { opportunities, pagination, meta }: {
      opportunities: IOpportunity[],
      pagination: IPagination,
      meta: IApiMeta
    }) {
      if (!opportunities) return

      state.published.list = !!pagination?.page ?
        mergeArraysUniqItems<IOpportunity>(state.published.list, opportunities)
        : opportunities
      if (meta) state.published.total = meta.total
    },
    setUserApplied (state, { opportunities, meta, pagination }: {
      opportunities: IOpportunity[],
      pagination: IPagination,
      meta: IApiMeta
    }) {
      if (!opportunities) return
      state.userApplied.list = !!pagination?.page ?
        mergeArraysUniqItems<IOpportunity>(state.userApplied.list, opportunities) :
        opportunities
      if (meta) state.userApplied.total = meta.total
    },
    addOpportunity (state, opportunity: IOpportunity) {
      if (!opportunity) return
      state.all.list = [ opportunity, ...state.all.list ]
      state.all.total++
    },
    addPublishedOpportunity (state, opportunity: IOpportunity) {
      if (!opportunity) return
      state.published.list = [ opportunity, ...state.published.list ]
      state.published.total++
    },
    updateOpportunity (state, opportunity: IOpportunity) {
      if (!opportunity) return
      const allListLength = state.all.list.length
      if (isOppSupportRequested(opportunity)) {
        state.all.list = removeArrayItemById<IOpportunity>(state.all.list, opportunity)
        if (allListLength !== state.all.list.length) state.all.total--
      } else {
        state.all.list = updateOrAddItemById<IOpportunity>(state.all.list, opportunity)
        if (allListLength !== state.all.list.length) state.all.total++
        state.published.list = updateArrayItemById(state.published.list, opportunity)
      }

      state.published.list = updateArrayItemById<IOpportunity>(state.published.list, opportunity)
    },
    unpublishOpportunity (state, { opportunity, profileID }: { opportunity: IOpportunity, profileID: number }) {
      if (!opportunity) return
      const allListLength = state.all.list.length
      state.all.list = removeArrayItemById<IOpportunity>(state.all.list, opportunity)
      if (allListLength !== state.all.list.length) state.all.total--

      if (opportunity?.owner?.id !== profileID) return
      state.published.list = updateOrAddItemById<IOpportunity>(state.published.list, opportunity)
    },
    addApplicants (state, { applicants, oppId }: { applicants: IApplicant[], oppId: string }) {
      if (!applicants) return
      state.published.list = state.published.list.map(opp => opp.id === oppId ? { ...opp, applicants } : opp)
    },
    addAppliedOpportunity (state, oppId: string) {
      const appliedOpportunity = state.all.list.find(item => item.id === oppId)
      if (!appliedOpportunity) return
      state.userApplied.list = [ appliedOpportunity, ...state.userApplied.list ]
      state.userApplied.total++
    },
  },
  actions: {
    _afterUserAuthHook (context) {
      if (!context.getters.profileID) return console.warn('No profile id!')
      if (context.getters.isAdmin) return

      OpportunityAPI.subscribeOpportunityCreate(context.getters.profileID, ({ data }) => {
        const opportunity = data.createOpportunity
        if (opportunity && opportunity.owner && opportunity.owner.id === context.getters.profileID) {
          context.commit('addPublishedOpportunity', opportunity)
        }
        if (isOppSupportRequested(opportunity)) return
        context.commit('addOpportunity', opportunity)
      })

      OpportunityAPI.subscribeOpportunityUpdate(context.getters.profileID, ({ data }) => {
        const opportunity = data.updateOpportunity
        if (!opportunity) return
        if (opportunity.networkType === 0) {
          // opportunity was unpublished
          context.commit('unpublishOpportunity', { opportunity, profileID: context.getters.profileID })
          return
        }
        context.commit('updateOpportunity', opportunity)
      })
    },
    async listOpportunities (context, payload: IOppsRequestOptions = {}) {
      const getOppsRequest = context.getters.authenticated ?
        OpportunityAPI.getOpportunities(payload) :
        OpportunityAPI.getPublicOpportunities(payload)

      const data = await getOppsRequest
        .catch(error => context.dispatch('setStatusError', error))

      if (!data) return

      const { opportunity, meta } = data
      context.commit('setAll', {
        opportunities: opportunity, pagination: payload.pagination, meta
      })
    },
    async listPublishedOpportunities (context, payload: IOppsRequestOptions = {}) {
      const userId = context.getters.profileID
      const data = await OpportunityAPI.getOpportunities({
        ...payload,
        owner: `User/${userId}`,
      })
        .catch(error => {
          context.dispatch('setStatusError', error)
        })
      if (!data) return

      const { opportunity, meta } = data
      context.commit('setPublished', {
        opportunities: opportunity, pagination: payload.pagination, meta
      })
    },
    async listUserAppliedOpportunities (context, payload: IOppsRequestOptions = {}) {
      const data = await OpportunityAPI.getUserAppliedOpps(payload).catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!data) return

      const { userApplied, meta } = data
      context.dispatch('fetchOpportunitiesApplications', userApplied.map(opp => opp.id))
      context.commit('setUserApplied', {
        opportunities: userApplied, meta, pagination: payload.pagination
      })
    },
    async listOpportunityApplicants (context, oppId: string): Promise<IApplicant[]> {
      const applicants = await ApplicationAPI.getApplicants([oppId]).catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!applicants || !applicants.length) return []
      const { users, opportunity } = applicants[0]
      context.commit('addApplicants', { applicants: users, oppId: opportunity })
      return users
    },
    // @Outdated
    async upsertOpportunity (context, { id, input }: { id?: number, input?: any } = {}) {
      const opportunity = await OpportunityAPI.upsert({ id, input }).catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!id && !!opportunity) {
        // send event only when opportunity is created
        // @ts-ignore
        this._vm.$analytics.sendOppPublish({ oppID: opportunity.id })
      }
    },
    async createOpportunity (context, input: any) {
      const opportunity = await OpportunityAPI.createOpp(input).catch(error => {
        context.dispatch('setStatusError', error)
      })
      if (!opportunity) return
      // @ts-ignore
      this._vm.$analytics.sendOppPublish({ oppID: opportunity.id })
    },
    async updateOpportunity (context, { id, input }: { id: number, input: any }) {
      return OpportunityAPI.updateOpp(id, input).catch(error => {
        context.dispatch('setStatusError', error)
      })
    },
    async fetchOpportunity (context, id): Promise<IOpportunity|null|void> {
      const getOppByIdRequest = context.getters.authenticated ?
        OpportunityAPI.getOpportunityById(id) :
        OpportunityAPI.getPublicOpportunityById(id)

      const data = await getOppByIdRequest.catch(error => {
        context.dispatch('setStatusError', error)
      })
      return data
    },

    async fetchOpportunityByOfferId (context, offerId): Promise<IOpportunity|null|void> {
      const getOppByOfferIdRequest = OpportunityAPI.getOpportunityByOfferId(offerId)
      const data = await getOppByOfferIdRequest.catch(error => {
        context.dispatch('setStatusError', error)
      })
      return data
    },
    async shareOppWithUsers (context, { id, to }: { id: number, to: string[] }): Promise<IOpportunity|void> {
      const opp = await OpportunityAPI.shareOppWithUsers({ id, to }).catch(error => {
        context.dispatch('setStatusError', error)
      })
      context.commit('updateOpportunity', opp)
      return opp
    },
    async shareOppByEmail (context, shareData: { id: number, email: string, name: string }): Promise<IOpportunity|void> {
      const opp = await OpportunityAPI.shareOppByEmail(shareData).catch(error => {
        context.dispatch('setStatusError', error)
      })
      context.commit('updateOpportunity', opp)
      return opp
    },
    async fetchUsersWhomSharedOpp (context, oppId: number) {
      return OpportunityAPI.getUsersWhomSharedOpp(oppId).catch(error => {
        context.dispatch('setStatusError', error)
      })
    },
    async applyToOpportunity (context, application: { oppID: number } & IApplication) {
      const { oppID, message, availableFrom, answers } = application
      const input: any = {
        message, availableFrom
      }
      if (answers) input.answers = answers
      const app = await OpportunityAPI.applyOpportunity({ id: oppID, input }).catch(err => context.dispatch('setStatusError', err))
      if (app && app.status === ApplicationStatus.APPLIED) {
        context.commit('addAppliedOpportunity', app.opportunity)
      }
      // @ts-ignore
      this._vm.$analytics.sendApplyToOpp({ oppID })
    },
  }
}


export default store