<template>
  <v-row
    no-gutters
    class="mb-3"
  >
    <v-col>
      <!-- <div class="headline">Contact preferences</div> -->
      <v-checkbox
        v-model="checkbox"
        label="Allow Riverflex to send me opportunities from the whole network"
        color="secondary"
        hide-details
        off-icon="radio_button_unchecked"
        on-icon="check_circle"
        @change="updateField"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: { user: { type: Object } },
  data () {
    return {
      checkbox: this.user.fields.allowNetwork
    }
  },
  methods: {
    updateField () {
      const field = {
        name: 'allowNetwork',
        value: this.checkbox
      }
      console.log('update field: checkbox: ', field)
      this.$store.dispatch('setProfileField', field)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-input ::v-deep .v-label {
  font-size: 1rem;
}
</style>
