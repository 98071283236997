import API from './api.service'
import gql from 'graphql-tag'
import { IUser, IInvite } from '@/types/models/user.model'
import { IPagination, IApiMeta, INextCallback, IUsersRequestOptions } from '@/types/api'
import { createSeachArgs } from '@/utils/api'

export const fragment = gql`
  fragment UserData on User {
    id
    available
    weeklyAvailability
    nextAvailabilityNotificationAt
    name
    nickname
    family_name
    given_name
    email
    email_verified
    picture
    role
    location
    about
    website
    linkedin
    phone
    aud
    sub
    targetRate
    prefRoles
    prefIndustries
    memberStatus
    admin
    referrerUser {
      id,
      name,
      picture
    }
    email_subscribe,
    connectionsNumber
    referralsNumber
    opportunitesPublishedNumber
    achievements
    gdprAccepted
  }
`

const inviteFragment = gql`
  fragment InviteData on Invite {
    id
    from {
      ...UserData
    }
    to {
      ...UserData
    }
  }
`

export class UserAPI extends API {

  // queries
  async me (): Promise<IUser|null> {
    const query = await this.query<{ me: IUser }>(gql`
      ${fragment}
      query me {
        me { ...UserData, candidateId, cvS3Url, createdAt, lastLogin, isBusinessInfoVerified, isBusinessInfoSet }
      }
    `)

    return query ? query.me : null
  }

  async getUsers (options: IUsersRequestOptions = {}): Promise<{ user: IUser[], meta: IApiMeta }> {
    const args = createSeachArgs(options)

    return this.query<{ user: IUser[], meta: IApiMeta }>(gql`
      ${fragment}
      query user {
        user ${args} {
          ...UserData, isConnectedToMe
        }
      }`, { fetchPolicy: 'no-cache' }
    )
  }

  async getUserById (id: number): Promise<IUser|null> {
    const query = await this.query<{ userById: IUser|null }>(gql`
      ${fragment}
      query userById {
        userById(id: ${id}) {
          ...UserData, cvS3Url
        }
      }`, { fetchPolicy: 'no-cache' }
    )
    return query.userById
  }

  async getConnections (options: IUsersRequestOptions = {}): Promise<{ connections: IUser[], meta: IApiMeta }> {
    const args = createSeachArgs(options)
    const query = await this.query<{ connections: IUser[], meta: IApiMeta }>(gql`
      ${fragment}
      query connections {
        connections ${args} {
          ...UserData
        }
      }
    `, { fetchPolicy: 'no-cache' })

    return query
  }

  async getIncomingInvites (options: IUsersRequestOptions = {}): Promise<{ invites: { id: number, from: IUser }[], meta: IApiMeta }> {
    const args = createSeachArgs(options)
    return this.query<{ invites: { id: number, from: IUser }[], meta: IApiMeta }>(gql`
      ${fragment}
      query invites {
        invites ${args} { id, from { ...UserData } }
      }`, { fetchPolicy: 'no-cache' }
    )
  }

  async getInvitesToUsers (usersIds: number[] = []): Promise<{ id: number, from: number, to: number }> {
    const query = await this.query<{ invitesToUsers: { id: number, from: number, to: number } }>(gql`
      query invitesToUsers {
        invitesToUsers(ids: [${usersIds.join(',')}]) { id, from, to }
      }`, { fetchPolicy: 'no-cache' }
    )
    return query.invitesToUsers
  }

  async getAllUsersNumber (): Promise<number> {
    const query = await this.query<{ allUsersNumber: number }>(
      gql`query allUsersNumber { allUsersNumber }`
    )
    return query?.allUsersNumber || 0
  }

  async getUserReferrals (id: number, pagination?: IPagination): Promise<{ getUserReferrals: IUser[], meta: IApiMeta }> {
    return this.query<{ getUserReferrals: IUser[], meta: IApiMeta }>(
      gql`query getUserReferrals {
        getUserReferrals(id: ${id}, pagination: ${this.input(pagination)}) {
          id, name, picture, role, location
        }
      }`, { fetchPolicy: 'no-cache' }
    )
  }

  // mutations
  async upsert ({ id, input }: { id?: number, input: any }): Promise<IUser> {
    const response = await this.mutation<{ user: IUser }>(gql`
      ${fragment}
      mutation upsertUser {
        user(${id ? `id: ${id}` : ''}, input: ${this.input(input)} ) {
          ...UserData, candidateId, createdAt
        }
      }
    `)
    return response.user
  }

  async candidate (): Promise<IUser> {
    const response = await this.mutation<{ candidate: IUser }>(gql`
      ${fragment}
      mutation candidate { candidate { ...UserData } }
    `)
    return response.candidate
  }

  async migrate (migrantKey: string): Promise<IUser> {
    const response = await this.mutation<{ migrant: IUser }>(gql`
      ${fragment}
      mutation migrant {
        migrant (key: "${migrantKey}") {
          ...UserData
        }
      }
    `)
    return response.migrant
  }

  async sendInvite (id: number): Promise<IInvite> {
    const response = await this.mutation<{ invite: IInvite }>(gql`
      ${inviteFragment} ${fragment} mutation invite { invite (id: ${id}) { ...InviteData } }`
    )
    return response.invite
  }

  async connect (inviteId: number): Promise<{ id: number, name: string }> {
    const response = await this.mutation<{ connect: { id: number, name: string } }>(gql`
      mutation connect { connect(invite: ${inviteId}) {
        id, name
      }
    }`)
    return response.connect
  }

  async connectReferral (input: { from: number, to: number, share?: number }): Promise<{ id: number, name: string }> {
    const response = await this.mutation<{ connect: { id: number, name: string } }>(gql`
      mutation connectReferral { connect(input: ${this.input(input)}) {
        id, name
      }
    }`)
    return response.connect
  }

  async declineInvite (inviteId: number): Promise<{ declineInvite: number }> {
    return await this.mutation<{ declineInvite: number }>(
      gql`mutation declineInvite { declineInvite(id: ${inviteId}) }`
    )
  }

  async deleteUser (userId: number): Promise<{ deleteUser: IUser }> {
    return await this.mutation<{ deleteUser: IUser }>(gql`
      ${fragment}
      mutation deleteUser {
        deleteUser(id: ${userId}) {
          ...UserData
        }
      }`
    )
  }

  // subscriptions
  subscribeIncomingInvite (id: number, next: INextCallback<{ invite: IInvite }>) {
    this.subscribe(gql`
      ${inviteFragment}
      ${fragment}
      subscription invite ($id: ID!) {
        invite(id: $id) { ...InviteData }
      }
    `, next, { id })
  }

  subscribeConnect (id: number, next: INextCallback<{ connect: { from: number, to: number } }>) {
    this.subscribe(gql`
      subscription connect ($id: ID!) {
        connect(id: $id) { from, to }
      }
    `, next, { id })
  }

  // User A send an invite to User B
  // User B declines invite
  // User A receives a notification about it in this subscription
  subscribeOutcomingDeclinedInvite (id: number, next: INextCallback<{ declineInvite: number }>) {
    this.subscribe(gql`
      subscription declineInvite($id: ID!) {
        declineInvite(id: $id)
      }
    `, next, { id })
  }
}

export default new UserAPI()
