function supportFeatures () {
  return !!(
    Array.of
    && Array.prototype.find
    && Array.prototype.flatMap
    && Array.prototype.values
    && Array.prototype.findIndex
    && String.prototype.endsWith
    && String.prototype.padStart
    && String.prototype.repeat
    && Object.entries
    && window.Promise
  )
}

async function init () {
  if (!window.IntersectionObserver) {
    console.log('loading IntersectionObserver polyfill...')
    await import('intersection-observer')
  }
  if (!window.fetch) {
    console.log('loading fetch polyfill...')
    await import('whatwg-fetch')
  }
  if (!supportFeatures()) {
    console.log('loading stable polyfills...')
    // @ts-ignore
    await import('core-js/stable')
  }
  require('./registerServiceWorker')
  require('./initApp')
}

init()