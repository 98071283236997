


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { IConnSearchField } from '@/types/filters'
import { debounce, IDebouncedFunction } from 'debounce'

@Component
export default class SearchField extends Vue {
  @Prop({ required: true }) search: IConnSearchField
  @Prop({ required: true }) label: string
  @Prop({ required: true }) tab: string

  // computed
  get searchLabel (): string {
    return `Search ${this.label}`
  }

  // lifecycle hooks
  created () {
    this.emitSearchingDebounced = debounce(() => {
      this.$emit('searching', this.search)
    }, 600)
  }

  // methods
  private emitSearchingDebounced: IDebouncedFunction

  private handleSearchEnter () {
    this.emitSearchingDebounced()
  }
}
