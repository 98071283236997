import { Component, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { debounce, IDebouncedFunction } from 'debounce'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
])

import { MODAL_TRANSITION_TIME } from '@/constants'
import { waitAsync } from '@/utils/common'
import { IOpportunity } from '@/types/models/opportunity.model'
import ViewApplicantsModule from '@/components/opportunities/applicants/ViewApplicantsModule.vue'
import { trackOppScreenTab, trackOppScroll } from '@/segmentAnalytics/opportunities'

export interface IOpportunitesPageMixin {
  routeName: string
}

@Component
export default class OpportunitiesPageMixin extends Vue implements IOpportunitesPageMixin {
  routeName: string = 'opportunities'
  activeTab: string = 'all'
  createDialog: boolean = false
  viewDialog: boolean = false
  applicantsDialog: boolean = false
  unpublishDialog: boolean = false
  shareOppDialog: boolean = false
  opportunitySelected?: IOpportunity|null = null
  handleScrollDebounced: IDebouncedFunction

  $refs: {
    $viewApplicantsModule: ViewApplicantsModule
  }

  // lifecycle hooks
  async created () {
    if (this.$route.params.id) {
      this.showViewOppDialog(await this.$store.dispatch('fetchOpportunity', this.$route.params.id))
      await waitAsync(MODAL_TRANSITION_TIME)
    }
    this.handleScrollDebounced = debounce(this.handleScroll, 1000)
    window.addEventListener('scroll', this.handleScrollDebounced)
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScrollDebounced)
  }

  // https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
  async beforeRouteUpdate (to: Route, from: Route, next: Function) {
    if (to.params.id) {
      const opp = await this.$store.dispatch('fetchOpportunity', to.params.id)
      if (!opp) return
      this.showViewOppDialog(opp)
    } else {
      this.hideViewOppDialog()
    }
    next()
  }

  // common methods
  protected async submitOpp (data: { id?: string, input: any }) {
    this.handleHideCreateDialog()
    await waitAsync(MODAL_TRANSITION_TIME)
    if (data.id) {
      this.$store.dispatch('updateOpportunity', data)
    } else {
      this.$store.dispatch('createOpportunity', data.input)
    }
  }

  protected async editOpp (input: any) {
    this.createDialog = true
    await waitAsync(MODAL_TRANSITION_TIME)
    this.viewDialog = false
  }

  protected createOpp () {
    this.opportunitySelected = null
    this.createDialog = true
  }

  protected showViewOppDialog (opp: IOpportunity) {
    this.opportunitySelected = opp
    if (!this.opportunitySelected) return
    this.viewDialog = true
  }

  protected hideViewOppDialog () {
    this.viewDialog = false
  }

  protected showApplicantsDialog (opp: IOpportunity) {
    this.opportunitySelected = opp
    this.applicantsDialog = true
  }

  protected routeToViewOppDialog (oppId: string) {
    this.$router.push({ name: this.routeName, params: { 'id': `${oppId}` } })
  }

  protected routeBackToOppsPage () {
    this.$router.push({ name: this.routeName })
  }

  protected viewApplicationDialog (applicantID: number) {
    // @ts-ignore
    this.$refs.$viewApplicantsModule.viewApp(applicantID)
  }

  protected handleShowViewOppDialog (opp: IOpportunity) {
    this.routeToViewOppDialog(opp.id)
  }

  protected handleHideViewOppDialog () {
    this.routeBackToOppsPage()
  }

  protected handleHideCreateDialog () {
    this.createDialog = false
    if (this.$route.params.id) {
      this.routeBackToOppsPage()
    }
  }

  protected handleTabsChange (tab: string) {
    trackOppScreenTab(tab)
  }

  protected handleScroll () {
    trackOppScroll(this.activeTab)
  }

}