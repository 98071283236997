

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { trackPostJobTap } from '@/segmentAnalytics/opportunities'

@Component
export default class CreateOpportunityBtn extends Vue {

  private handleCreateNewOppClick () {
    trackPostJobTap()
    this.$emit('createNewOpp')
  }
}
