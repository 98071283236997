import { Component, Vue } from 'vue-property-decorator'
import { waitAsync } from '@/utils/common'
import { IPagination } from '@/types/api'

export interface IViewSearchMixin {
  searchField: {
    [key: string]: any
  }
  pagination: {
    [key: string]: {
      isFetching: boolean
      page: number
    }
  }
  getActionByListName: (listName: any) => string|undefined
  isSearching: (listName: any) => boolean
}

@Component
export default class ViewSearchMixin extends Vue implements IViewSearchMixin {
  searchField: IViewSearchMixin['searchField']
  pagination: IViewSearchMixin['pagination']

  getActionByListName (listName: any): string|undefined {
    throw new Error('getActionByListName is not implemented!')
  }
  
  isSearching (listName: any): boolean {
    throw new Error('isSearching is not implemented!')
  }

  async fetchData (listName: any, { page, limit }: IPagination) {
    if (!listName || !this.pagination[listName]) {
      return console.warn('listName is not defined!')
    }

    Vue.set(this.pagination, listName, {
      ...this.pagination[listName],
      page,
      isFetching: true,
    })

    let requestAction = this.getActionByListName(listName)
    if (!requestAction) return

    const isSearching = this.isSearching(listName)
    await this.$store.dispatch(requestAction, {
      pagination: { page },
      input: isSearching ? this.searchField[listName] : null,
      match: !isSearching
    })

    Vue.set(this.pagination[listName], 'isFetching', false)
  }
  
}