


































import { Component, Prop, Vue } from 'vue-property-decorator'

import { IUser } from '@/types/models/user.model'
import { userProfileEvents } from '@/segmentAnalytics/connections'

@Component
export default class ProfileContact extends Vue {
  @Prop({ required: true }) profile: IUser

  phoneUser (phone: string) {
    userProfileEvents.trackPhoneTap(this.profile.id)
    window.location.href = `tel:${phone}`
  }

  emailUser (email: string) {
    userProfileEvents.trackEmailTap(this.profile.id)
    window.location.href = `mailto:${email}`
  }
}
