































import { mapGetters } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IUser } from '@/types/models/user.model'

@Component
export default class OpportunitiesPublishedNumber extends Vue {
  @Prop() user: IUser

  get oppsPublishedNumber (): number {
    if (!this.user) return 0
    return this.user?.opportunitesPublishedNumber || 0
  }
}
