






































import { mapGetters } from 'vuex'
import { Component, Vue } from 'vue-property-decorator'

import UpdateAvailability from '@/components/core/UpdateAvailability.vue'
import { IUser } from '@/types/models/user.model'
import { ProfilePageSection } from '@/router'

@Component({
  components: {
    UpdateAvailability,
  },
  computed: {
    ...mapGetters(['profile']),
  }
})
export default class UserAvailability extends Vue {
  availabilityDialog: boolean = false

  // computed
  profile: IUser|null

  created () {
    if (this.$route.params.section === ProfilePageSection.availability) {
      this.availabilityDialog = true
    }
  }

  showAvailabilityDialog () {
    this.availabilityDialog = true
  }

  hideAvailabilityDialog () {
    this.availabilityDialog = false
  }
}
