import _Vue from 'vue'
import { Store } from 'vuex'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VueRouter from 'vue-router'
import RootState from '@/types/rootState'
import { IUser } from '@/types/models/user.model'
import { memberStatusToEnum } from '@/utils/user'

interface SentryLoggerOptions {
  router: VueRouter;
  origins: Array<string | RegExp>;
  ignoreErrors?: Array<string | RegExp>,
  environment?: string;
  store: Store<RootState>;
}

export interface ILogger {
  logError: (error: Error, context?: Record<string, any>) => void;
  logMessage: (message: string, context?: Record<string, any>) => void;
  setupScope: (user: IUser) => void;
  clearScope: () => void;
}

class SentryLogger implements ILogger {
  private _sentry: typeof Sentry;
  private _store?: Store<RootState>;

  constructor (Vue: typeof _Vue, options: SentryLoggerOptions) {
    this._store = options.store

    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN_URL,
      ignoreErrors: options.ignoreErrors,
      environment: options.environment || 'default',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(options.router),
          tracingOrigins: options.origins,
        }),
      ],
    })
    this._sentry = Sentry
  }

  private get _profile (): IUser|undefined {
    return this._store ? this._store.getters.profile : undefined
  }

  private get _currentUserData (): Record<string, any> {
    if (!this._profile) return {}

    return {
      currentUserId: this._profile.id,
      currentUserName: this._profile.name,
      currentUserEmail: this._profile.email,
      currentUserMemberStatus: memberStatusToEnum(this._profile.memberStatus),
    }
  }

  private isInitialized (): boolean {
    // https://github.com/getsentry/sentry-go/issues/9
    return !!this._sentry.getCurrentHub().getClient()?.getDsn()
  }

  logError (error: Error, extra?: Record<string, any>) {
    if (this.isInitialized()) {
      this._sentry.captureException(error, {
        extra: {
          ...this._currentUserData,
          ...extra,
        }
      })
    } else {
      console.error(error, extra, this._currentUserData)
    }
  };
  
  logMessage (message: string, extra?: Record<string, any> | undefined) {
    if (this.isInitialized()) {
      this._sentry.captureMessage(message, {
        extra: {
          ...this._currentUserData,
          ...extra,
        }
      })
    } else {
      console.log(message, extra, this._currentUserData)
    }
  };

  setupScope (user: IUser): void {
    this._sentry.configureScope((scope) => {
      scope.setUser({
        id: String(user.id),
        name: user.name,
        email: user.email,
        memberStatus: memberStatusToEnum(user.memberStatus),
      })
    })
  }

  clearScope (): void {
    this._sentry.configureScope((scope) => {
      scope.setUser(null)
      scope.clear()
    })
  }
}

export default {
  install (Vue: typeof _Vue, options: SentryLoggerOptions) {
    const logger = new SentryLogger(Vue, options)
    Vue.$logger = logger
    Vue.prototype.$logger = logger
  }
}
