























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import LoadingProgress from '@/components/core/LoadingProgress.vue'
import { IAchievement } from '@/types/models/achievement.model'
import { IOption } from '@/types/form'
import { IUser } from '@/types/models/user.model'

@Component({
  components: {
    LoadingProgress
  },
  computed: {
    ...mapGetters(['achievements', 'achievementById'])
  }
})
export default class UserAchievements extends Vue {
  @Prop() user: IUser

  // data
  tooltipIsShown: any = {
    'quality-mark': false
  }
  tooltipCloseTimeout: any

  // computed
  achievements: IAchievement[]
  achievementById: (id: number) => IAchievement|undefined

  get userAchievements (): number[] {
    return this.user?.achievements || []
  }

  // methods
  async handleAchievementClick (achievementId: number) {
    this.toggleAchievementTooltip(achievementId)
  }

  handleAchievementHover (achievementId: number) {
    clearTimeout(this.tooltipCloseTimeout)
    this.toggleAchievementTooltip(achievementId, true)
  }

  handleAchievementMouseOut (achievementId: number) {
    this.tooltipCloseTimeout = setTimeout(() => {
      this.toggleAchievementTooltip(achievementId, false)
    }, 2000)
  }

  handleAchievementClose (achievementId: number) {
    this.toggleAchievementTooltip(achievementId, false)
  }

  private toggleAchievementTooltip (achievementId: number, toggle?: boolean): IAchievement|undefined {
    const achievemnt = this.achievementById(achievementId)
    if (!achievemnt) return
    this.tooltipIsShown[achievemnt.name] = toggle == undefined ? !this.tooltipIsShown[achievemnt.name] : toggle
    return achievemnt
  }
}
