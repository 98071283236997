// Available extensions for attachment to screening question
// To be discussed with rf team
export default [
  'pdf',
  'doc',
  'docx',
  'txt',
  'rtf',
  'csv',
  'xls',
  'xlsx',
  'pptx',
  'ppt',
  '-',
  'jpeg',
  'jpg',
  'gif',
  'svg',
  'png',
  // '-',
  // 'mp3',
  // 'wav',
  // 'mp4a',
  // 'aif',
  // '-',
  // 'mpeg',
  // 'mp4',
  // 'ogv',
  // 'mov',
  // 'avi',
  // 'aac'
]
