



































































































































































































import { mapState, mapGetters } from 'vuex'
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'

import SearchField from '@/components/core/SearchField.vue'
import createOpportunity from '@/components/opportunities/createOpportunity.vue'
import viewOpportunity from '@/components/opportunities/viewOpportunity.vue'
import listOpportunities from '@/components/opportunities/listOpportunities.vue'
import unpublishDialog from '@/components/opportunities/unpublishOpportunity.vue'
import shareOpportunity from '@/components/opportunities/shareOpportunity.vue'
import CreateOpportunityBtn from '@/components/opportunities/CreateOpportunityBtn.vue'
import ViewApplicantsModule from '@/components/opportunities/applicants/ViewApplicantsModule.vue'
import ViewSearchMixin, { IViewSearchMixin } from '@/mixins/ViewSearch.mixin'
import OpportunitiesPageMixin, { IOpportunitesPageMixin } from '@/mixins/OpportunitiesPage.mixin'
import LegacyPageContent from '@/components/layouts/LegacyPageContent.vue'
import { MODAL_TRANSITION_TIME, PAGINATION_LIMIT } from '@/constants'
import { waitAsync } from '@/utils/common'
import { IOpportunity } from '@/types/models/opportunity.model'
import { IOppSearchField } from '@/types/filters'
import { IPagination } from '@/types/api'
import { AdminOppsListName } from '@/store/adminOpportunities'
import { Route } from 'vue-router'

const ViewMixins = Mixins(ViewSearchMixin, OpportunitiesPageMixin)

@Component({
  computed: {
    ...mapGetters([
      'isAdmin',
      'adminAllOpps',
      'supportProvided',
      'supportRequested',
      'adminAllTotal',
      'supportProvidedTotal',
      'supportRequestedTotal'
    ]),
  },
  components: {
    SearchField,
    createOpportunity,
    viewOpportunity,
    listOpportunities,
    unpublishDialog,
    shareOpportunity,
    ViewApplicantsModule,
    CreateOpportunityBtn,
    LegacyPageContent
  },
})
export default class AdminOpportunities extends ViewMixins implements IViewSearchMixin, IOpportunitesPageMixin {
  @Prop() hasProfile: boolean

  // data
  routeName: string = 'adminOpportunities'

  pagination: {
    [key: string]: any
  } = {
    adminAllOpps: {
      isFetching: false,
      page: 0
    },
    supportProvided: {
      isFetching: false,
      page: 0
    },
    supportRequested: {
      isFetching: false,
      page: 0
    }
  }

  searchField: {
    [key: string]: IOppSearchField
  } = {
    adminAllOpps: {
      headline: '',
      industry: '',
      location: ''
    },
    supportProvided: {
      headline: '',
      industry: '',
      location: ''
    },
    supportRequested: {
      headline: '',
      industry: '',
      location: ''
    }
  }

  // computed
  // mapGetters
  isAdmin: boolean
  adminAllOpps: IOpportunity[]
  supportProvided: IOpportunity[]
  supportRequested: IOpportunity[]
  adminAllTotal: number
  supportProvidedTotal: number
  supportRequestedTotal: number

  // lifecycle hooks
  async created () {
    if (!this.isAdmin) {
      this.$router.push(this.$route.fullPath.replace('/admin', ''))
    }
    await this.$store.dispatch('listAdminOpportunities')
    await this.$store.dispatch('listSupportRequestedOpportunities')
    await this.$store.dispatch('listSupportProvidedOpportunities')
  }

  // methods
  private async unpublish () {
    this.unpublishDialog = false
    this.routeBackToOppsPage()

    await waitAsync(MODAL_TRANSITION_TIME)

    this.$store.dispatch('upsertOpportunity', {
      id: this.opportunitySelected?.id,
      input: {
        networkType: 0,
        status: null
      }
    })
  }

  private clearSearch (listName: AdminOppsListName) {
    if (!this.searchField[listName]) return
    this.searchField[listName] = {
      headline: '',
      industry: '',
      location: ''
    }
    this.handleSearch(listName, this.searchField[listName])
  }

  // handlers
  private async handleFetchMore (listName: AdminOppsListName, pagination: IPagination) {
    this.fetchData(listName, pagination)
  }

  private async handleSearch (listName: AdminOppsListName, search: IOppSearchField) {
    this.fetchData(listName, { page: 0, limit: PAGINATION_LIMIT })
  }

  getActionByListName (listName: AdminOppsListName): string|undefined {
    let requestAction
    switch (listName) {
    case 'adminAllOpps':
      requestAction = 'listAdminOpportunities'
      break
    case 'supportProvided':
      requestAction = 'listSupportProvidedOpportunities'
      break
    case 'supportRequested':
      requestAction = 'listSupportRequestedOpportunities'
      break
    }
    if (!requestAction) {
      console.warn('RequestAction is not found!', listName)
    }
    return requestAction
  }

  isSearching (listName: AdminOppsListName): boolean {
    const searchField = this.searchField[listName]
    if (!searchField) return false
    return !!searchField.headline || !!searchField.industry || !!searchField.location
  }
}
