


















































































































import { mapGetters } from 'vuex'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Message from '@/components/opportunities/apply/Message.vue'
import Date from '@/components/opportunities/apply/Date.vue'
import UploadCV from '@/components/core/UploadCV.vue'
import ScreeningQuestionsSurvey from '@/components/screeningQuestions/ScreeningQuestionsSurvey.vue'
import { IOpportunity } from '@/types/models/opportunity.model'
import { IUser } from '@/types/models/user.model'
import { ScreeningQuestionType, IScreeningQuestionEditable } from '@/types/models/screeningQuestion.model'
import { applyScreenEvents } from '@/segmentAnalytics/opportunities'

@Component({
  components: {
    Message,
    Date,
    UploadCV,
    ScreeningQuestionsSurvey
  },
  computed: {
    ...mapGetters([
      'profile',
      'currentUserHasCv',
      'isNewUser'
    ])
  }
})
export default class ApplyOpportunity extends Vue {
  @Prop({ required: true }) opportunity: IOpportunity

  $refs: {
    form: any
    sqSurvey: ScreeningQuestionsSurvey
  }

  // data
  valid: boolean = true
  messageRules: any = [
    (value: any) => {
      if (!this.isNewUser) return true
      return !!value || 'Message is required!'
    }
  ]
  showCvRequiredError: boolean = false
  screeningQuestions: IScreeningQuestionEditable[] = []

  @Watch('opportunity')
  onOpportunityChange (val: IOpportunity, oldVal: IOpportunity) {
    this.resetScreeningQuestions(val?.screeningQuestions || [])
  }

  // computed
  profile: IUser
  currentUserHasCv: boolean
  isNewUser: boolean

  created () {
    this.resetScreeningQuestions(this.opportunity?.screeningQuestions || [])
  }

  // methods
  handleCancelClick () {
    applyScreenEvents.trackCancel()
    this.$emit('hide')
  }

  handleCloseClick () {
    applyScreenEvents.trackClose()
    this.$emit('hide')
  }


  submitForm () {
    if (!this.currentUserHasCv && this.isNewUser) {
      this.showCvRequiredError = true
      return
    }
    if (!this.$refs.form.validate()) return

    applyScreenEvents.trackApply()

    const formData = this.$refs.form.inputs.reduce((result: any, formInput: any) => {
      if (!!formInput.id) result[formInput.id] = formInput.value
      return result
    }, {})

    if (this.$refs.sqSurvey && this.screeningQuestions) {
      const isSurveyValid = this.$refs.sqSurvey.validate()
      if (!isSurveyValid) return
      formData.answers = this.screeningQuestions.map(item => {
        const answer = item.type === ScreeningQuestionType.multiChoice ?
          // @ts-ignore
          item.answer.join(';') :
          item.answer

        return {
          question: item.body,
          type: item.type,
          order: item.order,
          answer,
        }
      })
    }
    this.$emit('submit', formData)
  }

  resetScreeningQuestions (questions: IScreeningQuestionEditable[]) {
    this.screeningQuestions = questions
  }

  handleCvUploaded () {
    this.showCvRequiredError = false
  }
}
