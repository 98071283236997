<template>
  <div>
    <v-card class="pa-2 pt-0 pb-0">
      <v-container>
        <v-row
          no-gutters
          align="center"
          class="mb-3"
        >
          <v-col class="headline">
            Decline Application
          </v-col>

          <v-col cols="auto">
            <v-btn
              small
              fab
              icon
              @click="hideModal"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="text-center"
        >
          <!-- <v-col cols=12 class="title">
                    {{opportunity.headline}}
                </v-col> -->

          <v-col class="text-center my-3">
            <v-btn
              rounded
              dark
              color="appRed"
              @click="decline"
            >
              Confirm
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="caption"
          >
            Confirming will decline this application to your opportunity. This
            can not be undone.
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ['opportunity'],
  methods: {
    hideModal () {
      this.$emit('hide')
    },
    decline () {
      this.$emit('decline')
    }
  }
}
</script>
<style lang="scss" scoped>
.caption {
  color: grey;
}
</style>
