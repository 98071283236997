<template>
  <div>
    <v-card
      class="pa-2 pt-0 pb-0"
      v-if="!!opportunity"
    >
      <v-container>
        <v-row
          no-gutters
          align="center"
          class="mb-3"
        >
          <v-col class="headline">
            Hide Opportunity
          </v-col>

          <v-col cols="auto">
            <v-btn
              small
              fab
              icon
              @click="hideModal"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="text-center"
        >
          <v-col
            cols="12"
            class="title"
          >
            {{ opportunity.headline }}
          </v-col>

          <v-col class="text-center my-3">
            <v-btn
              rounded
              dark
              color="appRed"
              @click="unpublish"
            >
              Confirm Unpublish
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="caption"
          >
            Confirming will hide this opportunity from everyone but it will
            still remain in your published opportunity list. You will be able to
            republish again later.
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { unpublishScreenEvents } from '@/segmentAnalytics/opportunities'
export default {
  props: ['opportunity'],
  methods: {
    hideModal () {
      unpublishScreenEvents.trackClose()
      this.$emit('hide')
    },
    unpublish () {
      unpublishScreenEvents.trackConfirm()
      this.$emit('unpublish')
    }
  }
}
</script>
<style lang="scss" scoped>
.caption {
  color: grey;
}
</style>
