export default [
  'Aerospace & Defence',
  'Agriculture & Dairy',
  'Apparel & Fashion',
  'Automotive',
  'Banking & Finance',
  'Biotechnology',
  'Chemicals',
  'Computer & Network Security',
  'Computer Games',
  'Consumer Goods',
  'Cosmetics',
  'Design',
  'Digital',
  'Education',
  'Engineering',
  'Entertainment',
  'Executive Office',
  'Food & Beverages',
  'Gambling & Casinos',
  'Health Care',
  'Hospitality & Leisure',
  'Human Resources',
  'Insurance',
  'Logistics and Supply Chain',
  'Luxury Goods & Jewelry',
  'Management Consulting',
  'Manufacturing & Construction',
  'Market Research',
  'Marketing and Advertising',
  'Media & Publishing',
  'Mining & Metals',
  'Music',
  'Non-Profit Organization Management',
  'Outsourcing/Offshoring',
  'Pharmaceuticals',
  'Professional Training & Coaching',
  'Public Relations and Communications',
  'Public Sector',
  'Real Estate',
  'Renewables & Environment',
  'Retail',
  'Sports',
  'Technology',
  'Telecommunications',
  'Travel & Tourism',
  'Utilities',
  'Other'
]
