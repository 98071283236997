<template>
  <div class="more-info-container">
    <v-row>
      <v-col>
        <div class="title">
          How can I earn rewards?
        </div>

        Your contacts and opportunities are valuable and you will be rewarded for your sharing and referrals! Find out more below:
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="(card, i) in cards"
        :key="i"
        cols="6"
        class="pa-1 px-2"
      >
        <v-card
          :style="card.background"
          class="background pb-1"
          dark
          @click="handleCardClick(card)"
          v-trackEvent:click="{
            event: card.eventName
          }"
        >
          <v-row class="ma-0">
            <v-col
              cols="11"
              class="pr-0"
            >
              <v-card-title
                class="pa-0 pl-4 card-title"
                v-html="card.title"
              />
            </v-col>
            <v-col
              v-if="!$mediaQuery.mobilesAndDown"
              cols="1"
              class="d-flex justify-center pa-0 ma-0 pr-5"
            >
              <v-icon>keyboard_arrow_right</v-icon>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col
        cols="6"
        class="pa-1 px-2"
      >
        <ul>
          <li>
            Almost 20% of job opportunities are posted by platform members
          </li>
          <li>
            100% of the members who have posted job opportunities on the platform will be rewarded when the Riverflex team successfully fulfills their job opportunities.
          </li>
        </ul>
      </v-col>
      <v-col
        cols="6"
        class="pa-1 px-2"
      >
        <ul>
          <li>
            More than 10% of our community members are referred by platform members
          </li>
          <li>
            We have already rewarded more than 20% of the members who have referred high quality independent professionals have earned 1000 €/£, because the people they referred got sourced in a Riverflex project.
          </li>
        </ul>
      </v-col>
    </v-row>

    <ShareOppRewardsDialog
      :show="showPostJobAndEarnMarginDialog"
      :content-style="orangeBg"
      @hide="showPostJobAndEarnMarginDialog = false"
    />

    <ReferContactRewardsDialog
      :show="showReferContactDialog"
      :show-more-info="false"
      @hide="showReferContactDialog = false"
    />

    <!-- <PostJobCampaignDialog
      :show="showPostJobAndEarn100Dialog"
      :show-more-info="false"
      @hide="showPostJobAndEarn100Dialog = false"
    /> -->
  </div>
</template>
<script>
import ReferContactRewardsDialog from '@/components/rewards/ReferContactRewardsDialog.vue'
import ShareOppRewardsDialog from '@/components/rewards/ShareOppRewardsDialog.vue'
// import PostJobCampaignDialog from '@/components/rewards/PostJobCampaignDialog.vue'
import { SEA_BLUE_BG_GRADIENT, ORANGE_BG_GRADIENT } from '@/constants'
// import { trackOppPostRewardTap, trackOppShareRewardTap, trackReferContactTap } from '@/segmentAnalytics/rewards'

export default {
  components: {
    ShareOppRewardsDialog,
    ReferContactRewardsDialog,
    // PostJobCampaignDialog
  },
  data () {
    return {
      blueBg: SEA_BLUE_BG_GRADIENT,
      orangeBg: ORANGE_BG_GRADIENT,
      cards: [
        // {
        //   title: 'Post a job opportunity and earn 100 €/£',
        //   background: ORANGE_BG_GRADIENT,
        //   dialog: 'showPostJobAndEarn100Dialog',
        //   eventName: 'reward_opp_post_tap',
        // },
        {
          title: 'Refer a contact to earn 1000 €/£!',
          background: SEA_BLUE_BG_GRADIENT,
          dialog: 'showReferContactDialog',
          eventName: 'reward_connect_tap',
        },
        {
          title: 'Share an opportunity and partner with Riverflex to earn 40% margin share!',
          background: ORANGE_BG_GRADIENT,
          dialog: 'showPostJobAndEarnMarginDialog',
          eventName: 'reward_opp_share_tap',
        },
      ],
      showPostJobAndEarn100Dialog: false,
      showPostJobAndEarnMarginDialog: false,
      showReferContactDialog: false
    }
  },

  methods: {
    handleCardClick (card) {
      this[card.dialog] = true
    },
  }
}
</script>
<style lang='scss' scoped>
.card-title {
  font-size: 1.3em;
  line-height: 1.3em;
}
.more-info-container {
  width: 100%;
}
</style>
