<template>
  <div>
    <v-list two-line>
      <v-list-item-group multiple>
        <v-list-item
          inactive
          @click="viewProfile"
          class="pa-0 px-2 pt-0"
        >
          <v-list-item-avatar
            width="62"
            height="62"
            class="mr-3"
          >
            <ProfilePic
              :src="profile.picture"
              :width="62"
            />
          </v-list-item-avatar>

          <v-list-item-content align="top">
            <v-list-item-title class="subtitle-1 mb-0 pb-0">
              <strong>{{ profile.name }}</strong>
            </v-list-item-title>

            <v-list-item-subtitle class="role subtitle-1 ma-0 mb-1">
              {{ profile.role }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="location">
              {{ profile.location }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action class="ma-0">
            <v-spacer />

            <ViewBtn
              v-if="canSeeUsersNetwork"
              class="mt-1"
              title="View profile"
              :loading="profileIsLoading"
            />
            <v-spacer />
          </v-list-item-action>
        </v-list-item>

        <v-divider />
      </v-list-item-group>
      <div
        v-if="!canSeeUsersNetwork"
        class="mt-2"
      >
        <i>
          In order to access the user profile, please add an up to date CV to your
          <router-link
            class="melon--text"
            to="/profile"
          >profile</router-link>.
        </i>
      </div>
    </v-list>
    <UserProfileDialog
      :show="viewProfileDialog"
      :profile="applicantProfile"
      @hide="hideViewUserDialog"
    />
  </div>
</template>
<script>
import ProfilePic from '@/components/core/ProfilePic'
import ViewBtn from '@/components/core/ViewBtn'
import UserProfileDialog from '@/components/profile/UserProfileDialog.vue'
import { mapGetters } from 'vuex'
import { oppApplicantionEvents } from '@/segmentAnalytics/opportunities'

export default {
  props: ['profile'],
  components: {
    ProfilePic,
    ViewBtn,
    UserProfileDialog
  },
  data () {
    return {
      disabledView: false,
      viewProfileDialog: false,
      applicantProfile: {},
      profileIsLoading: false,
    }
  },
  computed: {
    ...mapGetters(['canSeeUsersNetwork'])
  },
  methods: {
    async viewProfile () {
      if (!this.canSeeUsersNetwork) return

      oppApplicantionEvents.trackViewProfile()

      this.profileIsLoading = true
      const applicantProfile = await this.$store.dispatch('getUser', this.profile.id)
        .catch(console.error)

      if (!applicantProfile) {
        this.profileIsLoading = false
        return
      }

      this.applicantProfile = applicantProfile
      setTimeout(() => {
        this.profileIsLoading = false
        this.viewProfileDialog = true
      }, 500)
    },
    hideViewUserDialog () {
      this.viewProfileDialog = false
      this.applicantProfile = {}
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  line-height: 1.1em !important;
}
.role,
.location,
.date {
  color: grey;
}
</style>
