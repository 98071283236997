import { trackEvent } from '@/segmentAnalytics/core'
import { convertToCohortDate } from '@/utils/date'

export const trackSignInTap = (): void => {
  trackEvent('sign_in_tap')
}

interface ISliderScrollProps {
  action: 'left_tap'|'right_tap'
  number: number
}
const trackSliderScroll = (props: ISliderScrollProps): void => {
  trackEvent('slider_scroll', props)
}

export const trackNextSlideTap = (num: number): void => {
  trackSliderScroll({ action: 'right_tap', number: num })
}

export const trackPrevSlideTap = (num: number): void => {
  trackSliderScroll({ action: 'left_tap', number: num })
}

export const trackReferralSignIn = (): void => {
  trackEvent('new_contact_accept_link_tap')
}

export const trackOppShareReferralSignIn = (): void => {
  trackEvent('opp_share_accept_email_tap')
}

export const trackUserLogin = (loginDate?: string): void => {
  const cohortDate = convertToCohortDate(loginDate || (new Date().toISOString()))
  trackEvent('user_log_in', { date: cohortDate })
}