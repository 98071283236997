import { DirectiveOptions } from 'vue'
import { trackEvent } from '@/segmentAnalytics/core.ts'

const directive: DirectiveOptions = {
  bind (el, binding, vnode) {
    if (!binding.arg) {
      console.warn('arg is not defined!')
      return
    }
    if (!vnode.componentInstance) return

    (vnode.componentInstance as any).___handleTrackEvent = () => trackEvent(binding.value.event, binding.value.props)
    vnode.componentInstance.$on(binding.arg, (vnode.componentInstance as any).___handleTrackEvent)
  },
  unbind (el, binding, vnode) {
    if (!binding.arg) return
    if (!vnode.componentInstance) return
    vnode.componentInstance.$off(binding.arg, (vnode.componentInstance as any).___handleTrackEvent)
  },
}

export default directive
