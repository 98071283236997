

















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { trackControlEdit, trackControlCloseEditing } from '@/segmentAnalytics/profile'

interface IFormField {
  label: string
  name: string
  isActive: boolean,
  value: string|number|Array<any>|boolean
  editable: boolean
  onClick: () => {}
}

@Component
export default class FormFields extends Vue {
  @Prop({ required: true }) fields: IFormField[]

  updateField (field: IFormField, i: number) {
    trackControlCloseEditing(field.name)
    this.$store.dispatch('updateProfile', {
      [field.name] : field.value || null
    })
    this.fields[i].isActive = false
  }

  activateFieldEditMode (field: IFormField) {
    trackControlEdit(field.name)
    field.isActive = true
  }
}
