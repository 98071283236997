import { render, staticRenderFns } from "./listOpportunities.vue?vue&type=template&id=713b9751&scoped=true&"
import script from "./listOpportunities.vue?vue&type=script&lang=js&"
export * from "./listOpportunities.vue?vue&type=script&lang=js&"
import style0 from "./listOpportunities.vue?vue&type=style&index=0&id=713b9751&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713b9751",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VChip,VDivider,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VSpacer})
