




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { trackGDPRAcceptTap } from '@/segmentAnalytics/gdpr'
import { trackEmailNotificationTap } from '@/segmentAnalytics/profile'

@Component({})
export default class GDPRAgreement extends Vue {
  @Prop({ default: 'OK!' }) okBtnTitle: string;

  isSubscribedToEmail: boolean = false

  handleAcceptAgreementClick () {
    this.$store.dispatch('updateProfile', { gdprAccepted: true, email_subscribe: this.isSubscribedToEmail })
    trackGDPRAcceptTap()
    this.$emit('acceptAgreement')
  }
}
