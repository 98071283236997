import API from './api.service'
import gql from 'graphql-tag'
import { IBusinessInfo } from '@/types/models/businessInfo.model'

export const fragment = gql`
  fragment BusinessInfoData on BusinessInfo {
    fullName
    phone
    email
    deliveryAddress
    passportFileUrl
    supplierName
    supplierAddress
    registrationNumber
    vat
    professionalLiabilityInsuranceUrl
    publicLiabilityInsuranceUrl
    iban
    swift
    isVerified
    changeRequestMessage
  }
`

export type BusinessInfoCreateInput = {
  fullName: string
  phone: string
  email: string
  deliveryAddress: string
  passportS3Key: string
  supplierName: string
  supplierAddress: string
  registrationNumber: string
  vat: string
  professionalLiabilityInsiranceS3Key: string
  publicLiabilityInsuranceS3Key: string
  iban: string
  swift: string
}

export class BusinessInfoAPI extends API {

  // queries
  async getBusinessInfo (): Promise<IBusinessInfo[]> {
    const query = await this.query<{ businessInfo: IBusinessInfo[] }>(gql`
      ${fragment}
      query businessInfo {
        businessInfo {
          ...BusinessInfoData
        }
      }`, { fetchPolicy: 'no-cache' })
    return query.businessInfo
  }

  // mutations
  async updateBusinessInfo (info: BusinessInfoCreateInput): Promise<IBusinessInfo|undefined> {
    const response = await this.mutation<{ updateBusinessInfo?: IBusinessInfo }>(gql`
      mutation updateBusinessInfo($info: BusinessInfoCreateInput!) {
        updateBusinessInfo(info: $info) {
          ...BusinessInfoData
        }
      },
    ${fragment}
    `, { info })
    return response.updateBusinessInfo
  }

}

export default new BusinessInfoAPI()
